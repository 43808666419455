import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "sign-in",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      margin: "auto",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    block: {
      [theme.breakpoints.down("md")]: {
        width: "100%",
        minWidth: "unset",
      },
      minWidth: 352,
      background: theme.palette.common.white,
      boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.06)",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(4),
      marginBottom: theme.spacing(5),
      borderRadius: "8px",
    },
    link: {
      color: theme.palette.common.info,
      textDecoration: "none",
    },
    signedUser: {
      backgroundColor: theme.palette.background.light,
      padding: theme.spacing(2),
      width: "100%",
      display: "flex",
      alignItems: "center",
      borderRadius: "8px",
    },
    avatar: {
      width: 24,
      height: 24,
      marginRight: theme.spacing(1),
    },
    avatarWrapper: {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      textAlign: "center",
      cursor: "pointer",
      width: "24px",
      height: "24px",
      fontSize: 12,
      backgroundColor: "#FFFFFF",
      borderRadius: "50%",
      marginRight: "10px",
    },
  }),
  options
);

export default useStyles;
