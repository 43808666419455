import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "notification-item",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(1),
      cursor: "pointer",
    },
    unread: {
      backgroundColor: theme.palette.background.info,
    },
    content: {
      fontSize: 18,
      lineHeight: 1.44,
      marginBottom: theme.spacing(0.5),
    },
    date: {
      fontSize: 16,
      fontWeight: 500,
      color: theme.palette.common.grey,
    },
  }),
  options
);

export default useStyles;
