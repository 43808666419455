import { Box } from "@mui/system";
import { ComponentType } from "react";
import useStyles from "./styles";
import clsx from "clsx";

interface Props {
  label: string;
  variant?:
    | "default"
    | "primary"
    | "secondary"
    | "success"
    | "warning"
    | "error";
  sx?: any;
  circle?: boolean;
}

export const Badge: ComponentType<Props> = ({ label, variant, sx, circle }) => {
  const classes = useStyles();
  return (
    <Box
      sx={sx}
      className={clsx(classes.root, {
        [classes.circle]: circle,
        [classes.primary]: variant === "primary",
        [classes.secondary]: variant === "secondary",
        [classes.success]: variant === "success",
        [classes.warning]: variant === "warning",
        [classes.error]: variant === "error",
      })}
      component="span"
    >
      {label}
    </Box>
  );
};
