import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& a": {
      textDecoration: "none",
      color: "black",
      "&:visited": {
        color: "black",
      },
      "&:active": {
        color: "black",
      },
      "&:focus": {
        color: "black",
      },
      "&:hover": {
        color: "black",
      },
    },
    "& table thead tr > th:nth-last-child(2) > div": {
      [theme.breakpoints.down("lg")]: {
        justifyContent: "flex-end",
      },
    },

    "& table thead tr > th:last-child > div": {
      justifyContent: "flex-end",
    },
  },
}));

export default useStyles;
