import React, { ComponentType } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { IconOpen, IconClose } from "../icons";
import { Checkbox } from "../checkbox";
import {
  DataTableColumn,
  DataTableRowExpandable,
  DataTableRowSelection,
} from "./TableView";

interface DataTableRowProps {
  item: Record<string, any>;
  columnsCount: number;
  columns: DataTableColumn[];
  rowSelection?: DataTableRowSelection;
  onClick?: (item: Record<string, any>) => void;
  selectedRows: number[];
  setSelectedRows: (id: number) => void;
  expandable?: DataTableRowExpandable;
  onRowClick?: (item: Record<string, any>) => void;
  onAnyCellClick?: (item: Record<string, any>) => void;
}

export const TableViewRow: ComponentType<DataTableRowProps> = ({
  columnsCount,
  rowSelection,
  item,
  columns,
  selectedRows,
  setSelectedRows,
  expandable,
  onRowClick,
  onAnyCellClick,
}) => {
  const [open, setOpen] = React.useState(false);
  const isSelectable = !!rowSelection;
  const isSelected = selectedRows.includes(item.id);
  const isExpandable =
    expandable && expandable.rowExpandable && expandable.rowExpandable(item);

  const handleSelect = () => {
    setSelectedRows(item.id);
  };

  return (
    <>
      <TableRow
        onClick={() => onRowClick(item)}
        sx={{
          "&:hover": {
            backgroundColor: "#F8F9FF",
            cursor: "pointer",
          },
          backgroundColor: isSelected ? "#F8F9FF" : "transparent",
        }}
      >
        {isSelectable && (
          <TableCell>
            <Checkbox
              color="primary"
              checked={isSelected}
              onChange={handleSelect}
            />
          </TableCell>
        )}
        {columns.map((column) => {
          const value = item[column.dataIndex];
          return (
            <TableCell
              key={item.id + "_" + column.key}
              {...column.cellProps}
              onClick={() => onAnyCellClick && onAnyCellClick(item)}
            >
              {column.render ? column.render(value, item) : value}
            </TableCell>
          );
        })}
        {isExpandable && (
          <TableCell>
            <IconButton
              size="small"
              sx={{ backgroundColor: "#F6F6FC", "& svg": { fontSize: 14 } }}
              onClick={() => setOpen(!open)}
            >
              {open ? <IconClose /> : <IconOpen />}
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      {isExpandable && (
        <TableRow>
          <TableCell sx={{ padding: 0 }} colSpan={columnsCount}>
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              sx={{ backgroundColor: "background.light" }}
            >
              <Box sx={{ padding: 2 }}>
                {expandable.expandedRowRender &&
                  expandable.expandedRowRender(item)}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
