import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: "646px",
    margin: "auto",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  iconNotFoundWrapper: {
    "& svg": {
      fontSize: 70,
    },
  },
  icon404Wrapper: {
    "& svg": {
      fontSize: 120,
    },
  },
}));
