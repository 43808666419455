import React, { ComponentType, useEffect, useRef } from "react";
import {
  Box,
  TableBody,
  Table,
  TableContainer,
  Pagination,
  PaginationItem,
  TableCellProps,
} from "@mui/material";
import { TableViewRow } from "./TableViewRow";
import { TableViewHeader } from "./table-view-header";
import { useTableSelection } from "./useTableSelection";
import { SortingDirection, useTableSorting } from "./useTableSorting";
import { useTableFiltering } from "./useTableFiltering";
import useStyles from "./styles";
import { IconLoaderBlack } from "../icons";

export interface DataTableFilterItem {
  label: string;
  value: string;
}

export interface DataTableColumn {
  title: string;
  dataIndex: string;
  key: string;
  cellProps?: TableCellProps;
  render?: (value: any, record: any) => any;
  sortable?: boolean;
  filters?: DataTableFilterItem[];
}

export interface DataTableRowSelection {
  selectedRowKeys: string[];
  onChange: (item: number[]) => void;
}

export interface DataTableRowExpandable {
  expandedRowRender: (record: any) => any;
  rowExpandable?: (record: any) => boolean;
}

interface Props {
  dataSource: Record<string, any>[];
  columns: DataTableColumn[];
  rowSelection?: DataTableRowSelection;
  onRowClick?: (item: Record<string, any>) => void;
  onAnyCellClick?: (item: Record<string, any>) => void;
  expandable?: DataTableRowExpandable;
  hideColumns?: string[];
  hiddenHeader?: boolean;
  isLoading?: boolean;
  pagination?: {
    page: number;
    perPage: number;
    totalPages: number;
    total: number;
  };
  onPageChange?: (page: number) => void;
  handleSort?: (sortableField: any) => void;
}

export const TableView: ComponentType<Props> = ({
  rowSelection,
  columns: defaultColumns,
  dataSource,
  onRowClick,
  onAnyCellClick,
  expandable,
  hideColumns = [],
  hiddenHeader = false,
  pagination,
  onPageChange,
  isLoading,
  handleSort,
}) => {
  const {
    selectedRows,
    isSelectedAll,
    handleSelectRow,
    isSelectedRowsEmpty,
    handleSelectAll,
  } = useTableSelection(dataSource, rowSelection);
  const { sortingState, sort } = useTableSorting();
  const { filteringState, filter, clearFilter } = useTableFiltering();

  useEffect(() => {
    handleSort(sortingState);
  }, [sortingState]);

  const isSelectable = !!rowSelection;
  const columns = defaultColumns.filter(
    (column) => !hideColumns.includes(column.key)
  );
  const isExpandable = Boolean(expandable && !!expandable.expandedRowRender);
  const columnsCount =
    columns.length + (isSelectable ? 1 : 0) + (isExpandable ? 1 : 0);
  const classes = useStyles();

  const paginationRef = useRef(null);

  const LoadingComponent = () => {
    return (
      <Box className={classes.spinnerContainer}>
        <IconLoaderBlack />
      </Box>
    );
  };

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <TableContainer className={classes.root}>
        <Table>
          {!hiddenHeader && (
            <TableViewHeader
              {...{
                handleSort,
                isExpandable,
                isSelectable,
                isSelectedAll,
                isSelectedRowsEmpty,
                handleSelectAll,
                columns,
                sortingState,
                sort,
                filteringState,
                filter,
                clearFilter,
              }}
            />
          )}
          <TableBody>
            {dataSource?.map((item) => (
              <TableViewRow
                key={item.id}
                onRowClick={(item) => onRowClick && onRowClick(item)}
                onAnyCellClick={onAnyCellClick}
                {...{
                  expandable,
                  columnsCount,
                  item,
                  columns,
                  rowSelection,
                  selectedRows,
                  setSelectedRows: handleSelectRow,
                }}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={4}>
        {pagination && (
          <Pagination
            onChange={(event: React.ChangeEvent<unknown>, page: number) =>
              onPageChange(page)
            }
            count={pagination?.totalPages}
            ref={paginationRef}
            renderItem={(item) => (
              <PaginationItem
                components={{
                  previous: () => <span>Previous</span>,
                  next: () => <span>Next</span>,
                }}
                {...item}
              />
            )}
          />
        )}
      </Box>
    </>
  );
};
