import { useEffect, useState } from "react";
import { ComponentType } from "react";
import {
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Grid,
  Typography,
  Divider,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { DialogProps } from "../../core/providers/dialog.provider";
import { RadioGroup, DatePicker } from "../../components/fields";
import { AdaptiveButton } from "../../components/adaptive-button";
import { LinkButton } from "../../components/buttons/LinkButton";
import { IconExportLoading } from "../../components/icons";
import useStyles from "./styles";
import { filterItems } from "../../core/columns/receipts.columns";
import { useExportForm } from "../../core/hooks/useExportForm";
import { endOfDay, startOfDay, sub } from "date-fns";
import {
  useGetEmployeesQuery,
  useGetTypesOfActivityQuery,
} from "../../http/api";
import { get, map } from "lodash";
import { MultiSelect } from "../../components/fields/multi-select";

export const ExportActionDialog: ComponentType<DialogProps> = ({
  popDialog,
}) => {
  const classes = useStyles();
  const [showLoading, setShowLoading] = useState(false);
  const [periodDate, setPeriodDate] = useState<any>([null, null]);
  const [period, setPeriod] = useState<string>("month");
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { data: employeesData } = useGetEmployeesQuery({});

  const { data: typesOfActivityData } = useGetTypesOfActivityQuery({});
  const { setFieldValue, values, handleSubmit } = useExportForm({
    onSuccess: () => {
      setShowLoading(false);
      popDialog();
    },
    onError: () => {
      setShowLoading(false);
      popDialog();
    },
  });

  useEffect(() => {
    if (values.typeIds.length > 0) {
      setFieldValue("exportAllTypes", false);
    } else {
      setFieldValue("exportAllTypes", true);
    }
  }, [values]);

  const handleChangeDate = (value: string) => {
    setPeriod(value);
    let val = 1;
    switch (value) {
      case "month":
        val = 1;
        break;
      case "3_month":
        val = 3;
        break;
      case "6_month":
        val = 6;
        break;
      case "total":
        val = 99;
        break;
      default:
        val = 1;
        break;
    }
    setFieldValue("dateFrom", sub(startOfDay(new Date()), { months: val }));
    setFieldValue("dateTo", endOfDay(new Date()));
  };

  const employeesList = map(get(employeesData, "items", []), (item) => ({
    value: item.id,
    label: `${item.firstName} ${item.lastName}`,
  }));

  const typeOfActivityList = map(
    get(typesOfActivityData, "items", []),
    (item) => ({
      value: item.id,
      label: item.name,
    })
  );

  useEffect(() => {
    if (periodDate[0] && periodDate[1]) {
      setPeriod("");
      setFieldValue("dateFrom", startOfDay(periodDate[0]));
      setFieldValue("dateTo", endOfDay(periodDate[1]));
    }
  }, [periodDate]);

  const LoadingComponent = () => {
    return (
      <Stack direction="column" spacing={2} alignItems="center">
        <IconExportLoading />
        <Typography variant="body3">Prepare file to export...</Typography>
        <LinkButton
          color="common.info"
          underline="always"
          onClick={popDialog}
          to="#"
        >
          Cancel
        </LinkButton>
      </Stack>
    );
  };

  return (
    <Dialog open onClose={popDialog} className={classes.exportActionDialog}>
      {!showLoading && <DialogTitle sx={{ mb: 2 }}>Export .CSV</DialogTitle>}{" "}
      <DialogContent sx={{ p: isMobile ? 3 : 4 }}>
        {showLoading ? (
          <LoadingComponent />
        ) : (
          <>
            <Grid container spacing={isMobile ? 2 : 3}>
              <Grid item xs={12}>
                <Typography variant="body2" mb={1.5}>
                  Select period
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.radioGroup}>
                {!isMobile ? (
                  <Stack direction="row">
                    <RadioGroup
                      items={filterItems}
                      value={period}
                      onChange={(value) => {
                        handleChangeDate(value);
                      }}
                    />
                    <Grid item display="flex" ml={3} mr={3} alignItems="center">
                      <Typography variant="body2">or</Typography>
                    </Grid>
                    <DatePicker date={periodDate} setDate={setPeriodDate} />
                  </Stack>
                ) : (
                  <Stack>
                    <TextField
                      select
                      placeholder="Month"
                      helperText=""
                      onChange={(event) => handleChangeDate(event.target.value)}
                    >
                      {filterItems.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </TextField>
                    <DatePicker date={periodDate} setDate={setPeriodDate} />
                  </Stack>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" mb={1.5}>
                  Select member/s
                </Typography>
                <RadioGroup
                  responsive
                  items={[
                    { label: "All members", value: "allMembers" },
                    { label: "Selected members", value: "selectedMembers" },
                  ]}
                  onChange={(value) => {
                    if (value === "allMembers") {
                      setFieldValue("exportAllMembers", true);
                    } else {
                      setFieldValue("exportAllMembers", false);
                    }
                  }}
                  value={
                    values.exportAllMembers ? "allMembers" : "selectedMembers"
                  }
                />
              </Grid>
            </Grid>
            {!values.exportAllMembers && (
              <Grid container spacing={isMobile ? 2 : 3} mt={1}>
                <Grid item xs={12} md={9}>
                  <Typography variant="body2">Employees</Typography>
                  <MultiSelect
                    placeholder="All employees"
                    onChange={(value) => {
                      setFieldValue("memberIds", value);
                    }}
                    options={employeesList}
                    value={values.memberIds}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container spacing={isMobile ? 2 : 3} mb={2}>
              <Grid item xs={12} md={9} mt={2}>
                <Typography variant="body2" mb={1.5}>
                  Types
                </Typography>
                <MultiSelect
                  placeholder="All types"
                  onChange={(value) => {
                    setFieldValue("typeIds", value);
                  }}
                  options={typeOfActivityList}
                  value={values.typeIds}
                />
              </Grid>
            </Grid>
            <Divider />
            <Stack direction="row" spacing={1} mt={3}>
              <AdaptiveButton
                onClick={() => {
                  setShowLoading(true);
                  handleSubmit();
                }}
              >
                Save
              </AdaptiveButton>
              <AdaptiveButton color="secondary" onClick={popDialog}>
                Cancel
              </AdaptiveButton>
            </Stack>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
