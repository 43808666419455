import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "date-picker",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    calendar: {
      "&>div>div>div>div:not(:last-of-type)": {
        borderColor: "transparent",
      },
      "& .MuiButtonBase-root": {
        fontSize: 16,
        fontWeight: 700,
      },

      "& .MuiTypography-subtitle1": {
        fontSize: 12,
        fontWeight: 700,
      },

      "& .MuiTypography-caption": {
        fontSize: 12,
        color: "#6C7294",
      },

      "& .Mui-selected": {
        backgroundColor: "#1DF7F4",
      },

      "& .MuiDateRangePickerDay-rangeIntervalDayHighlight": {
        backgroundColor: "#B1FAFA",
      },
    },
  }),
  options
);

export default useStyles;
