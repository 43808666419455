import { createApi } from "@reduxjs/toolkit/query/react";
import { baseMultipartQuery } from "../../core/store/rtk.utils";
import { UPLOAD_FILE } from "./api.routes";

interface IFile {
  file: any;
  guid: string;
}

export const fileApi = createApi({
  reducerPath: "fileApi",
  baseQuery: baseMultipartQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    uploadFile: builder.mutation<IFile, any>({
      query: (params) => ({
        url: UPLOAD_FILE,
        method: "POST",
        body: params,
      }),
    }),
  }),
});

export const { useUploadFileMutation } = fileApi;
