import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: "auto",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    block: { width: "100%", maxWidth: "unset" },
  },
  [theme.breakpoints.up("sm")]: {},
  block: {
    maxWidth: 352,
    background: theme.palette.common.white,
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.06)",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
    marginBottom: theme.spacing(5),
    borderRadius: "8px",
  },
  link: {
    color: theme.palette.common.info,
    textDecoration: "none",
  },
}));

export default useStyles;
