import { alpha } from "@mui/material";

const dark = "#131730";
const white = "#FFFFFF";

export const lightPalette = {
  type: "light",
  background: {
    default: "#fff",
    light: "#F6F6FC",
    auth: "#F5F5FC",
    info: "#EAFFFF",
    success: "#EAFFF5",
    error: "#FFEAEA",
  },
  common: {
    black: "#000",
    white,
    grey: "#6C7294",
    inputGrey: "#FBFBFF",
    dark: "#131730",
    lightGrey: "#F6F6FC",
    default: "#131730",
    error: "#FE8E8E",
    success: "#6AE3A9",
    info: "#12C8C5",
  },
  text: {
    primary: "#222222",
    secondary: "#222222",
  },
  primary: {
    contrastText: dark,
    dark: "#80F5F5",
    light: "#B1FAFA",
    main: "#B1FAFA",
  },
  secondary: {
    contrastText: dark,
    dark: "#E7EAF9",
    light: "#F2F5FF",
    main: "#F2F5FF",
  },
  third: {
    contrastText: dark,
    dark: "#F6F6FC",
    light: "#F6F6FC",
    main: "#fff",
  },
  info: {
    contrastText: dark,
    dark: "#EAFFFF",
    light: "#EAFFFF",
    main: "#EAFFFF",
  },
  success: {
    contrastText: "#131730",
    dark: "#C3FFE2",
    light: "#EAFFF5",
    main: "#EAFFF5",
  },
  warning: {
    contrastText: "#131730",
    dark: "#C27A3B",
    light: "#F5AD6E",
    main: "#F2994A",
  },
  error: {
    contrastText: "#131730",
    dark: "#FE8E8E",
    light: "#FFEAEA",
    main: "#FE8E8E",
  },
  gradient: {
    main: "linear-gradient(90deg, #667EEA 0%, #764BA2 100%);",
    contrastText: "#FFFFFF",
  },
  a11y: {
    light: "transparent",
    main: "#FFFFFF",
    dark: "#222222",
    contrastText: "transparent",
  },
  action: {
    disabledBackground: "#F6F6FC",
    disabled: "#BEC1D8",
  },
  divider: "#F6F6FC",
  grey: {
    50: alpha("#222222", 0.05),
    100: alpha("#222222", 0.1),
    200: alpha("#222222", 0.2),
    300: alpha("#222222", 0.3),
    400: alpha("#222222", 0.4),
    500: alpha("#222222", 0.5),
    600: alpha("#222222", 0.6),
    700: alpha("#222222", 0.7),
    800: alpha("#222222", 0.8),
    900: alpha("#222222", 0.9),
  },
  light: {
    50: alpha("#FFFFFF", 0.05),
    100: alpha("#FFFFFF", 0.1),
    200: alpha("#FFFFFF", 0.2),
    300: alpha("#FFFFFF", 0.3),
    400: alpha("#FFFFFF", 0.4),
    500: alpha("#FFFFFF", 0.5),
    600: alpha("#FFFFFF", 0.6),
    700: alpha("#FFFFFF", 0.7),
    800: alpha("#FFFFFF", 0.8),
    900: alpha("#FFFFFF", 0.9),
  },
};
