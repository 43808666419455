import React, { ComponentType } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import useStyles from "./styles";
import clsx from "clsx";

interface Props {
  item: Record<string, any>;
  onClick?: () => void;
}

export const NotificationItem: ComponentType<Props> = ({ item, onClick }) => {
  const classes = useStyles();

  return (
    <Box
      onClick={onClick}
      component={Paper}
      className={clsx(classes.root, {
        [classes.unread]: !item.readAt,
      })}
    >
      <Box className={classes.content}>{item.message}</Box>
      <Box className={classes.date}>{item.date}</Box>
    </Box>
  );
};
