import { makeStyles } from "@mui/styles";

const options = {
  name: "list-view",
};

const useStyles = makeStyles(
  () => ({
    root: {},
  }),
  options
);

export default useStyles;
