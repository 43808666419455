import { ComponentType, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import Zoom from "@mui/material/Zoom";
import SnackbarContext from "../../contexts/snackbar.context";
import useStyles from "./styles";

type SnackbarTypes = "success" | "error" | "info" | "warning";

interface SnackbarItem {
  message: string;
  type: SnackbarTypes;
}

const HIDE_DURATION = 5000;

export const SnackbarProvider: ComponentType = ({ children }) => {
  const [snackbars, setSnackbars] = useState<SnackbarItem[]>([]);
  const [intervals, setIntervals] = useState<any[]>([]);

  useEffect(() => {
    return () => {
      intervals.forEach((interval) => clearInterval(interval));
    };
  }, [intervals]);

  const showMessage = (message: string, type: SnackbarTypes = "info") => {
    setSnackbars((prev) => [...prev, { message, type }]);
    const interval = setInterval(() => {
      setSnackbars((prev) => prev.filter((_, index) => index !== 0));
    }, HIDE_DURATION);
    setIntervals((prev) => [...prev, interval]);
  };

  const classes = useStyles();

  return (
    <SnackbarContext.Provider value={{ showMessage }}>
      {children}
      <Stack className={classes.root} spacing={0.5}>
        {snackbars.map((snackbar, index) => (
          <Zoom in={true} key={index} unmountOnExit>
            <Alert severity={snackbar.type}>{snackbar.message}</Alert>
          </Zoom>
        ))}
      </Stack>
    </SnackbarContext.Provider>
  );
};
