import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDialog } from "../../core/hooks/useDialog";
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Theme,
  useMediaQuery,
  Paper,
} from "@mui/material";
import { url } from "../../core/utils/route.utils";
import { ROUTE_SPENDS_EDIT } from "../../constants/route.constants";
import { PageHeader } from "../../components/page-header";
import { Badge } from "../../components/badge";
import { IconExport, IconPlus } from "../../components/icons";
import { Tabs } from "../../components/tabs";
import { TableView } from "../../components/table-view";
import { receiptsColumn, receiptsColumnMobile } from "./mockdata";
import { AdaptiveButton } from "../../components/adaptive-button";
import { SearchInput } from "../../components/fields";
import { ConfirmDialog } from "../../components/dialogs/ConfirmDialog";
import { LabeledRecord } from "../../components/labeled-record";
import { AlertMessage } from "../../components/alert-message";
import { useType } from "../../core/hooks/useType";
import { useSnackbar } from "../../core/hooks/useSnackbar";

import {
  useGetUserQuery,
  useGetReceiptsQuery,
  useBlockUserMutation,
} from "../../http/api";
import { get } from "lodash";
import { SortingState } from "../../components/table-view/useTableSorting";

export const EmployeeDetails = () => {
  const [page, setPage] = useState(1);
  const [sortingState, setSortingState] = useState<SortingState>({});
  const { id, tab } = useParams();
  const { showMessage } = useSnackbar();
  const { data: userData } = useGetUserQuery({ id });
  const formattedSortingState = Object.keys(sortingState).reduce(
    (acc, item) => {
      acc[`sort[${item}]`] = sortingState[item];
      return acc;
    },
    {}
  );
  const { data: receiptsData } = useGetReceiptsQuery({
    page,
    userId: id,
    formattedSortingState,
  });
  const [blockUser] = useBlockUserMutation();
  const [openBlockUserDialog, popConfirmDialog] = useDialog(ConfirmDialog);
  const navigate = useNavigate();
  const { type } = useType();
  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const userReceiptsList = get(receiptsData, "items", []);

  const receiptsDataMobile = userReceiptsList.map((item: any) => ({
    ...item,
    dataIndex: "item",
    key: "item",
  }));

  return (
    <>
      <PageHeader
        title={`${userData?.firstName} ${userData?.lastName}`}
        backTitle="Employees"
      />
      <Container>
        <Box>
          <Tabs
            defaultValue={tab === "profile" ? 1 : 0}
            items={[
              {
                key: "receipts",
                label: "Receipts",
                content: (
                  <>
                    <Grid
                      container
                      sx={{ flexDirection: { md: "column", lg: "row" } }}
                    >
                      <Grid
                        item
                        xs={12}
                        lg={3}
                        sx={{
                          order: { md: 1, lg: 2 },
                          marginBottom: { md: 2, lg: 0 },
                          marginLeft: { md: 0, lg: "auto" },
                        }}
                      >
                        <SearchInput />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={8}
                        md={12}
                        sx={{
                          order: { md: 2, lg: 1 },
                        }}
                      >
                        <AdaptiveButton
                          sx={{
                            width: {
                              xs: "100%",
                              lg: "142px",
                            },
                            marginTop: isMobile ? "8px" : 0,
                          }}
                          color="secondary"
                          startIcon={<IconExport />}
                        >
                          Export .CSV
                        </AdaptiveButton>
                      </Grid>
                    </Grid>

                    <Box mt={2}>
                      {!isMobile ? (
                        <TableView
                          pagination={{
                            page: receiptsData?.currentPage,
                            perPage: receiptsData?.recordsPerPage,
                            totalPages: receiptsData?.totalPages,
                            total: 8,
                          }}
                          onRowClick={(row) => {
                            navigate(
                              url(ROUTE_SPENDS_EDIT, {
                                type,
                                id: row.id,
                              })
                            );
                          }}
                          hideColumns={
                            isTablet
                              ? ["time", "description", "comments", "type"]
                              : ["employee"]
                          }
                          expandable={
                            isTablet && {
                              rowExpandable: () => true,
                              expandedRowRender: (record) => (
                                <>
                                  <LabeledRecord
                                    label="Type:"
                                    value={
                                      <Badge
                                        label={record.type}
                                        variant={"primary"}
                                      />
                                    }
                                  />
                                  <LabeledRecord
                                    label="Time:"
                                    value={record.time}
                                  />
                                  <LabeledRecord
                                    label="Comments:"
                                    value={record.comments}
                                  />
                                  <LabeledRecord
                                    label="Description:"
                                    value={record.description}
                                  />
                                </>
                              ),
                            }
                          }
                          dataSource={userReceiptsList.slice(0, 10)}
                          columns={receiptsColumn}
                          onPageChange={(page) => setPage(page)}
                          handleSort={(sortableField) =>
                            setSortingState(sortableField)
                          }
                        />
                      ) : (
                        <>
                          <TableView
                            pagination={{
                              page: receiptsData?.currentPage,
                              perPage: receiptsData?.recordsPerPage,
                              totalPages: receiptsData?.totalPages,
                              total: 8,
                            }}
                            expandable={{
                              rowExpandable: () => false,
                              expandedRowRender: (record) => (
                                <>
                                  <LabeledRecord
                                    label="Id:"
                                    value={record.id}
                                  />
                                  <LabeledRecord
                                    label="Time:"
                                    value={record.time}
                                  />
                                  <LabeledRecord
                                    label="Type:"
                                    value={
                                      <Badge
                                        label={record.type}
                                        variant={"primary"}
                                      />
                                    }
                                  />
                                  <LabeledRecord
                                    label="Description:"
                                    value={record.description}
                                  />
                                  email{" "}
                                </>
                              ),
                            }}
                            dataSource={receiptsDataMobile.slice(0, 10)}
                            columns={receiptsColumnMobile}
                            onPageChange={(page) => setPage(page)}
                            handleSort={(sortableField) =>
                              setSortingState(sortableField)
                            }
                          />
                          <Button fullWidth startIcon={<IconPlus />}>
                            Show more
                          </Button>
                        </>
                      )}
                    </Box>
                  </>
                ),
              },
              {
                key: "profile",
                label: "Profile",
                content: (
                  <Container>
                    <Box component={Paper} p={isMobile ? 2 : 4} my={3}>
                      {userData?.newEmail && <AlertMessage id={id} />}
                      <Grid container direction="column">
                        <Grid item xs={3}>
                          <LabeledRecord label="Role:" value="Employee" />
                        </Grid>
                        <Grid item xs={3}>
                          <LabeledRecord
                            label="Status:"
                            value={userData?.status}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <LabeledRecord
                            label="Email:"
                            value={userData?.userName}
                          />
                        </Grid>
                        <Stack
                          spacing={1.2}
                          direction="row"
                          alignItems="center"
                        >
                          {userData?.status !== "blocked" && (
                            <Button
                              color="secondary"
                              onClick={() =>
                                openBlockUserDialog({
                                  message:
                                    "Do you really want to block this user profile?",
                                  okText: "Confirm",
                                  onConfirm: async () => {
                                    const res = await blockUser({
                                      id,
                                    });
                                    const error = get(res, "error", null);
                                    if (error) {
                                      const message = get(
                                        error,
                                        "data.title",
                                        "Something went wrong."
                                      );
                                      showMessage(message, "error");
                                    } else {
                                      showMessage(
                                        "User was blocked successfully",
                                        "success"
                                      );
                                      popConfirmDialog();
                                    }
                                  },
                                })
                              }
                            >
                              Block User
                            </Button>
                          )}
                        </Stack>
                      </Grid>
                    </Box>
                  </Container>
                ),
              },
            ]}
          />
        </Box>
      </Container>
    </>
  );
};
