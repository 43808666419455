import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "labeled-record",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down("md")]: {
      root: {
        marginBottom: theme.spacing(1.25),
      },
      label: {
        fontSize: "12px !important",
      },
      value: {
        fontSize: "14px !important",
      },
    },
    label: {
      fontSize: 14,
      color: theme.palette.common.grey,
      marginBottom: theme.spacing(0.5),
    },
    value: {
      fontSize: 16,
    },
  }),
  options
);

export default useStyles;
