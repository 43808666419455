import React from "react";
import { useNavigate, Link } from "react-router-dom";

import { useOrganizationForm } from "../../../core/hooks/useOrganizationForm";
import {
  Grid,
  Typography,
  Box,
  Select,
  MenuItem,
  InputBase,
  Container,
  Paper,
  Divider,
} from "@mui/material";
import { useGetMeQuery, useGetTypesOfBusinessQuery } from "../../../http/api";
import { IconSave } from "../../../components/icons";
import { AdaptiveButton } from "../../../components/adaptive-button";
import { PageHeader } from "../../../components/page-header";
import { Space } from "../../../components/space";
import { useStyles } from "./styles";
import { url } from "../../../core/utils/route.utils";
import {
  ROUTE_SPENDS_INDEX,
  ROUTE_INVITE_EMPLOYEES,
} from "../../../constants/route.constants";
import { get, map } from "lodash";
import { useSnackbar } from "../../../core/hooks/useSnackbar";
import { useType } from "../../../core/hooks/useType";

export const FillCompanyInfo = () => {
  const navigate = useNavigate();
  const { type } = useType();
  const { showMessage } = useSnackbar();
  const { data: userData } = useGetMeQuery({});
  const { getFieldProps, handleSubmit } = useOrganizationForm({
    id: undefined,
    onSuccess: () => {
      showMessage("Organization was created successfully", "success");
      navigate(url(ROUTE_INVITE_EMPLOYEES, { type }));
    },
    onError: (message) => {
      showMessage(message, "error");
    },
  });

  const { data } = useGetTypesOfBusinessQuery({});
  const classes = useStyles();

  const typeOfBusinessList = map(get(data, "items", []), (item) => ({
    value: item.guid,
    label: item.name,
  }));

  return (
    <Box className={classes.root}>
      <PageHeader title="Fill in company information" />
      <Container>
        <Box component={Paper} p={4} mt={3} mb={5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={8} mb={3}>
              <Typography variant="body2">Company name</Typography>
              <InputBase
                {...getFieldProps("name")}
                className={classes.inputField}
                required
                id="outlined-required"
                defaultValue="Biffco Enterprises Ltd."
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={3}>
            <Grid item xs={12} md={6} lg={4}>
              <Typography variant="body2">Administartor name</Typography>
              <InputBase
                className={classes.inputField}
                required
                id="outlined-required"
                value={`${userData?.firstName} ${userData?.lastName}`}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography variant="body2">Administartor email</Typography>
              <InputBase
                className={classes.inputField}
                required
                id="outlined-required"
                value={userData?.userName}
                disabled
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={3}>
            <Grid item xs={12} md={12} lg={8}>
              <Typography variant="body2">Type of Business</Typography>
              <Select
                {...getFieldProps("typeOfBusinessGuid")}
                defaultValue="select"
                id="grouped-select"
                className={classes.selectField}
              >
                <MenuItem value="select" disabled>
                  Select
                </MenuItem>
                {typeOfBusinessList.map((item) => (
                  <MenuItem
                    value={item.value}
                    key={`type-of-business-${item.value}`}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 3, mb: 3 }} />
          <Space spacing={1}>
            <AdaptiveButton
              color="primary"
              startIcon={<IconSave />}
              onClick={() => handleSubmit()}
            >
              Save
            </AdaptiveButton>
            {/* <AdaptiveButton
              component={Link}
              to={url(ROUTE_SPENDS_INDEX, { type })}
              color="secondary"
            >
              Cancel
            </AdaptiveButton> */}
          </Space>
        </Box>
      </Container>
    </Box>
  );
};
