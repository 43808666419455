import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Box, Container, Grid, useMediaQuery, Theme } from "@mui/material";
import { AdaptiveButton } from "../../components/adaptive-button";
import { Badge } from "../../components/badge";
import { IconEmptyData, IconPlus } from "../../components/icons";
import { PageHeader } from "../../components/page-header";
import { url } from "../../core/utils/route.utils";
import {
  ROUTE_SPENDS_TEMPLATE_EDIT,
  ROUTE_SPENDS_TEMPLATE_CREATE,
} from "../../constants/route.constants";
import { TableView } from "../../components/table-view";
import { SearchInput } from "../../components/fields";
import { templatesColumn } from "../../core/columns/templates.columns";
import { useType } from "../../core/hooks/useType";
import { useGetMeQuery, useGetTemplatesQuery } from "../../http/api";
import { get } from "lodash";
import { templatesColumnMobile } from "../../core/columns/templates.columns";
import { LabeledRecord } from "../../components/labeled-record";
import { EmptyState } from "../../components/empty-state";
import useDebounce from "../../core/hooks/useDebounce";
import useStyles from "./templatesStyles";
import { SortingState } from "../../components/table-view/useTableSorting";

export const TemplateList = () => {
  const navigate = useNavigate();
  const { type } = useType();
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sortingState, setSortingState] = useState<SortingState>({});
  const debouncedValue = useDebounce(search, 600);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const classes = useStyles();

  const formattedSortingState = Object.keys(sortingState).reduce(
    (acc, item) => {
      acc[`sort[${item}]`] = sortingState[item];
      return acc;
    },
    {}
  );
  const { data: userData } = useGetMeQuery({});
  const { data, refetch, isFetching } = useGetTemplatesQuery({
    page,
    userId: userData?.id || 0,
    search: debouncedValue,
    formattedSortingState,
  });

  useEffect(() => {
    refetch();
  }, [searchParams]);

  const templatesList = get(data, "items", []);

  return (
    <>
      <PageHeader title="Receipt templates" backTitle="New receipts">
        <AdaptiveButton
          component={Link}
          to={url(ROUTE_SPENDS_TEMPLATE_CREATE, { type })}
          startIcon={<IconPlus />}
        >
          {!isMobile && "Add new template"}
        </AdaptiveButton>
      </PageHeader>
      <Container className={classes.root}>
        <Box my={3}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <SearchInput
                onChange={(event) => setSearch(event.target.value)}
              />
            </Grid>
          </Grid>
          {templatesList.length || isFetching ? (
            <Box mt={3}>
              {!isMobile ? (
                <TableView
                  isLoading={isFetching}
                  pagination={{
                    page: data?.currentPage,
                    perPage: data?.recordsPerPage,
                    totalPages: data?.totalPages,
                    total: 8,
                  }}
                  onRowClick={(row) => {
                    navigate(
                      url(ROUTE_SPENDS_TEMPLATE_EDIT, {
                        type,
                        id: row.id,
                      })
                    );
                  }}
                  dataSource={templatesList}
                  columns={templatesColumn(type)}
                  onPageChange={(page) => setPage(page)}
                  handleSort={(sortableField) => setSortingState(sortableField)}
                />
              ) : (
                <TableView
                  pagination={{
                    page: data?.currentPage,
                    perPage: data?.recordsPerPage,
                    totalPages: data?.totalPages,
                    total: 8,
                  }}
                  expandable={{
                    rowExpandable: () => true,
                    expandedRowRender: (record) => (
                      <>
                        <LabeledRecord label="Id:" value={record.id} />
                        <LabeledRecord
                          label="Type:"
                          value={
                            <Badge label={record.type} variant={"primary"} />
                          }
                        />
                        <LabeledRecord label="Time:" value={record.time} />
                        <LabeledRecord
                          label="Description:"
                          value={record.description}
                        />
                      </>
                    ),
                  }}
                  onRowClick={(row) => {
                    navigate(
                      url(ROUTE_SPENDS_TEMPLATE_EDIT, {
                        type,
                        id: row.id,
                      })
                    );
                  }}
                  dataSource={templatesList}
                  columns={templatesColumnMobile}
                  onPageChange={(page) => setPage(page)}
                  handleSort={(sortableField) => setSortingState(sortableField)}
                />
              )}
            </Box>
          ) : (
            <EmptyState
              icon={<IconEmptyData />}
              description="You have no
                templates"
            />
          )}
        </Box>
      </Container>
    </>
  );
};
