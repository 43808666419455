import {
  ROUTE_NOTIFICATIONS,
  ROUTE_PROFILE,
  ROUTE_EMPLOYEES_LIST,
  ROUTE_SPENDS_INDEX,
} from "../../../constants/route.constants";

export const navigationElements = [
  {
    id: 1,
    title: "Spends",
    route: ROUTE_SPENDS_INDEX,
  },
  {
    id: 2,
    title: "Employees",
    route: ROUTE_EMPLOYEES_LIST,
    userType: "employer",
  },
  {
    id: 3,
    title: "Notifications",
    route: ROUTE_NOTIFICATIONS,
    count: 29,
    userType: "employer",
  },
  {
    id: 4,
    title: "Profile",
    route: ROUTE_PROFILE,
    avatar: "/assets/images/profile-1.jpeg",
  },
];
