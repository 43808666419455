import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#fff",
  },
  formContainer: {
    borderRadius: "8px",
    padding: "32px",
    border: `1px solid ${theme.palette.secondary.dark}`,
    backgroundColor: theme.palette.common.white,
  },
  inputField: {
    border: `1px solid ${theme.palette.secondary.dark}`,
    background: theme.palette.common.inputGrey,
    borderRadius: "4px",
    width: "100%",
    height: "40px",
    padding: "8px 12px",
    marginTop: "4px",
  },
  selectField: {
    marginTop: "4px",
    width: "100%",
    height: "40px",
  },
  buttonsContainer: {
    padding: "24px",
  },
}));
