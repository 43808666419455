import { Link } from "react-router-dom";
import { Box, Typography, Button, Container } from "@mui/material";
import { ROUTE_FILL_COMPANY_INFO } from "../../../constants/route.constants";
import { BackNavigationButton } from "../../../components/buttons";
import { useStyles } from "./styles";

export const MailAccess = () => {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Box className={classes.navigationStack}>
        <BackNavigationButton title="back" />
      </Box>
      <Box className={classes.wrapper}>
        <Box className={classes.block}>
          <Typography
            variant="h2"
            sx={{ mb: 3 }}
            align="center"
            className={classes.description}
          >
            Allow access to your email address john@gmail.com{" "}
          </Typography>
          <Typography variant="body2" sx={{ mb: 3 }} align="center">
            Rimshare will check your mail for receipts and send you
            notifications about new receipts in the compensation system
          </Typography>
          <Button fullWidth component={Link} to={ROUTE_FILL_COMPANY_INFO}>
            Allow
          </Button>
        </Box>
      </Box>
    </Container>
  );
};
