import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import { get } from "lodash";
import { AmountView } from "../../components/amount-view";
import { Badge } from "../../components/badge";
import { LinkButton } from "../../components/buttons/LinkButton";
import { DataTableColumn } from "../../components/table-view/TableView";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
} from "../../constants/date.constants";
import { ROUTE_SPENDS_EDIT } from "../../constants/route.constants";
import { spendsStatusBadgeLabel } from "../../constants/statuses.constants";
import { url } from "../utils/route.utils";

const spendsStatusBadge = {
  approved: "success",
  "approved partly": "warning",
  declined: "error",
};

export const spendsColumn: DataTableColumn[] = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    sortable: true,
    render: (text) => (
      <Typography component={"span"} variant={"body2"} sx={{ fontWeight: 500 }}>
        {text}
      </Typography>
    ),
  },
  {
    title: "Organization",
    dataIndex: "vendorName",
    key: "vendorName",
    cellProps: {
      sx: {
        whiteSpace: "nowrap",
      },
    },
    render: (text) => (
      <Typography component="span" variant={"body2"} sx={{ fontWeight: 500 }}>
        {text}
      </Typography>
    ),
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    render: (text) => {
      return (
        <Typography component="span" variant={"body2"} sx={{ fontWeight: 500 }}>
          {text || "-"}
        </Typography>
      );
    },
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    sortable: true,
    cellProps: {
      sx: {
        whiteSpace: "nowrap",
      },
    },
    render: (text) => {
      if (text) {
        return <Badge label={text} variant="primary" />;
      }
      return (
        <LinkButton
          color="common.info"
          underline="always"
          to={url(ROUTE_SPENDS_EDIT, { type: "employee", id: "new" })}
        >
          Add type
        </LinkButton>
      );
    },
  },
  {
    title: "Date",
    dataIndex: "createdAt",
    key: "createdAt",
    sortable: true,
    cellProps: {
      sx: {
        whiteSpace: "nowrap",
      },
    },
    render: (date) => {
      if (date) {
        return format(new Date(date), DEFAULT_DATE_FORMAT);
      }
      return "-";
    },
  },
  {
    title: "Time",
    dataIndex: "createdAt",
    key: "createdAt",
    sortable: true,
    cellProps: {
      sx: {
        whiteSpace: "nowrap",
      },
    },
    render: (date) => {
      if (date) {
        return format(new Date(date), DEFAULT_TIME_FORMAT);
      }
      return "-";
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    sortable: true,
    cellProps: {
      align: "right",
    },
    render: (amount) => <AmountView amount={amount} />,
  },
];

export const spendsApprovedColumns: DataTableColumn[] = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    sortable: true,
    render: (text) => (
      <Typography component="span" variant={"body2"} sx={{ fontWeight: 500 }}>
        {text}
      </Typography>
    ),
  },
  {
    title: "Approved By",
    dataIndex: "approvedBy",
    key: "approvedBy",
    cellProps: {
      sx: {
        whiteSpace: "nowrap",
      },
    },
    render: (text, { updatedBy, updatedAt }) => (
      <>
        <Typography variant={"body2"} sx={{ fontWeight: 500 }}>
          {updatedBy.firstName} {updatedBy.lastName}
        </Typography>
        <Typography variant={"body2"} sx={{ fontWeight: 500 }}>
          ({format(new Date(updatedAt), DEFAULT_DATE_FORMAT)})
        </Typography>
      </>
    ),
  },
  {
    title: "Organization",
    dataIndex: "vendorName",
    key: "vendorName",
    cellProps: {
      sx: {
        whiteSpace: "nowrap",
      },
    },
    render: (text) => (
      <Typography component="span" variant={"body2"} sx={{ fontWeight: 500 }}>
        {text}
      </Typography>
    ),
  },
  {
    title: "Employee",
    dataIndex: "ownerName",
    key: "ownerName",
    cellProps: {
      sx: {
        whiteSpace: "nowrap",
      },
    },
    render: (text) => (
      <Typography component="span" variant={"body2"} sx={{ fontWeight: 500 }}>
        {text}
      </Typography>
    ),
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    render: (text, { id }) => {
      if (text) {
        return (
          <Typography component="span" variant={"body2"}>
            {text}
          </Typography>
        );
      }

      return (
        <LinkButton
          color={"common.info"}
          underline={"always"}
          to={url(ROUTE_SPENDS_EDIT, { type: "employee", id })}
        >
          Add description
        </LinkButton>
      );
    },
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    sortable: true,
    cellProps: {
      sx: {
        whiteSpace: "nowrap",
      },
    },
    render: (text) => {
      if (text) {
        return <Badge label={text} variant="primary" />;
      }
      return (
        <LinkButton
          color="common.info"
          underline="always"
          to={url(ROUTE_SPENDS_EDIT, { type: "employee", id: "new" })}
        >
          Add type
        </LinkButton>
      );
    },
  },
  {
    title: "Date",
    dataIndex: "createdAt",
    key: "createdAt",
    sortable: true,
    cellProps: {
      sx: {
        whiteSpace: "nowrap",
      },
    },
    render: (date) => {
      if (date) {
        return format(new Date(date), DEFAULT_DATE_FORMAT);
      }
      return "-";
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    sortable: true,
    cellProps: {
      align: "right",
    },
    render: (amount) => <AmountView amount={amount} />,
  },
];

export const filterItems = [
  {
    label: "Month",
    value: "month",
  },
  {
    label: "3 months",
    value: "3_month",
  },
  {
    label: "6 months",
    value: "6_month",
  },
  {
    label: "Total",
    value: "total",
  },
];

export const spendsOnApprovalColumn: DataTableColumn[] = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (text) => (
      <Typography variant={"body2"} sx={{ fontWeight: 500 }}>
        {text}
      </Typography>
    ),
  },
  {
    title: "Organization",
    dataIndex: "vendorName",
    key: "vendorName",
    cellProps: {
      sx: {
        whiteSpace: "nowrap",
      },
    },
    render: (text) => (
      <Typography variant={"body2"} sx={{ fontWeight: 500 }}>
        {text}
      </Typography>
    ),
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    render: (text, { id }) => {
      if (text) {
        return <Typography variant={"body2"}>{text}</Typography>;
      }

      return (
        <LinkButton
          color={"common.info"}
          underline={"always"}
          to={url(ROUTE_SPENDS_EDIT, { id })}
        >
          Add description
        </LinkButton>
      );
    },
  },
  {
    title: "Comments",
    dataIndex: "commentsCount",
    key: "commentsCount",
    render: (text) => (
      <Typography variant={"body2"} sx={{ fontWeight: 500 }}>
        {text ? text : "-"}
      </Typography>
    ),
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    sortable: true,
    cellProps: {
      sx: {
        whiteSpace: "nowrap",
      },
    },
    render: (text) => {
      if (text) {
        return <Badge label={text} variant="primary" />;
      }
      return (
        <LinkButton
          color="common.info"
          underline="always"
          to={url(ROUTE_SPENDS_EDIT, { id: "new" })}
        >
          Add type
        </LinkButton>
      );
    },
  },
  {
    title: "Date",
    dataIndex: "createdAt",
    key: "createdAt",
    sortable: true,
    cellProps: {
      sx: {
        whiteSpace: "nowrap",
      },
    },
    render: (date) => {
      if (date) {
        return (
          <Typography>{format(new Date(date), DEFAULT_DATE_FORMAT)}</Typography>
        );
      }
      return "-";
    },
  },
  {
    title: "Time",
    dataIndex: "createdAt",
    key: "createdAt",
    sortable: true,
    cellProps: {
      sx: {
        whiteSpace: "nowrap",
      },
    },
    render: (date) => {
      if (date) {
        return (
          <Typography>{format(new Date(date), DEFAULT_TIME_FORMAT)}</Typography>
        );
      }
      return "-";
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    sortable: true,
    cellProps: {
      align: "right",
    },
    render: (amount) => <AmountView amount={amount} />,
  },
];

export const spendsHistoryColumns: DataTableColumn[] = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    sortable: true,
    render: (text) => (
      <Typography component="span" variant={"body2"} sx={{ fontWeight: 500 }}>
        {text}
      </Typography>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    cellProps: {
      sx: {
        whiteSpace: "nowrap",
      },
    },
    render: (text) => {
      return (
        <Badge
          label={spendsStatusBadgeLabel(text)}
          variant={get(spendsStatusBadge, text, "primary")}
        />
      );
    },
  },
  {
    title: "Organization",
    dataIndex: "vendorName",
    key: "vendorName",
    cellProps: {
      sx: {
        whiteSpace: "nowrap",
      },
    },
    render: (text) => (
      <Typography component="span" variant={"body2"} sx={{ fontWeight: 500 }}>
        {text}
      </Typography>
    ),
  },
  {
    title: "Employee",
    dataIndex: "ownerName",
    key: "ownerName",
    cellProps: {
      sx: {
        whiteSpace: "nowrap",
      },
    },
    render: (text) => (
      <Typography component="span" variant={"body2"} sx={{ fontWeight: 500 }}>
        {text}
      </Typography>
    ),
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    render: (text, { id }) => {
      if (text) {
        return (
          <Typography
            component="span"
            variant={"body2"}
            sx={{ fontWeight: 500 }}
          >
            {text}
          </Typography>
        );
      }

      return (
        <LinkButton
          color={"common.info"}
          underline={"always"}
          to={url(ROUTE_SPENDS_EDIT, { type: "employee", id })}
        >
          Add description
        </LinkButton>
      );
    },
  },
  {
    title: "Comments",
    dataIndex: "commentsCount",
    key: "commentsCount",
    render: (text) => (
      <Typography component="span" variant={"body2"} sx={{ fontWeight: 500 }}>
        {text ? text : "-"}
      </Typography>
    ),
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    sortable: true,
    cellProps: {
      sx: {
        whiteSpace: "nowrap",
      },
    },
    render: (text) => {
      if (text) {
        return <Badge label={text} variant="primary" />;
      }
      return (
        <LinkButton
          color="common.info"
          underline="always"
          to={url(ROUTE_SPENDS_EDIT, { type: "employee", id: "new" })}
        >
          Add type
        </LinkButton>
      );
    },
  },
  {
    title: "Date",
    dataIndex: "createdAt",
    key: "createdAt",
    sortable: true,
    cellProps: {
      sx: {
        whiteSpace: "nowrap",
      },
    },
    render: (date) => {
      if (date) {
        return format(new Date(date), DEFAULT_DATE_FORMAT);
      }
      return "-";
    },
  },
  {
    title: "Time",
    dataIndex: "createdAt",
    key: "createdAt",
    sortable: true,
    cellProps: {
      sx: {
        whiteSpace: "nowrap",
      },
    },
    render: (date) => {
      if (date) {
        return format(new Date(date), DEFAULT_TIME_FORMAT);
      }
      return "-";
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    sortable: true,
    cellProps: {
      align: "right",
    },
    render: (amount) => <AmountView amount={amount} />,
  },
];

export const spendsOnApprovalColumns: DataTableColumn[] = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    sortable: true,
    render: (text) => (
      <Typography variant={"body2"} sx={{ fontWeight: 500 }}>
        {text}
      </Typography>
    ),
  },
  {
    title: "Organization",
    dataIndex: "vendorName",
    key: "vendorName",
    cellProps: {
      sx: {
        whiteSpace: "nowrap",
      },
    },
    render: (text) => (
      <Typography variant={"body2"} sx={{ fontWeight: 500 }}>
        {text}
      </Typography>
    ),
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    render: (text, { id }) => {
      if (text) {
        return <Typography variant={"body2"}>{text}</Typography>;
      }

      return (
        <LinkButton
          color={"common.info"}
          underline={"always"}
          to={url(ROUTE_SPENDS_EDIT, { type: "employee", id })}
        >
          Add description
        </LinkButton>
      );
    },
  },
  {
    title: "Comments",
    dataIndex: "commentsCount",
    key: "commentsCount",
    render: (text) => (
      <Typography variant={"body2"} sx={{ fontWeight: 500 }}>
        {text ? text : "-"}
      </Typography>
    ),
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    sortable: true,
    cellProps: {
      sx: {
        whiteSpace: "nowrap",
      },
    },
    render: (text) => {
      if (text) {
        return <Badge label={text} variant="primary" />;
      }
      return (
        <LinkButton
          color="common.info"
          underline="always"
          to={url(ROUTE_SPENDS_EDIT, { type: "employee", id: "new" })}
        >
          Add type
        </LinkButton>
      );
    },
  },
  {
    title: "Date",
    dataIndex: "createdAt",
    key: "createdAt",
    sortable: true,
    cellProps: {
      sx: {
        whiteSpace: "nowrap",
      },
    },
    render: (date) => {
      if (date) {
        return format(new Date(date), DEFAULT_DATE_FORMAT);
      }
      return "-";
    },
  },
  {
    title: "Time",
    dataIndex: "createdAt",
    key: "createdAt",
    sortable: true,
    cellProps: {
      sx: {
        whiteSpace: "nowrap",
      },
    },
    render: (date) => {
      if (date) {
        return format(new Date(date), DEFAULT_TIME_FORMAT);
      }
      return "-";
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    sortable: true,
    cellProps: {
      align: "right",
    },
    render: (amount) => <AmountView amount={amount} />,
  },
];

export const spendsColumnMobile: DataTableColumn[] = [
  {
    title: "Select ALL",
    dataIndex: "item",
    key: "item",
    render: (text, { vendorName, createdAt, amount }) => {
      if (text === "SELECT ALL") {
        return null;
      }
      return (
        <>
          <Box>
            <Typography
              mr={1}
              component={"span"}
              mb={3}
              variant="body2"
              color="common.grey"
            >
              Organization:
            </Typography>
            {vendorName}
          </Box>
          <Box>
            <Typography
              mr={1}
              component={"span"}
              mb={3}
              variant="body2"
              color="common.grey"
            >
              Date:
            </Typography>
            {createdAt ? format(new Date(createdAt), DEFAULT_DATE_FORMAT) : "-"}
          </Box>
          <Box>
            <Typography
              mr={1}
              component={"span"}
              mb={3}
              variant="body2"
              color="common.grey"
            >
              Amount:
            </Typography>
            {amount}
          </Box>
        </>
      );
    },
  },
];

export const spendsApprovedMobileColumns: DataTableColumn[] = [
  {
    title: "Select ALL",
    dataIndex: "item",
    key: "item",
    render: (text, { vendorName, createdAt, amount }) => {
      if (text === "SELECT ALL") {
        return null;
      }
      return (
        <>
          <Box>
            <Box>
              <Typography
                mr={1}
                component={"span"}
                mb={3}
                variant="body2"
                color="common.grey"
              >
                Organization:
              </Typography>
              {vendorName}
            </Box>
            <Box>
              <Typography
                mr={1}
                component={"span"}
                mb={3}
                variant="body2"
                color="common.grey"
              >
                Date:
              </Typography>
              {createdAt
                ? format(new Date(createdAt), DEFAULT_DATE_FORMAT)
                : "-"}
            </Box>
            <Box>
              <Typography
                mr={1}
                component={"span"}
                mb={3}
                variant="body2"
                color="common.grey"
              >
                Amount:
              </Typography>
              {amount}
            </Box>
          </Box>
        </>
      );
    },
  },
];

export const spendsOnApprovalMobileColumns: DataTableColumn[] = [
  {
    title: "Select ALL",
    dataIndex: "item",
    key: "item",
    render: (text, { vendorName, createdAt, amount }) => {
      if (text === "SELECT ALL") {
        return null;
      }
      return (
        <>
          <Box>
            <Box>
              <Typography
                mr={1}
                component={"span"}
                mb={3}
                variant="body2"
                color="common.grey"
              >
                Organization:
              </Typography>
              {vendorName}
            </Box>
            <Box>
              <Typography
                mr={1}
                component={"span"}
                mb={3}
                variant="body2"
                color="common.grey"
              >
                Date:
              </Typography>
              {createdAt
                ? format(new Date(createdAt), DEFAULT_DATE_FORMAT)
                : "-"}
            </Box>
            <Box>
              <Typography
                mr={1}
                component={"span"}
                mb={3}
                variant="body2"
                color="common.grey"
              >
                Amount:
              </Typography>
              {amount}
            </Box>
          </Box>
        </>
      );
    },
  },
];

export const templatesColumn: DataTableColumn[] = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Organization",
    dataIndex: "organization",
    key: "organization",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (text) => <Badge label={text} variant="primary" />,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    cellProps: {
      align: "right",
    },
    render: (amount) => <AmountView amount={amount} />,
  },
];
