import React, { ComponentType } from "react";
import { Menu } from "@mui/material";
import { BoxProps } from "@mui/material/Box";
import { RadioGroup } from "../fields";

import useStyles from "./styles";

type Item = Record<string, any> & { id: number };

interface Props {
  dataSource: Item[];
  renderItem: (record: Item, index: number) => JSX.Element;
  anchorEl: any;
  open: any;
  onClose: () => void;
  ListItemProps?: Partial<BoxProps>;
}

export const MenuView: ComponentType<Props> = ({
  dataSource,
  renderItem,
  anchorEl,
  open,
  onClose,
}) => {
  const classes = useStyles();
  return (
    <Menu
      className={classes.root}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{ "aria-labelledby": "basic-button" }}
    >
      {dataSource.map((record, index) => renderItem(record, index))}
      <RadioGroup
        responsive
        items={[
          { label: "Employer view", value: "employerView" },
          { label: "Employee view", value: "employeeView" },
        ]}
        defaultValue="allMembers"
      />
    </Menu>
  );
};
