import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../core/store/rtk.utils";
import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_COUNT,
  MARK_NOTIFICATION_AS_READ,
} from "./api.routes";

interface IGetNotificationsResponse {
  items: [
    {
      id: number;
      text: string;
      guid: string;
      type: string;
      createdAt: number;
      readAt: number;
    }
  ];
  currentPage: number;
  totalPages: number;
  recordsPerPage: number;
}
interface IGetNotificationsCountResponse {
  count: number;
}
interface IMarkNotificationAsReadRequest {
  ids: number[];
}

export const notificationsApi = createApi({
  reducerPath: "notificationsApi",
  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getNotifications: builder.query<IGetNotificationsResponse, any>({
      query: (params) => ({
        url: GET_NOTIFICATIONS,
        method: "GET",
        params: { page: params.page },
      }),
    }),
    getNotificationsCount: builder.query<IGetNotificationsCountResponse, any>({
      query: (params) => ({
        url: GET_NOTIFICATIONS_COUNT,
        method: "GET",
        params,
      }),
    }),
    markNotificationAsRead: builder.mutation<
      IMarkNotificationAsReadRequest,
      any
    >({
      query: (params) => ({
        url: MARK_NOTIFICATION_AS_READ,
        method: "POST",
        body: params,
      }),
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useGetNotificationsCountQuery,
  useMarkNotificationAsReadMutation,
} = notificationsApi;
