import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "menu-item",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    navLink: {
      fontSize: 14,
      fontWeight: 500,
      textDecoration: "none",
      color: theme.palette.common.dark,
      "& .MuiBox-root": {
        borderRadius: 40,
        backgroundColor: theme.palette.background.light,
      },
    },
    navLinkActive: {
      color: theme.palette.common.info,
      "& .MuiBox-root": {
        backgroundColor: "#B1FAFA",
        color: theme.palette.common.black,
      },
    },
  }),
  options
);

export default useStyles;
