import React, { ComponentType } from "react";
import Box, { BoxProps } from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import useStyles from "./styles";
import { PaginationItem } from "@mui/lab";

type Item = Record<string, any> & { id: number };

interface Props {
  dataSource: Item[];
  renderItem: (record: Item, index: number) => JSX.Element;
  ListItemProps?: Partial<BoxProps>;
  totalPages: number;
  onPageChange?: (page: number) => void;
}

export const ListView: ComponentType<Props> = ({
  dataSource,
  renderItem,
  ListItemProps,
  totalPages,
  onPageChange,
}) => {
  const classes = useStyles();
  return (
    <Box component="div" className={classes.root}>
      <Box>
        {dataSource?.map((record, index) => {
          return (
            <Box key={record.id} {...ListItemProps}>
              {renderItem(record, index)}
            </Box>
          );
        })}
      </Box>
      <Box mt={4}>
        <Pagination
          onChange={(event: React.ChangeEvent<unknown>, page: number) =>
            onPageChange(page)
          }
          count={totalPages}
          renderItem={(item) => (
            <PaginationItem
              components={{
                previous: () => <span>Previous</span>,
                next: () => <span>Next</span>,
              }}
              {...item}
            />
          )}
        />
      </Box>
    </Box>
  );
};
