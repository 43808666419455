import React, { ComponentType } from "react";
import { Link } from "react-router-dom";
import { url } from "../../../core/utils/route.utils";
import { useLocation, matchPath } from "react-router-dom";
import {
  Avatar,
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
  Theme,
} from "@mui/material";
import clsx from "clsx";
import { MainLogo } from "../../logos";
import { IconArrow, IconBurger } from "../../icons";
import { Badge } from "../../badge";
import { Space } from "../../space";
import { MenuView } from "../../menu-view";
import { MenuItem } from "../../menu-view/menu-item";
import { navigationElements } from "./mockdata";
import { generateInitials } from "../../../core/utils/generateInitials";
import { useGetNotificationsCountQuery } from "../../../http/api";
import {
  ROUTE_PROFILE,
  ROUTE_SPENDS_INDEX,
} from "../../../constants/route.constants";
import useStyles from "./styles";
import { useType } from "../../../core/hooks/useType";
import { useGetMeQuery } from "../../../http/api";
import { get } from "lodash";

interface IProps {
  isNavigationVisible?: boolean;
  whiteBg?: boolean;
  noLogoFollow?: boolean;
}

export const Header: ComponentType<IProps> = ({
  isNavigationVisible = true,
  whiteBg = false,
  noLogoFollow = false,
}) => {
  const { data: notificationsCountData } = useGetNotificationsCountQuery({});
  const { data } = useGetMeQuery({});
  const classes = useStyles();
  const location = useLocation();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { type } = useType();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const notificationsCount = get(notificationsCountData, "count", 0);

  const initials = data ? generateInitials(data.firstName, data.lastName) : "";

  return (
    <Box
      component="header"
      py={2}
      className={clsx({
        [classes.whiteHeader]: whiteBg,
      })}
    >
      <Stack
        component={Container}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {noLogoFollow ? (
          <MainLogo />
        ) : (
          <Link to={url(ROUTE_SPENDS_INDEX, { type })}>
            <MainLogo />
          </Link>
        )}
        {isNavigationVisible && (
          <Box>
            {!isMobile ? (
              <Stack
                component="nav"
                direction="row"
                spacing={4}
                alignItems="center"
              >
                {navigationElements.map((item) => {
                  if (
                    item.title !== "Profile" &&
                    (!item.userType || type === item.userType)
                  ) {
                    return (
                      <Typography
                        key={item.title}
                        component={Link}
                        to={url(item.route, { type })}
                        className={clsx(classes.navLink, {
                          [classes.navLinkActive]: matchPath(
                            location.pathname,
                            item.route
                          ),
                        })}
                      >
                        {item.title}
                        {item?.count && (
                          <Badge
                            sx={{ marginLeft: "8px" }}
                            circle
                            label={notificationsCount.toString()}
                          />
                        )}
                      </Typography>
                    );
                  }
                  return null;
                })}
                <Typography
                  component={Link}
                  to={url(ROUTE_PROFILE, { type })}
                  className={clsx(classes.navLink, {
                    [classes.navLinkActive]: matchPath(
                      location.pathname,
                      ROUTE_PROFILE
                    ),
                  })}
                >
                  <Space spacing={1.5} alignItems="center">
                    {data?.avatar ? (
                      <Avatar
                        alt="Remy Sharp"
                        sx={{ width: 24, height: 24 }}
                        src={data.avatar.link}
                      />
                    ) : (
                      <div className={classes.avatarWrapper}>{initials}</div>
                    )}
                    <span>{`${data?.firstName} ${data?.lastName}`}</span>
                  </Space>
                </Typography>
              </Stack>
            ) : (
              <Box>
                <span onClick={handleClick} className={classes.iconWrapper}>
                  <IconBurger className={classes.burger} />
                </span>
                <MenuView
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  dataSource={navigationElements}
                  renderItem={(item) => <MenuItem item={item} key={item.id} />}
                />
              </Box>
            )}
          </Box>
        )}
      </Stack>
    </Box>
  );
};
