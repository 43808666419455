import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "badge",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: "inline-flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      fontSize: 12,
      textTransform: "uppercase",
      padding: theme.spacing(0.55, 1.1),
      backgroundColor: "#CEF9FF",
      borderRadius: 8,
    },
    circle: {
      borderRadius: 40,
    },
    primary: {
      backgroundColor: "#CEF9FF",
    },
    secondary: {
      backgroundColor: "#F6F6FC",
    },
    warning: {
      backgroundColor: "#FBEBC3",
    },
    error: {
      backgroundColor: "#FEEFEF",
    },
    info: {
      backgroundColor: "#CEF9FF",
    },
    success: {
      backgroundColor: "#C3FBDA",
    },
  }),
  options
);

export default useStyles;
