import { ComponentType, useEffect, useRef } from "react";
import { isEmpty } from "lodash";
import { Paper, Typography, Box, useMediaQuery, Theme } from "@mui/material";
import { Message } from "./message/Message";
import { Input } from "./input/Input";
import { IconMessages } from "../icons";

import { Space } from "../space";
import useStyles from "./styles";
import { useGetCommentsQuery } from "../../http/api";

interface IProps {
  receiptGuid?: string;
  status?: string;
  amount?: number;
  approvedAmount?: number;
}

const statuses = ["approved", "declined", "approved_partially"];

export const Comments: ComponentType<IProps> = ({
  receiptGuid,
  status,
  amount,
  approvedAmount,
}) => {
  const commentsRef = useRef(null);
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const statusMessage = {
    text: status,
    createdAt: "2021-09-09",
    amount,
    approvedAmount,
    owner: {
      id: 1,
      firstName: "John",
      lastName: "Doe",
      userName: "johndoe",
      createdAt: "2021-09-09",
      lastLogin: "2021-09-09",
    },
  };

  const { data } = useGetCommentsQuery({ receiptGuid });

  useEffect(() => {
    commentsRef.current?.scrollTo({
      behavior: "smooth",
      top: commentsRef.current.scrollHeight,
    });
  }, [data]);

  return (
    <Box className={classes.root} component={Paper}>
      <Box className={classes.block} px={isMobile ? 2 : 4.35}>
        <Box py={3}>
          <Typography variant="h3">Comments</Typography>
        </Box>
        <Box className={classes.messages} ref={commentsRef}>
          {!isEmpty(data?.items) ? (
            <>
              {data?.items.map((message: any) => (
                <Message key={message.id} item={message} />
              ))}
              {statuses.includes(status) && (
                <Message item={statusMessage} statuses={statuses} statusView />
              )}
            </>
          ) : statuses.includes(status) ? (
            <Message item={statusMessage} statuses={statuses} statusView />
          ) : (
            <Space spacing={1}>
              <>
                <IconMessages sx={{ fontSize: 20, color: "common.grey" }} />
                <Typography variant="body2" color="common.grey" component="div">
                  Comments on the receipt will be displayed here
                </Typography>
              </>
            </Space>
          )}
        </Box>
      </Box>
      <Input receiptGuid={receiptGuid} />
    </Box>
  );
};
