import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Theme,
  useMediaQuery,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { LinkButton } from "../../components/buttons/LinkButton";
import { PageHeader } from "../../components/page-header";
import { IconEmptyData, IconPlus } from "../../components/icons";
import { TableView } from "../../components/table-view";
import { employeesColumn } from "./mockdata";
import { AdaptiveButton } from "../../components/adaptive-button";
import { SearchInput } from "../../components/fields";
import { url } from "../../core/utils/route.utils";
import {
  ROUTE_EMPLOYEE_DETAILS,
  ROUTE_EMPLOYEE_CREATE,
} from "../../constants/route.constants";
import { useType } from "../../core/hooks/useType";
import { useGetEmployeesQuery } from "../../http/api";
import { get, map } from "lodash";
import { SortingState } from "../../components/table-view/useTableSorting";
import { EmptyState } from "../../components/empty-state";

export const EmployeesList = () => {
  const [page, setPage] = useState(1);
  const [sortingState, setSortingState] = useState<SortingState>({});
  const navigate = useNavigate();
  const { type } = useType();

  const formattedSortingState = Object.keys(sortingState).reduce(
    (acc, item) => {
      acc[`sort[${item}]`] = sortingState[item];
      return acc;
    },
    {}
  );
  const { data, isFetching } = useGetEmployeesQuery({
    page,
    ...formattedSortingState,
  });
  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const employeesData = map(get(data, "items", []), (item) => ({
    id: item.id,
    firstName: item.firstName,
    lastName: item.lastName,
    email: item.email,
    receiptsAmount: item.receiptsAmount,
    receiptsCount: item.receiptsCount,
    receiptsApprovedCount: item.receiptsApprovedCount,
    receiptsApprovedAmount: item.receiptsApprovedAmount,
  }));

  return (
    <>
      <PageHeader title="Employees" buttonsOrder={isMobile ? "column" : "row"}>
        <AdaptiveButton
          sx={{
            marginTop: { xs: 2, lg: 0, md: 0 },
          }}
          fullWidth={isMobile}
          component={Link}
          to={url(ROUTE_EMPLOYEE_CREATE, { type })}
          startIcon={<IconPlus />}
        >
          Add new employee
        </AdaptiveButton>
      </PageHeader>

      <Container>
        <Grid container sx={{ flexDirection: { md: "column", lg: "row" } }}>
          <Grid
            item
            xs={12}
            lg={4}
            md={5}
            sx={{
              order: { md: 1, lg: 2 },
              marginBottom: { md: 2, lg: 4, xs: 2 },
              marginTop: { md: 2, lg: 3, xs: 2 },
              marginRight: { md: 0, lg: "auto" },
            }}
          >
            <SearchInput />
          </Grid>
        </Grid>
        {employeesData.length || isFetching ? (
          <Box>
            {!isMobile ? (
              <TableView
                isLoading={isFetching}
                pagination={{
                  page: data?.currentPage,
                  perPage: data?.recordsPerPage,
                  totalPages: data?.totalPages,
                  total: 8,
                }}
                hiddenHeader={isMobile}
                onRowClick={(row) => {
                  navigate(
                    url(ROUTE_EMPLOYEE_DETAILS, {
                      type,
                      id: row.id,
                    })
                  );
                }}
                hideColumns={isTablet ? ["time", "description"] : []}
                dataSource={employeesData}
                columns={employeesColumn}
                onPageChange={(page) => setPage(page)}
                handleSort={(sortableField) => setSortingState(sortableField)}
              />
            ) : (
              <List>
                {employeesData.map(
                  ({
                    firstName,
                    lastName,
                    email,
                    receiptsCount,
                    receiptsApprovedCount,
                    id,
                  }) => {
                    const routeID = id.toString();
                    return (
                      <ListItem
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <LinkButton
                          sx={{ fontWeight: 700, fontSize: 18 }}
                          color={"common.black"}
                          underline={firstName ? "hover" : "always"}
                          to={url(ROUTE_EMPLOYEE_DETAILS, {
                            type,
                            id: routeID,
                          })}
                        >
                          {`${firstName} ${lastName}`}
                        </LinkButton>
                        <Box>
                          <Typography
                            mr={1}
                            component={"span"}
                            variant="body2"
                            color="common.grey"
                          >
                            Email:
                          </Typography>
                          {email}
                        </Box>
                        <Box>
                          <Typography
                            mr={1}
                            component={"span"}
                            mb={3}
                            variant="body2"
                            color="common.grey"
                          >
                            All receipts:
                          </Typography>
                          {receiptsCount}
                        </Box>
                        <Box>
                          <Typography
                            mr={1}
                            component={"span"}
                            mb={3}
                            variant="body2"
                            color="common.grey"
                          >
                            Approved:
                          </Typography>
                          {receiptsApprovedCount}
                        </Box>
                      </ListItem>
                    );
                  }
                )}
              </List>
            )}
          </Box>
        ) : (
          <EmptyState
            icon={<IconEmptyData />}
            description="You have no
                employees"
          />
        )}
      </Container>
    </>
  );
};
