import * as yup from "yup";
import { useFormik } from "formik";
import get from "lodash/get";
import { v4 as uuidv4 } from "uuid";

import { useGetTemplateQuery, useUpdateTemplateMutation } from "../../http/api";
import { format } from "date-fns";
import { useEffect } from "react";

interface UseProps {
  id: string;
  guid: string;
  userId: number;
  onSuccess: () => void;
  onError: (message: string) => void;
}

export const useEditTemplateForm = ({
  id,
  guid,
  userId,
  onSuccess,
  onError,
}: UseProps) => {
  const { data, refetch } = useGetTemplateQuery({
    userId,
    id,
  });

  useEffect(() => {
    setValues({
      ...data,
      userId: userId,
      guid: data?.guid,
      oldGuid: data?.guid,
      type: data?.typeId,
      amount: data?.amount,
    });
  }, [data]);

  useEffect(() => {
    refetch();
  }, [id]);

  const [updateReceipt] = useUpdateTemplateMutation({});
  const {
    setValues,
    handleSubmit,
    getFieldProps: formikGetFieldProps,
    setFieldValue,
    errors,
    values,
    touched,
  } = useFormik({
    initialValues: {
      name: "",
      userId: userId,
      id: 0,
      guid: guid ? guid : uuidv4(),
      type: 1,
      description: "",
      spentAt: new Date(),
      amount: 0,
      vendorGuid: "",
      files: [],
      employeeId: 0,
    },
    validationSchema: yup.object().shape({
      name: yup.string(),
      userId: yup.number(),
      id: yup.string(),
      guid: yup.string(),
      type: yup.number(),
      description: yup.string(),
      spentAt: yup.string(),
      amount: yup.number().min(1),
      vendorGuid: yup.string(),
      files: yup.array(),
      employeeId: yup.number(),
    }),
    onSubmit: async (values) => {
      const formattedValues = {
        name: values.name,
        oldGuid: values.guid,
        guid: values.guid,
        vendorGuid: values.vendorGuid,
        description: values.description,
        amount: Number(values.amount),
        type: Number(values.type),
        employeeId: values.employeeId,
        spentAt: format(new Date(values.spentAt), "yyyy-MM-dd HH:mm:ss"),
        files: [],
      };
      const res = await updateReceipt(formattedValues);
      const error = get(res, "error", null);
      if (error) {
        const message = get(error, "data.title", "Something went wrong.");
        onError(message);
      } else {
        onSuccess();
      }
    },
  });

  const getFieldProps = (fieldName) => {
    const error = errors[fieldName] && touched[fieldName];
    return {
      error,
      helperText: error ? errors[fieldName] : "",
      ...formikGetFieldProps(fieldName),
      onChange: (e) => {
        const targetValue = get(e, "target.value");
        if (targetValue || targetValue === "") {
          setFieldValue(fieldName, targetValue);
        } else {
          setFieldValue(fieldName, e);
        }
      },
    };
  };

  return {
    handleSubmit,
    errors,
    values,
    getFieldProps,
    setFieldValue,
    data,
  };
};
