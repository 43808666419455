import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../core/store/rtk.utils";
import {
  GET_TEMPLATES,
  GET_TEMPLATE,
  CREATE_TEMPLATE,
  UPDATE_TEMPLATE,
  DELETE_TEMPLATE,
} from "./api.routes";
import { IGetListResponse } from "../../types";
import { url } from "../../core/utils/route.utils";

interface ITemplate {
  amount: number;
  countComments: number;
  description: string;
  guid: string;
  id: number;
  spentAt: string;
  type: string | number | null;
  vendor_name: string;
}

interface ITemplates extends IGetListResponse {
  items: Array<ITemplate>;
}
interface ICreateTemplateRequest {
  guid: string;
  type: string;
  description: string;
  spentAt: string;
  amount: number;
  vendorGuid: string;
  files: Array<any>;
}

interface IUpdateTemplateRequest {
  userId: number;
  guid: string;
  description: string;
  vendorGuid: string;
  type: number;
  status: string;
  spentAt: string;
  amount: number;
  files: Array<string>;
}

export const templateApi = createApi({
  reducerPath: "templateApi",
  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getTemplates: builder.query<ITemplates, any>({
      query: (params) => ({
        url: url(GET_TEMPLATES, { userId: params.userId }),
        method: "GET",
        params: {
          page: params.page || 1,
          search: params.search || "",
          ...params.formattedSortingState,
        },
      }),
    }),
    getTemplate: builder.query<any, any>({
      query: (params) => ({
        url: url(GET_TEMPLATE, { userId: params.userId, id: params.id }),
        method: "GET",
      }),
    }),
    createTemplate: builder.mutation<ICreateTemplateRequest, any>({
      query: (params) => ({
        url: CREATE_TEMPLATE,
        method: "POST",
        body: params,
      }),
    }),
    updateTemplate: builder.mutation<IUpdateTemplateRequest, any>({
      query: (params) => ({
        url: url(UPDATE_TEMPLATE, { id: params.guid }),
        method: "PUT",
        body: params,
      }),
    }),
    deleteTemplate: builder.mutation<Record<string, never>, any>({
      query: (params) => ({
        url: url(DELETE_TEMPLATE, { id: params.guid }),
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetTemplatesQuery,
  useGetTemplateQuery,
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
  useDeleteTemplateMutation,
} = templateApi;
