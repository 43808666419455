import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Stack, Theme, useMediaQuery } from "@mui/material";
import { SearchInput } from "../../components/fields";
import {
  IconEmptyData,
  IconExport,
  IconFolder,
  IconMinus,
  IconPerson,
  IconPlus,
  IconSend,
  IconTrash,
} from "../../components/icons";
import { TableView } from "../../components/table-view";
import { LabeledRecord } from "../../components/labeled-record";
import { Badge } from "../../components/badge";
import { url } from "../../core/utils/route.utils";
import { ROUTE_SPENDS_EDIT } from "../../constants/route.constants";
import {
  spendsColumn,
  spendsColumnMobile,
} from "../../core/columns/receipts.columns";
import { Drawer } from "../../components/drawer";
import { AdaptiveButton } from "../../components/adaptive-button";
import { useDialog } from "../../core/hooks/useDialog";
import { ConfirmDialog } from "../../components/dialogs/ConfirmDialog";
import { ApproveSingleDialog } from "./ApproveSingleDialog";
import {
  useApproveReceiptMutation,
  useDeclineReceiptMutation,
  useGetMeQuery,
  useGetReceiptsQuery,
  useSendReceiptsOnApprovalMutation,
  useMarkReceiptAsPersonalMutation,
  useDeleteReceiptMutation,
  useArchiveReceiptMutation,
} from "../../http/api";
import { get } from "lodash";
import { useType } from "../../core/hooks/useType";
import { useSnackbar } from "../../core/hooks/useSnackbar";
import { EmptyState } from "../../components/empty-state";
import useDebounce from "../../core/hooks/useDebounce";
import { ExportActionDialog } from "./ExportActionDialog";
import { SortingState } from "../../components/table-view/useTableSorting";
import clsx from "clsx";

export const SpendsNewReceipts = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [sortingState, setSortingState] = useState<SortingState>({});
  const { data: userData } = useGetMeQuery({});
  const [search, setSearch] = useState("");
  const debouncedValue = useDebounce(search, 600);
  const { type } = useType();

  const formattedSortingState = Object.keys(sortingState).reduce(
    (acc, item) => {
      acc[`sort[${item}]`] = sortingState[item];
      return acc;
    },
    {}
  );
  const { data, error, refetch, isFetching } = useGetReceiptsQuery({
    page,
    userId: userData?.id || 0,
    status: type === "employer" ? ["new", "on_approval"] : ["new"],
    search: debouncedValue,
    formattedSortingState,
  });

  const [selectedIds, setSelectedIds] = React.useState<number[]>([]);
  const [openActions, setOpenActions] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const isIdsSelected = selectedIds.length > 0;
  const [openConfirmDialog, popConfirmDialog] = useDialog(ConfirmDialog);
  const [openApproveSingleDialog, popApproveSingleDialog] =
    useDialog(ApproveSingleDialog);
  const { showMessage } = useSnackbar();
  const [approveReceipt] = useApproveReceiptMutation({});
  const [declineReceipt] = useDeclineReceiptMutation({});
  const [sendReceiptsOnApproval] = useSendReceiptsOnApprovalMutation({});
  const [markReceiptAsPersonal] = useMarkReceiptAsPersonalMutation({});
  const [deleteReceipt] = useDeleteReceiptMutation({});
  const [archiveReceipt] = useArchiveReceiptMutation({});
  const [openExportDialog] = useDialog(ExportActionDialog);
  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const receiptsList = get(data, "items", []);

  useEffect(() => {
    if (error) {
      const message = get(error, "data.title", "Something went wrong");
      showMessage(message, "error");
    }
  }, [error]);

  const handleSetSelectedIds = (ids: number[]) => {
    setSelectedIds(ids);
  };

  const resetSelectedRows = () => {
    setLoading(true);
    setTimeout(() => {
      setSelectedIds([]);
      setLoading(false);
    });
  };

  const rowSelection = {
    selectedRowKeys: selectedIds.map((item) => item.toString()),
    onChange: handleSetSelectedIds,
  };

  const handleSendReceiptsOnApproval = () =>
    openConfirmDialog({
      message: "Are you sure you want to send selected receipts?",
      onConfirm: async () => {
        const res = await sendReceiptsOnApproval({
          ids: selectedIds,
        });
        const error = get(res, "error", null);
        if (error) {
          const message = get(error, "data.title", "Something went wrong.");
          showMessage(message, "error");
        } else {
          showMessage("Receipts was sent on approval successfully", "success");
          refetch();
          popConfirmDialog();
          resetSelectedRows();
        }
      },
    });
  const handleMarkReceiptAsPersonal = () => {
    openConfirmDialog({
      message: "Are you sure you want to mark selected receipts as personal?",
      onConfirm: async () => {
        const res = await markReceiptAsPersonal({
          ids: selectedIds,
        });
        const error = get(res, "error", null);
        if (error) {
          const message = get(error, "data.title", "Something went wrong.");
          showMessage(message, "error");
        } else {
          showMessage(
            "Receipts was marked as personal successfully",
            "success"
          );
          popConfirmDialog();
          resetSelectedRows();
          refetch();
        }
      },
    });
  };
  const handleDeleteReceipt = () => {
    openConfirmDialog({
      message: "Are you sure you want to remove selected receipts?",
      onConfirm: async () => {
        const res = await deleteReceipt({
          ids: selectedIds,
        });
        const error = get(res, "error", null);
        if (error) {
          const message = get(error, "data.title", "Something went wrong.");
          showMessage(message, "error");
        } else {
          showMessage("Receipts was removed successfully", "success");
          popConfirmDialog();
          resetSelectedRows();
          refetch();
        }
      },
    });
  };
  const handleArchiveReceipt = () => {
    openConfirmDialog({
      message: "Are you sure you want to archive selected receipts?",
      onConfirm: async () => {
        const res = await archiveReceipt({
          ids: selectedIds,
        });
        const error = get(res, "error", null);
        if (error) {
          const message = get(error, "data.title", "Something went wrong.");
          showMessage(message, "error");
        } else {
          showMessage("Receipts was archived successfully", "success");
          popConfirmDialog();
          resetSelectedRows();
          refetch();
        }
      },
    });
  };
  const handleApproveReceipt = () => {
    if (selectedIds.length > 1) {
      openConfirmDialog({
        message: "Are you sure you want to approve selected receipts?",
        onConfirm: async () => {
          const res = await approveReceipt({
            ids: selectedIds,
          });
          const error = get(res, "error", null);
          if (error) {
            const message = get(error, "data.title", "Something went wrong.");
            showMessage(message, "error");
          } else {
            showMessage("Receipts approved successfully", "success");
            popConfirmDialog();
            resetSelectedRows();
            refetch();
          }
        },
      });
    } else {
      openApproveSingleDialog({
        id: selectedIds[0],
        amount: receiptsList.find((item) => item.id === selectedIds[0])?.amount,
        onSuccess: () => {
          showMessage("Receipt approved successfully", "success");
          refetch();
          resetSelectedRows();
          popApproveSingleDialog();
        },
        onError: (message) => {
          showMessage(message, "error");
          resetSelectedRows();
        },
      });
    }
  };
  const handleDeclineReceipt = () => {
    openConfirmDialog({
      message: "Are you sure you want to decline selected receipts?",
      onConfirm: async () => {
        const res = await declineReceipt({
          ids: selectedIds,
        });

        const error = get(res, "error", null);
        if (error) {
          const message = get(error, "data.title", "Something went wrong.");
          showMessage(message, "error");
        } else {
          showMessage("Receipts declined successfully", "success");
          refetch();
          resetSelectedRows();
          popConfirmDialog();
        }
      },
    });
  };

  return (
    <>
      <Grid container sx={{ flexDirection: { md: "column", lg: "row" } }}>
        <Grid
          item
          xs={12}
          lg={2.5}
          sx={{
            order: { md: 1, lg: 2 },
            marginBottom: { md: 2, lg: 0 },
            marginLeft: { md: 0, lg: "auto" },
          }}
        >
          <SearchInput onChange={(event) => setSearch(event.target.value)} />
        </Grid>
        <Grid
          item
          lg={8}
          md={12}
          sx={{
            order: { md: 2, lg: 1 },
            display: { xs: "none", md: "block" },
          }}
        >
          {type === "employee"
            ? !!receiptsList.length && (
                <Stack spacing={1} direction="row" alignItems="center">
                  <Button
                    startIcon={<IconSend />}
                    color="success"
                    fullWidth={isTablet}
                    disabled={!isIdsSelected}
                    onClick={handleSendReceiptsOnApproval}
                  >
                    Send receipts
                  </Button>
                  <Button
                    color="info"
                    startIcon={<IconPerson />}
                    fullWidth={isTablet}
                    disabled={!isIdsSelected}
                    onClick={handleMarkReceiptAsPersonal}
                  >
                    Mark as personal
                  </Button>
                  <Button
                    color="secondary"
                    startIcon={<IconTrash />}
                    fullWidth={isTablet}
                    disabled={!isIdsSelected}
                    onClick={handleDeleteReceipt}
                  >
                    Remove
                  </Button>
                  <Button
                    color="secondary"
                    startIcon={<IconFolder />}
                    fullWidth={isTablet}
                    disabled={!isIdsSelected}
                    onClick={handleArchiveReceipt}
                  >
                    Archive
                  </Button>
                </Stack>
              )
            : !!receiptsList.length && (
                <Stack spacing={1} direction="row" alignItems="center">
                  <Button
                    startIcon={<IconPlus />}
                    color="primary"
                    fullWidth={isTablet}
                    disabled={!isIdsSelected}
                    onClick={handleApproveReceipt}
                  >
                    Approve
                  </Button>
                  <Button
                    color="error"
                    startIcon={<IconMinus />}
                    fullWidth={isTablet}
                    disabled={!isIdsSelected}
                    onClick={handleDeclineReceipt}
                  >
                    Decline
                  </Button>
                  <Button
                    color="secondary"
                    startIcon={<IconExport />}
                    fullWidth={isTablet}
                    onClick={openExportDialog}
                  >
                    Export .CSV
                  </Button>
                </Stack>
              )}
        </Grid>
      </Grid>

      <Box mt={2}>
        {!isMobile ? (
          (receiptsList.length && !loading) || isFetching ? (
            <TableView
              isLoading={isFetching}
              pagination={{
                page: data?.currentPage,
                perPage: data?.recordsPerPage,
                totalPages: data?.totalPages,
                total: 8,
              }}
              hideColumns={isTablet ? ["description"] : []}
              expandable={
                isTablet && {
                  rowExpandable: () => true,
                  expandedRowRender: (record) => (
                    <>
                      <LabeledRecord label="Time:" value={record.time} />
                      <LabeledRecord
                        label="Type:"
                        value={
                          <Badge label={record.type} variant={"primary"} />
                        }
                      />
                      <LabeledRecord
                        label="Description:"
                        value={record.description}
                      />
                    </>
                  ),
                }
              }
              rowSelection={rowSelection}
              dataSource={receiptsList}
              onAnyCellClick={(record) =>
                navigate(
                  url(ROUTE_SPENDS_EDIT, {
                    type,
                    id: record.id,
                  })
                )
              }
              columns={spendsColumn}
              onPageChange={(page) => setPage(page)}
              handleSort={(sortableField) => setSortingState(sortableField)}
            />
          ) : (
            <EmptyState
              icon={<IconEmptyData />}
              description="You have no
            new receipts"
            />
          )
        ) : (
          <>
            <Drawer isOpen={!!selectedIds.length}>
              <AdaptiveButton onClick={() => setOpenActions(true)}>
                Action
              </AdaptiveButton>
            </Drawer>

            <Drawer isOpen={openActions}>
              <Stack spacing={1} alignItems="center">
                <Button
                  startIcon={<IconSend />}
                  color="success"
                  fullWidth={isTablet}
                  disabled={!isIdsSelected}
                  onClick={handleSendReceiptsOnApproval}
                >
                  Send receipts
                </Button>
                <Button
                  color="info"
                  startIcon={<IconPerson />}
                  fullWidth={isTablet}
                  disabled={!isIdsSelected}
                  onClick={handleMarkReceiptAsPersonal}
                >
                  Mark as personal
                </Button>
                <Button
                  color="secondary"
                  startIcon={<IconTrash />}
                  fullWidth={isTablet}
                  disabled={!isIdsSelected}
                  onClick={handleDeleteReceipt}
                >
                  Remove
                </Button>
                <Button
                  color="secondary"
                  startIcon={<IconFolder />}
                  fullWidth={isTablet}
                  disabled={!isIdsSelected}
                  onClick={handleArchiveReceipt}
                >
                  Archive
                </Button>
                <Button onClick={() => setOpenActions(false)} color="third">
                  Cancel
                </Button>
              </Stack>
            </Drawer>

            <TableView
              pagination={{
                page: data?.currentPage,
                perPage: data?.recordsPerPage,
                totalPages: data?.totalPages,
                total: 8,
              }}
              expandable={{
                rowExpandable: () => true,
                expandedRowRender: (record) => (
                  <>
                    <LabeledRecord label="Id:" value={record.id} />
                    <LabeledRecord
                      label="Type:"
                      value={<Badge label={record.type} variant={"primary"} />}
                    />
                    <LabeledRecord label="Time:" value={record.time} />
                    <LabeledRecord
                      label="Description:"
                      value={record.description}
                    />
                  </>
                ),
              }}
              rowSelection={{
                selectedRowKeys: [],
                onChange: (ids) => setSelectedIds(ids),
              }}
              onAnyCellClick={(record) =>
                navigate(
                  url(ROUTE_SPENDS_EDIT, {
                    type,
                    id: record.id,
                  })
                )
              }
              dataSource={receiptsList}
              columns={spendsColumnMobile}
              onPageChange={(page) => setPage(page)}
              handleSort={(sortableField) => setSortingState(sortableField)}
            />
          </>
        )}
      </Box>
    </>
  );
};
