import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { get } from "lodash";
import { Box, Button, Grid, Stack, Theme, useMediaQuery } from "@mui/material";

import { SearchInput } from "../../components/fields";
import { IconEmptyData, IconFolder, IconTrash } from "../../components/icons";
import { TableView } from "../../components/table-view";
import { LabeledRecord } from "../../components/labeled-record";
import { Badge } from "../../components/badge";
import { url } from "../../core/utils/route.utils";
import { ROUTE_SPENDS_EDIT } from "../../constants/route.constants";
import { useDialog } from "../../core/hooks/useDialog";
import { ConfirmDialog } from "../../components/dialogs/ConfirmDialog";
import {
  spendsOnApprovalColumns,
  spendsOnApprovalMobileColumns,
} from "../../core/columns/receipts.columns";
import { useType } from "../../core/hooks/useType";
import {
  useArchiveReceiptMutation,
  useDeleteReceiptMutation,
  useGetMeQuery,
  useGetReceiptsQuery,
} from "../../http/api";
import { EmptyState } from "../../components/empty-state";
import useDebounce from "../../core/hooks/useDebounce";
import { Drawer } from "../../components/drawer";
import { AdaptiveButton } from "../../components/adaptive-button";
import { useSnackbar } from "../../core/hooks/useSnackbar";
import { SortingState } from "../../components/table-view/useTableSorting";

export const SpendsOnApproval = () => {
  const [page, setPage] = useState(1);
  const [sortingState, setSortingState] = useState<SortingState>({});
  const navigate = useNavigate();
  const { type } = useType();
  const { data: userData } = useGetMeQuery({});
  const [search, setSearch] = useState("");
  const debouncedValue = useDebounce(search, 600);
  const [openActions, setOpenActions] = React.useState<boolean>(false);

  const formattedSortingState = Object.keys(sortingState).reduce(
    (acc, item) => {
      acc[`sort[${item}]`] = sortingState[item];
      return acc;
    },
    {}
  );
  const { data, refetch, isFetching } = useGetReceiptsQuery({
    page,
    userId: userData?.id || 0,
    status: "on_approval",
    search: debouncedValue,
    formattedSortingState,
  });
  const [selectedIds, setSelectedIds] = React.useState<number[]>([]);
  const isIdsSelected = selectedIds.length > 0;
  const [deleteReceipt] = useDeleteReceiptMutation({});
  const [archiveReceipt] = useArchiveReceiptMutation({});
  const [openConfirmDialog, popConfirmDialog] = useDialog(ConfirmDialog);
  const { showMessage } = useSnackbar();

  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const spendsOnApprovalData = get(data, "items", []);
  const [loading, setLoading] = React.useState<boolean>(false);
  const handleSetSelectedIds = (ids: number[]) => {
    setSelectedIds(ids);
  };

  const resetSelectedRows = () => {
    setLoading(true);
    setTimeout(() => {
      setSelectedIds([]);
      setLoading(false);
    });
  };

  const rowSelection = {
    selectedRowKeys: selectedIds.map((item) => item.toString()),
    onChange: handleSetSelectedIds,
  };

  const handleDeleteReceipt = () => {
    openConfirmDialog({
      message: "Are you sure you want to remove selected receipts?",
      onConfirm: async () => {
        const res = await deleteReceipt({
          ids: selectedIds,
        });
        const error = get(res, "error", null);
        if (error) {
          const message = get(error, "data.title", "Something went wrong.");
          showMessage(message, "error");
        } else {
          showMessage("Receipts was removed successfully", "success");
          popConfirmDialog();
          resetSelectedRows();
          refetch();
        }
      },
    });
  };
  const handleArchiveReceipt = () => {
    openConfirmDialog({
      message: "Are you sure you want to archive selected receipts?",
      onConfirm: async () => {
        const res = await archiveReceipt({
          ids: selectedIds,
        });
        const error = get(res, "error", null);
        if (error) {
          const message = get(error, "data.title", "Something went wrong.");
          showMessage(message, "error");
        } else {
          showMessage("Receipts was archived successfully", "success");
          popConfirmDialog();
          resetSelectedRows();
          refetch();
        }
      },
    });
  };

  return (
    <>
      <Grid container sx={{ flexDirection: { md: "column", lg: "row" } }}>
        <Grid
          item
          xs={12}
          lg={2.5}
          sx={{
            order: { md: 1, lg: 2 },
            marginBottom: { md: 2, lg: 0 },
            marginLeft: { md: 0, lg: "auto" },
          }}
        >
          <SearchInput onChange={(event) => setSearch(event.target.value)} />
        </Grid>
        <Grid
          item
          lg={8}
          md={12}
          sx={{
            order: { md: 2, lg: 1 },
            display: { xs: "none", md: "block" },
          }}
        >
          {!isMobile
            ? !!spendsOnApprovalData.length && (
                <Stack spacing={1} direction="row" alignItems="center">
                  <Button
                    color="secondary"
                    startIcon={<IconTrash />}
                    fullWidth={isTablet}
                    disabled={!isIdsSelected}
                    onClick={handleDeleteReceipt}
                  >
                    Remove
                  </Button>
                  <Button
                    color="secondary"
                    startIcon={<IconFolder />}
                    fullWidth={isTablet}
                    disabled={!isIdsSelected}
                    onClick={handleArchiveReceipt}
                  >
                    Archive
                  </Button>
                </Stack>
              )
            : !!spendsOnApprovalData.length && (
                <Stack spacing={1} direction="row" alignItems="center">
                  dsa
                </Stack>
              )}
        </Grid>
      </Grid>

      <Box mt={2}>
        {!isMobile ? (
          (spendsOnApprovalData.length && !loading) || isFetching ? (
            <TableView
              isLoading={isFetching}
              hideColumns={
                isTablet ? ["time", "description", "employee"] : ["employee"]
              }
              expandable={
                isTablet && {
                  rowExpandable: () => true,
                  expandedRowRender: (record) => (
                    <>
                      <LabeledRecord label="Time:" value={record.time} />
                      <LabeledRecord
                        label="Type:"
                        value={
                          <Badge label={record.type} variant={"primary"} />
                        }
                      />
                      <LabeledRecord
                        label="Description:"
                        value={record.description}
                      />
                    </>
                  ),
                }
              }
              rowSelection={rowSelection}
              onAnyCellClick={(row) => {
                navigate(
                  url(ROUTE_SPENDS_EDIT, {
                    type,
                    id: row.id,
                  })
                );
              }}
              dataSource={spendsOnApprovalData}
              columns={spendsOnApprovalColumns}
              onPageChange={(page) => setPage(page)}
              handleSort={(sortableField) => setSortingState(sortableField)}
            />
          ) : (
            <EmptyState
              icon={<IconEmptyData />}
              description="You have no on approval
           receipts"
            />
          )
        ) : (
          <>
            <Drawer isOpen={!!selectedIds.length}>
              <AdaptiveButton onClick={() => setOpenActions(true)}>
                Action
              </AdaptiveButton>
            </Drawer>
            <Drawer isOpen={openActions}>
              <Stack spacing={1} alignItems="center">
                <Button
                  color="secondary"
                  startIcon={<IconTrash />}
                  fullWidth={isTablet}
                  disabled={!isIdsSelected}
                  onClick={handleDeleteReceipt}
                >
                  Remove
                </Button>
                <Button
                  color="secondary"
                  startIcon={<IconFolder />}
                  fullWidth={isTablet}
                  disabled={!isIdsSelected}
                  onClick={handleArchiveReceipt}
                >
                  Archive
                </Button>
                <Button onClick={() => setOpenActions(false)} color="third">
                  Cancel
                </Button>
              </Stack>
            </Drawer>

            <TableView
              pagination={{
                page: data?.currentPage,
                perPage: data?.recordsPerPage,
                totalPages: data?.totalPages,
                total: 8,
              }}
              expandable={{
                rowExpandable: () => true,
                expandedRowRender: (record) => (
                  <>
                    <LabeledRecord label="Id:" value={record.id} />
                    <LabeledRecord
                      label="Type:"
                      value={<Badge label={record.type} variant={"primary"} />}
                    />
                    <LabeledRecord label="Time:" value={record.time} />
                    <LabeledRecord
                      label="Description:"
                      value={record.description}
                    />
                  </>
                ),
              }}
              rowSelection={rowSelection}
              onAnyCellClick={(record) =>
                navigate(
                  url(ROUTE_SPENDS_EDIT, {
                    type,
                    id: record.id,
                  })
                )
              }
              dataSource={spendsOnApprovalData}
              columns={spendsOnApprovalMobileColumns}
              onPageChange={(page) => setPage(page)}
              handleSort={(sortableField) => setSortingState(sortableField)}
            />
          </>
        )}
      </Box>
    </>
  );
};
