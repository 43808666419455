import * as yup from "yup";
import { useFormik } from "formik";
import get from "lodash/get";

import { endOfDay, format, startOfDay, sub } from "date-fns";
import { EXPORT_RECEIPTS } from "../../http/api/api.routes";
import { ENDPOINT_ROOT } from "../../constants/app.constants";
import { rtkBaseHeaders } from "../store/rtk.utils";

interface UseProps {
  onSuccess: () => void;
  onError: (message: string) => void;
}

export const useExportForm = ({ onSuccess, onError }: UseProps) => {
  const {
    handleSubmit,
    getFieldProps: formikGetFieldProps,
    setFieldValue,
    errors,
    values,
    touched,
    validateForm,
    setErrors,
    setTouched,
  } = useFormik({
    initialValues: {
      dateFrom: sub(startOfDay(new Date()), { months: 1 }),
      dateTo: endOfDay(new Date()),
      memberIds: [],
      exportAllMembers: true,
      typeIds: [],
      exportAllTypes: true,
    },
    validationSchema: yup.object().shape({
      dateFrom: yup.string().required("Required field"),
      dateTo: yup.string().required("Required field"),
      memberIds: yup.array().required("Required field"),
      exportAllMembers: yup.boolean(),
      typeIds: yup.array().required("Required field"),
      exportAllTypes: yup.boolean(),
    }),
    onSubmit: async (values) => {
      const formattedValues = {
        ...values,
        dateFrom: format(new Date(values.dateFrom), "yyyy-MM-dd HH:mm:ss"),
        dateTo: format(new Date(values.dateTo), "yyyy-MM-dd HH:mm:ss"),
        typeIds: values.typeIds.map((item) => item.value),
        memberIds: values.memberIds.map((item) => item.value),
        exportAllMembers: !values.memberIds.length
          ? true
          : values.exportAllMembers,
      };

      try {
        const headers = new Headers();

        const newHeaders = rtkBaseHeaders(headers);

        const res = await fetch(`${ENDPOINT_ROOT}${EXPORT_RECEIPTS}`, {
          headers: newHeaders,
          method: "POST",
          body: JSON.stringify(formattedValues),
        });

        const text = await res.text();
        const blob = new Blob([text], { type: "text/plain" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `export-${format(
          new Date(values.dateFrom),
          "yyyy-MM-dd"
        )}-${format(new Date(values.dateTo), "yyyy-MM-dd")}.csv`;
        document.body.appendChild(link);
        link.click();
        onSuccess();
      } catch (e) {
        onError("Something went wrong");
      }
    },
  });

  const getFieldProps = (fieldName) => {
    const error = errors[fieldName] && touched[fieldName];
    return {
      error,
      helperText: error ? errors[fieldName] : "",
      ...formikGetFieldProps(fieldName),
      onChange: (e) => {
        const targetValue = get(e, "target.value");
        if (targetValue || targetValue === "") {
          setFieldValue(fieldName, targetValue);
        } else {
          setFieldValue(fieldName, e);
        }
      },
    };
  };

  return {
    handleSubmit,
    errors,
    values,
    getFieldProps,
    setFieldValue,
    validateForm,
    setErrors,
    setTouched,
  };
};
