import React, { ComponentType } from "react";
import { useNavigate } from "react-router-dom";

import {
  Container,
  Paper,
  Box,
  Typography,
  Grid,
  Button,
  Avatar,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { AdaptiveButton } from "../../components/adaptive-button";
import { PageHeader } from "../../components/page-header";
import { LabeledRecord } from "../../components/labeled-record";
import { useDialog } from "../../core/hooks/useDialog";
import { ChangeEmailDialog } from "./ChangeEmailDialog";
import { ConfirmDialog } from "../../components/dialogs/ConfirmDialog";
import { useEditUserMutation, useGetMeQuery, userApi } from "../../http/api";
import { AUTH_TOKEN } from "../../constants/app.constants";
import { ROUTE_SIGN_IN } from "../../constants/route.constants";
import { useDispatch } from "react-redux";
import { useType } from "../../core/hooks/useType";
import { url } from "../../core/utils/route.utils";
import { useSnackbar } from "../../core/hooks/useSnackbar";
import useStyles from "./styles";
import { generateInitials } from "../../core/utils/generateInitials";
import { get } from "lodash";
import { useUploadFileMutation } from "../../http/api/file.api";

export const Profile: ComponentType = () => {
  const navigate = useNavigate();
  const { type } = useType();
  const dispatch = useDispatch();
  const { data, refetch } = useGetMeQuery({});
  const { showMessage } = useSnackbar();
  const classes = useStyles();
  const [uploadFile] = useUploadFileMutation({});
  const [editUser] = useEditUserMutation({});

  const [openChangeEmailDialog] = useDialog(ChangeEmailDialog);
  const [openLogoutConfirmDialog, popLogoutConfirmDialog] =
    useDialog(ConfirmDialog);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const handleLogout = () => {
    localStorage.removeItem(AUTH_TOKEN);

    navigate(
      url(ROUTE_SIGN_IN, {
        type,
      })
    );

    dispatch(userApi.util.resetApiState());
    popLogoutConfirmDialog();
  };

  const handleEmailDialog = () =>
    openChangeEmailDialog({
      onSuccess: () => {
        if (type === "employer") {
          showMessage("Email changed successfully", "success");
          navigate(url(ROUTE_SIGN_IN, { type: "employer" }));
        } else {
          showMessage("Email was sent to approval successfully", "success");
        }
      },
      onError: (message) => {
        showMessage(message, "error");
      },
    });

  const initials = data ? generateInitials(data.firstName, data.lastName) : "";

  return (
    <>
      <PageHeader title="Profile">
        <AdaptiveButton
          color="third"
          onClick={() => {
            openLogoutConfirmDialog({
              message: "Do you really want to logout?",
              okText: "Logout",
              onConfirm: () => handleLogout(),
            });
          }}
        >
          Logout
        </AdaptiveButton>
      </PageHeader>
      <Container>
        <Box component={Paper} p={4} my={3}>
          <Typography
            component={Box}
            mb={3}
            variant="body1"
            color="common.grey"
          >
            You signed in with google account
          </Typography>

          <Grid container>
            <Grid item xs={3} md={6} lg={4} onClick={() => handleEmailDialog()}>
              <LabeledRecord label="Email:" value={data?.userName} />
            </Grid>
            {!isMobile && (
              <Grid item md={6} lg={2}>
                <Button
                  color="secondary"
                  onClick={() => {
                    handleEmailDialog();
                  }}
                >
                  Change email
                </Button>
              </Grid>
            )}
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <Box>
                <LabeledRecord
                  label="Profile photo:"
                  value={
                    <label className={classes.fileLabel} htmlFor="photo-input">
                      Select
                      <input
                        style={{ display: "none" }}
                        onChange={async (event) => {
                          const file = event.target.files?.[0];
                          if (!file) return;

                          const values: any = {
                            id: data.id,
                          };

                          if (file) {
                            const guid = uuidv4();
                            const formData = new FormData();
                            formData.append("documentFile", file);
                            formData.append("guid", guid);
                            await uploadFile(formData);
                            values.avatarGuid = guid;
                          }

                          const res = await editUser(values);
                          const error = get(res, "error", null);
                          if (error) {
                            const message = get(
                              error,
                              "data.title",
                              "Something went wrong."
                            );
                            showMessage(message, "error");
                          } else {
                            showMessage(
                              "Profile photo was changed successfully",
                              "success"
                            );
                            refetch();
                          }
                        }}
                        id={"photo-input"}
                        type="file"
                      />
                    </label>
                  }
                />

                {data?.avatar ? (
                  <Avatar
                    alt="Remy Sharp"
                    sx={{ width: 88, height: 88 }}
                    src={data.avatar?.link}
                  />
                ) : (
                  <div className={classes.avatarWrapper}>{initials}</div>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};
