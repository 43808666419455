import { ComponentType, ReactNode } from "react";
import { Stack, Typography, Container, Box, Button } from "@mui/material";
import { IconArrowLeft } from "../icons";
import useStyles from "./styles";

interface Props {
  title: string;
  backTitle?: string;
  buttonsOrder?: string;
  meta?: ReactNode;
}

export const PageHeader: ComponentType<Props> = ({
  title,
  backTitle,
  children,
  buttonsOrder = "row",
  meta,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Container sx={{ paddingTop: 3, paddingBottom: 3 }}>
        {backTitle && (
          <Button
            variant="text"
            className={classes.backButton}
            startIcon={<IconArrowLeft width={12} sx={{ ml: "-5px" }} />}
            onClick={() => {
              window.history.back();
            }}
          >
            {backTitle}
          </Button>
        )}
        <Stack
          direction={buttonsOrder === "row" ? "row" : "column"}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            direction={buttonsOrder === "row" ? "row" : "column"}
            alignItems="center"
          >
            <Typography variant="h1" className={classes.title}>
              {title}
            </Typography>
            {meta && (
              <Stack direction="row" spacing={1} className={classes.meta}>
                {meta}
              </Stack>
            )}
          </Stack>
          <Stack
            direction="row"
            spacing={1.25}
            alignItems="center"
            sx={{ mt: buttonsOrder === "column" ? 2 : 0 }}
          >
            {children}
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};
