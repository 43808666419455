export const ROUTE_HOME = "/";
export const ROUTE_SIGN_IN = "/:type/sign-in";
export const ROUTE_SIGN_UP = "/:type/sign-up";
export const ROUTE_AUTH_REDIRECT = "/auth/google";
export const ROUTE_SIGN_IN_RESULT = "/sign-in-result";

export const ROUTE_SPENDS_INDEX = "/:type/spends";
export const ROUTE_SPENDS_EDIT = "/:type/spends/:id/edit";
export const ROUTE_SPENDS_CREATE = "/:type/spends/create";
export const ROUTE_SPENDS_TEMPLATES_INDEX = "/:type/spends/templates";
export const ROUTE_SPENDS_TEMPLATE_CREATE = "/:type/spends/templates/create";
export const ROUTE_SPENDS_TEMPLATE_EDIT = "/:type/spends/templates/:id/edit";
export const ROUTE_SPENDS_COMMENTS = "/:type/spends/comments/";

export const ROUTE_EMPLOYEES_LIST = "/:type/employees";
export const ROUTE_EMPLOYEE_DETAILS = "/:type/employees/details/:id";
export const ROUTE_EMPLOYEE_DETAILS_TAB = "/:type/employees/details/:id/:tab";
export const ROUTE_EMPLOYEE_CREATE = "/:type/employees/employee/create";

export const ROUTE_PROFILE = "/:type/profile";
export const ROUTE_NOTIFICATIONS = "/:type/notifications";
export const ROUTE_MAIL_ACCESS = "/:type/mail-access";
export const ROUTE_FILL_COMPANY_INFO = "/:type/fill-company-info";
export const ROUTE_INVITE_EMPLOYEES = "/:type/invite-employees";
export const ROUTE_SUCCESS_INVITES = "/:type/success-invites";
