import React from "react";
import { Box } from "@mui/material";
import { Footer } from "../footer";
import useStyles from "./styles";

export const AuthLayoutWithBackground: React.ComponentType = ({ children }) => {
  const classes = useStyles();
  return (
    <>
      <Box component="main" className={classes.rootWithBackground}>
        {children}
      </Box>
      <Footer />
    </>
  );
};
