import { ComponentType } from "react";
import { Box, FormHelperText, InputLabel, Typography } from "@mui/material";
import { IconDownload } from "../../icons";
import useStyles from "./styles";

interface Props {
  label: string;
  hint?: string;
  setFile: (args: any) => void;
}

export const InputFile: ComponentType<Props> = ({
  label,
  hint = " ",
  setFile,
}) => {
  const classes = useStyles();
  const inputId = "input-file";

  const onDropHandler = (ev) => {
    ev.preventDefault();

    let file = "";
    if (ev.dataTransfer.items) {
      file = [...ev.dataTransfer.items]
        .find((item: any) => item.kind === "file")
        .getAsFile();
    } else {
      file = ev.dataTransfer.files[0];
    }

    setFile(file);
  };

  const onDragOver = (ev) => ev.preventDefault();

  return (
    <>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <label
        htmlFor={inputId}
        onDrop={onDropHandler}
        onDragOver={onDragOver}
        className={classes.root}
      >
        <Box className={classes.container}>
          <IconDownload className={classes.icon} />
          <Typography className={classes.text}>
            Choose a file or drop it here
          </Typography>
        </Box>

        <input
          style={{ display: "none" }}
          onChange={(event) =>
            event.target.files && setFile(event.target.files[0])
          }
          id={inputId}
          type="file"
        />
      </label>
      <FormHelperText>{hint}</FormHelperText>
    </>
  );
};
