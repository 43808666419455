import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Box, useMediaQuery, Theme } from "@mui/material";
import { PageHeader } from "../../components/page-header";
import { AdaptiveButton } from "../../components/adaptive-button";
import { ListView } from "../../components/list-view";
import { NotificationItem } from "./notification-item/NotificationItem";
import {
  useGetNotificationsQuery,
  useGetNotificationsCountQuery,
  useMarkNotificationAsReadMutation,
} from "../../http/api";
import { get, map } from "lodash";
import {
  ROUTE_SPENDS_EDIT,
  ROUTE_EMPLOYEE_DETAILS_TAB,
} from "../../constants/route.constants";
import { url } from "../../core/utils/route.utils";
import { useType } from "../../core/hooks/useType";
import { IconEmptyData } from "../../components/icons";
import { EmptyState } from "../../components/empty-state";

export const NotificationList = () => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const navigate = useNavigate();
  const { type } = useType();
  const [page, setPage] = useState(1);
  const { data } = useGetNotificationsQuery({ page });
  const [markNotificationAsRead] = useMarkNotificationAsReadMutation({});
  const { refetch } = useGetNotificationsCountQuery({});
  const notificationsList = map(get(data, "items", []), (item) => ({
    ...item,
    id: item.id,
    message: item.text,
    date: item.createdAt,
    read: item.readAt,
  }));

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <PageHeader
        title="Notifications"
        buttonsOrder={isMobile ? "column" : "row"}
      >
        <AdaptiveButton
          color="third"
          onClick={async () => {
            await markNotificationAsRead({
              ids: notificationsList.map((item) => item.id),
            });
            refetch();
          }}
          fullWidth={isMobile}
        >
          Mark all notifications as read
        </AdaptiveButton>
      </PageHeader>
      <Container>
        <Box py={1.5}>
          {notificationsList.length ? (
            <ListView
              dataSource={notificationsList}
              renderItem={(item) => (
                <NotificationItem
                  item={item}
                  onClick={async () => {
                    if (item.type === "new request" && item?.receiptDTO) {
                      await markNotificationAsRead({ ids: [item.id] });
                      refetch();
                      navigate(
                        url(ROUTE_SPENDS_EDIT, {
                          type,
                          id: item?.receiptDTO?.id,
                        })
                      );
                    } else if (
                      item.type === "change email request" &&
                      item?.employeeDTO
                    ) {
                      await markNotificationAsRead({ ids: [item.id] });
                      refetch();
                      navigate(
                        url(ROUTE_EMPLOYEE_DETAILS_TAB, {
                          type,
                          id: item?.employeeDTO?.id,
                          tab: "profile",
                        })
                      );
                    }
                  }}
                />
              )}
              totalPages={data?.totalPages}
              onPageChange={(page) => setPage(page)}
            />
          ) : (
            <EmptyState
              icon={<IconEmptyData />}
              description="You have no
                notifications"
            />
          )}
        </Box>
      </Container>
    </>
  );
};
