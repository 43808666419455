import { ReactComponent as LogoIcon } from "./assets/logo.svg";
import { SvgIcon } from "@mui/material";

export const AuthLogo = () => {
  return (
    <SvgIcon
      component={LogoIcon}
      viewBox="0 0 113 24"
      sx={{ width: 187, height: 40 }}
    />
  );
};
