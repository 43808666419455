import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "menu-view",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      width: "100%",
      [theme.breakpoints.down("md")]: {
        "& .MuiPaper-root": {
          padding: "24px",
          minWidth: "100%",
          minHeight: "180px",
          left: "0 !important",
          top: "57px !important",
          borderRadius: 0,
          border: "none",
          boxShadow: "0px 8px 16px rgba(24, 61, 98, 0.12)",
        },
        "& .MuiMenuItem-root": {
          justifyContent: "center !important",
        },
      },
    },
  }),
  options
);

export default useStyles;
