import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: "auto",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },

  [theme.breakpoints.up("md")]: {
    block: { width: "480px", maxWidth: "unset" },
  },
  [theme.breakpoints.down("md")]: {
    block: { maxWidth: "100%" },
  },

  block: {
    maxWidth: "480px",
    boxShadow: "0px 12px 24px rgba(0, 0, 0,0.06)",
    borderRadius: "8px",
    backgroundColor: theme.palette.common.white,
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(5),
  },
}));
