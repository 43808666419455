import { ComponentType } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { DialogProps } from "../../core/providers/dialog.provider";
import { useApproveSingleForm } from "../../core/hooks/useApproveSingleForm";

interface ApproveSingleDialogProps extends DialogProps {
  id: number;
  amount: number;
  onSuccess: () => void;
  onError: (message: string) => void;
}

export const ApproveSingleDialog: ComponentType<ApproveSingleDialogProps> = ({
  popDialog,
  id,
  amount,
  onSuccess,
  onError,
}) => {
  const { getFieldProps, handleSubmit } = useApproveSingleForm({
    id,
    amount,
    onSuccess: () => {
      onSuccess();
      popDialog();
    },
    onError: (message) => {
      onError(message);
    },
  });

  return (
    <Dialog open onClose={popDialog} fullWidth>
      <DialogTitle>
        Are you sure you want to approve selected receipt?
      </DialogTitle>
      <DialogContent>
        <TextField label="Amount" {...getFieldProps("amount")} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleSubmit()}>Send</Button>
        <Button color="secondary" onClick={popDialog}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
