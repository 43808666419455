export const spendsStatusBadge = {
  new: "success",
  approved: "success",
  approved_partially: "warning",
  declined: "error",
};

export const spendsStatusBadgeLabel = (status) => {
  switch (status) {
    case "approved_partially":
      return "Approved partially";
    case "on_approval":
      return "On approval";
    default:
      return status;
  }
};

export const spendsCommentsStatusBadgeLabel = (
  status,
  amount,
  approvedAmount
) => {
  switch (status) {
    case "approved_partially":
      return `accepted $${approvedAmount} from $${amount}`;
    case "on_approval":
      return `on approval`;
    default:
      return status;
  }
};
