import React, { useEffect, useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { TimePicker as TPicker } from "@mui/lab";
import { IconDownUp } from "../../icons";
import useStyles from "./styles";
import { add, startOfDay } from "date-fns";

export const TimePicker = ({ label = "Time:", onChange, value }) => {
  const [timeSuffix, setTimeSuffix] = useState<string>("PM");

  useEffect(() => {
    const hours = new Date(value).getHours();
    setTimeSuffix(hours >= 12 ? "PM" : "AM");
  }, [value]);

  const classes = useStyles();
  return (
    <Box className={classes.timePickerContainer}>
      <TPicker
        components={{
          OpenPickerIcon: () => (
            <Typography
              variant="body1"
              color="common.black"
              className={classes.timeSuffix}
            >
              {timeSuffix}
            </Typography>
          ),
        }}
        label={label}
        value={value}
        inputFormat="hh:mm"
        toolbarFormat="hh:mm"
        onChange={(newValue) => {
          const newDate = add(startOfDay(new Date(value)), {
            hours: new Date(newValue).getHours(),
            minutes: new Date(newValue).getMinutes(),
          });

          onChange(newDate);
        }}
        renderInput={(params) => {
          params.inputProps.placeholder = "00:00 ";
          return <TextField {...params} />;
        }}
      />
      <IconDownUp className={classes.downUp} />
    </Box>
  );
};
