import React, { ComponentType } from "react";
import { Box, Container, Stack, Typography } from "@mui/material";
import useStyles from "./styles";

export const Footer: ComponentType = () => {
  const year = new Date().getFullYear();

  const classes = useStyles();

  return (
    <Box component="footer" py={2} className={classes.root}>
      <Box component={Container}>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
          <Typography variant="body3" component="span" color="common.grey">
            © {year}, Rimshare
          </Typography>

          <Box className={classes.developedBy}>
            <Typography
                variant="body2"
                component="a"
                color="common.grey"
                href={"https://diffco.us/"}
                sx={{ textDecoration: "none" }}
            >
              Developed by <strong>Diffco</strong>
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
