import { Components } from "@mui/material";
import { themeBreakpoints } from "./breakpoints";
import { createTheme } from "@mui/material/styles";
import { typography } from "./typography";

export const themeComponents = (palette: any): Components => {
  const theme = createTheme();

  return {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          minHeight: "100vh",
          height: "100%",
        },
        body: {
          minHeight: "100vh",
          height: "100%",
          margin: 0,
        },
        "#root": {
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          width: "100%",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          [themeBreakpoints.up("sm")]: {
            paddingLeft: 16,
            paddingRight: 16,
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 10,
          "&:last-child": {
            paddingBottom: 10,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
        size: "large",
      },
      styleOverrides: {
        sizeLarge: {
          padding: theme.spacing(1.125, 1.875),

          "& .MuiSvgIcon-root": {
            fontSize: "20px",
          },
        },
        sizeSmall: {
          padding: theme.spacing(1, 1.5),
          fontSize: 14,
          lineHeight: 1.42,
          fontWeight: 400,
        },
        textPrimary: {
          color: palette.common.dark,
          "&:hover": {
            backgroundColor: "#F6F6FC",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: 3,
          backgroundColor: "#12C8C5",
          borderTopLeftRadius: 2,
          borderTopRightRadius: 2,
          padding: 0,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          fontFamily: "Gilroy, sans-serif",
          fontSize: 18,
          color: "#6C7294",
          padding: theme.spacing(1.75, 0),
          marginRight: theme.spacing(4),
          "&.Mui-selected": {
            color: "#131730",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          boxShadow: "none",
          border: "1px solid #E7EAF9",
          borderRadius: 8,

          "& .MuiCardContent-root": {
            padding: theme.spacing(4),
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: "#CACDE2",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "#F6F6FC",

          "& .MuiTableCell-root": {
            color: "#6C7294",
            borderBottom: "1px solid #E7EAF9",
            textTransform: "uppercase",
            fontWeight: 600,
            fontSize: 12,
          },
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1.72, 1.5),
          color: "#131730",
          borderColor: "transparent",
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#B8BDD9",
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "#E7EAF9",
        },
      },
    },

    MuiPagination: {
      styleOverrides: {
        root: {
          display: "inline-flex",
          padding: theme.spacing(0.5),
          backgroundColor: "#F6F6FC",
          borderRadius: 12,
        },
      },
    },

    MuiPaginationItem: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          margin: 0,
          marginRight: 2,
          color: "#6C7294",
          "&.Mui-selected, &:hover, &.Mui-selected:hover": {
            backgroundColor: "#fff",
            color: "#131730",
            boxShadow: "0px 1px 3px rgba(30, 42, 75, 0.08)",
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        helperText: " ",
        InputLabelProps: {
          shrink: true,
        },
        SelectProps: {
          native: true,
        },
      },
      styleOverrides: {
        root: {
          "& .MuiInputLabel-root": {
            position: "static",
            marginBottom: 4,
            transform: "none",
            width: "100%",
            maxWidth: "100%",
            color: "#6C7294",
            ...typography.body2,

            "&.Mui-focused": {
              color: "#6C7294",
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: false,
      },

      styleOverrides: {
        root: {
          backgroundColor: "#FBFBFF",

          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#12C8C5",
          },

          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: 1,
            borderColor: "#12C8C5",
            boxShadow: " 0px 0px 0px 3px rgba(18, 200, 197, 0.1)",
          },
        },
        input: {
          padding: "8px 12px",
          height: "1.5em",
          "&::placeholder": {
            color: "#6C7294",
          },
        },
        notchedOutline: {
          borderColor: "#E7EAF9",
          top: 0,
          "& > *": {
            display: "none !important",
          },
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiFormControl-root label +.MuiOutlinedInput-root .MuiInputBase-input::placeholder":
            {
              opacity: "1 !important",
              color: "black !important",
            },
          "& .MuiOutlinedInput-root": {
            padding: 0,
          },
        },
        input: {
          padding: 0,
        },
      },
    },

    MuiDialog: {
      defaultProps: {
        fullWidth: true,
        maxWidth: "sm",
      },
      styleOverrides: {
        paperWidthSm: {
          maxWidth: "420px",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          ...typography.h3,
          padding: theme.spacing(4, 4, 1.5, 4),
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          ...typography.body2,
          padding: theme.spacing(0, 4, 3, 4),
        },
      },
    },
    MuiDialogActions: {
      defaultProps: {},
      styleOverrides: {
        root: {
          justifyContent: "flex-start",
          borderTop: "1px solid #E7EAF9",
          margin: theme.spacing(0, 4, 4, 4),
          padding: theme.spacing(3, 0, 0, 0),
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          boxShadow: "0px 8px 24px rgba(24, 61, 98, 0.24)",
        },
      },
    },
  };
};
