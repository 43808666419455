import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Stack, Theme, useMediaQuery } from "@mui/material";
import { SearchInput } from "../../components/fields";
import {
  IconEmptyData,
  IconExport,
  IconFolder,
  IconPerson,
  IconSend,
  IconTrash,
} from "../../components/icons";
import { TableView } from "../../components/table-view";
import { LabeledRecord } from "../../components/labeled-record";
import { Badge } from "../../components/badge";
import { url } from "../../core/utils/route.utils";
import { ROUTE_SPENDS_EDIT } from "../../constants/route.constants";
import {
  spendsHistoryColumns,
  spendsApprovedMobileColumns,
} from "../../core/columns/receipts.columns";
import { Drawer } from "../../components/drawer";
import { AdaptiveButton } from "../../components/adaptive-button";
import { useDialog } from "../../core/hooks/useDialog";
import { ConfirmDialog } from "../../components/dialogs/ConfirmDialog";
import { useGetMeQuery, useGetReceiptsQuery } from "../../http/api";
import { get } from "lodash";
import { useType } from "../../core/hooks/useType";
import { useSnackbar } from "../../core/hooks/useSnackbar";
import { EmptyState } from "../../components/empty-state";
import useDebounce from "../../core/hooks/useDebounce";
import { ExportActionDialog } from "./ExportActionDialog";
import { SortingState } from "../../components/table-view/useTableSorting";

export const SpendsHistory = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sortingState, setSortingState] = useState<SortingState>({});
  const debouncedValue = useDebounce(search, 600);
  const { data: userData } = useGetMeQuery({});

  const formattedSortingState = Object.keys(sortingState).reduce(
    (acc, item) => {
      acc[`sort[${item}]`] = sortingState[item];
      return acc;
    },
    {}
  );
  const { data, error, isFetching } = useGetReceiptsQuery({
    page,
    userId: userData?.id || 0,
    status: [
      "approved",
      "approved_partially",
      "declined",
      "personal",
      "archived",
    ],
    search: debouncedValue,
    formattedSortingState,
  });
  const navigate = useNavigate();
  const { type } = useType();
  const [selectedIds, setSelectedIds] = React.useState<number[]>([]);
  const [openActions, setOpenActions] = React.useState<boolean>(false);
  const isIdsSelected = selectedIds.length > 0;
  const [openConfirmDialog] = useDialog(ConfirmDialog);
  const [openExportDialog] = useDialog(ExportActionDialog);

  const { showMessage } = useSnackbar();
  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const receiptsList = get(data, "items", []);

  useEffect(() => {
    if (error) {
      const message = get(error, "data.title", "Something went wrong");
      showMessage(message, "error");
    }
  }, [error]);

  return (
    <>
      <Grid container sx={{ flexDirection: { md: "column", lg: "row" } }}>
        <Grid
          item
          xs={12}
          lg={2.5}
          sx={{
            order: { md: 1, lg: type === "employer" ? 2 : 0 },
            marginBottom: { md: 2, lg: 0 },
            marginLeft: { md: 0, lg: type === "employer" ? "auto" : 0 },
          }}
        >
          <SearchInput onChange={(event) => setSearch(event.target.value)} />
        </Grid>
        <Grid
          item
          lg={8}
          md={12}
          sx={{
            order: { md: 2, lg: 1 },
            display: { xs: "none", md: "block" },
          }}
        >
          {type === "employer" && !!receiptsList.length && (
            <Stack spacing={1} direction="row" alignItems="center">
              <Button
                color="secondary"
                startIcon={<IconExport />}
                fullWidth={isTablet}
                onClick={openExportDialog}
              >
                Export .CSV
              </Button>
            </Stack>
          )}
        </Grid>
      </Grid>
      <Box mt={2}>
        {!isMobile ? (
          receiptsList.length || isFetching ? (
            <TableView
              isLoading={isFetching}
              pagination={{
                page: data?.currentPage,
                perPage: data?.recordsPerPage,
                totalPages: data?.totalPages,
                total: 8,
              }}
              hideColumns={isTablet ? ["description"] : []}
              expandable={
                isTablet && {
                  rowExpandable: () => true,
                  expandedRowRender: (record) => (
                    <>
                      <LabeledRecord label="Time:" value={record.time} />
                      <LabeledRecord
                        label="Type:"
                        value={
                          <Badge label={record.type} variant={"primary"} />
                        }
                      />
                      <LabeledRecord
                        label="Description:"
                        value={record.description}
                      />
                    </>
                  ),
                }
              }
              rowSelection={{
                selectedRowKeys: [],
                onChange: (ids) => setSelectedIds(ids),
              }}
              onAnyCellClick={(record) =>
                navigate(
                  url(ROUTE_SPENDS_EDIT, {
                    type,
                    id: record.id,
                  })
                )
              }
              dataSource={receiptsList}
              columns={spendsHistoryColumns}
              onPageChange={(page) => setPage(page)}
              handleSort={(sortableField) => setSortingState(sortableField)}
            />
          ) : (
            <EmptyState
              icon={<IconEmptyData />}
              description="You have no
            history receipts"
            />
          )
        ) : (
          <>
            <Drawer isOpen={!!selectedIds.length}>
              <AdaptiveButton onClick={() => setOpenActions(true)}>
                Action
              </AdaptiveButton>
            </Drawer>

            <Drawer isOpen={openActions}>
              <Stack spacing={1} alignItems="center">
                <Button
                  startIcon={<IconSend />}
                  color="success"
                  fullWidth={isTablet}
                  disabled={!isIdsSelected}
                  onClick={() =>
                    openConfirmDialog({
                      message:
                        "Are you sure you want to send selected receipts?",
                      onConfirm: () => {
                        console.log("send");
                      },
                    })
                  }
                >
                  Send receipts
                </Button>
                <Button
                  color="info"
                  startIcon={<IconPerson />}
                  fullWidth={isTablet}
                  disabled={!isIdsSelected}
                  onClick={() =>
                    openConfirmDialog({
                      message:
                        "Are you sure you want to mark selected receipts as personal?",
                      onConfirm: () => {
                        console.log("mark personal");
                      },
                    })
                  }
                >
                  Mark as personal
                </Button>
                <Button
                  color="secondary"
                  startIcon={<IconTrash />}
                  fullWidth={isTablet}
                  disabled={!isIdsSelected}
                  onClick={() =>
                    openConfirmDialog({
                      message:
                        "Are you sure you want to remove selected receipts?",
                      onConfirm: () => {
                        console.log("remove");
                      },
                    })
                  }
                >
                  Remove
                </Button>
                <Button
                  color="secondary"
                  startIcon={<IconFolder />}
                  fullWidth={isTablet}
                  disabled={!isIdsSelected}
                  onClick={() =>
                    openConfirmDialog({
                      message:
                        "Do you really want to mark this receipts as personal?\n" +
                        "This receipts will be moved in Archive.",
                      onConfirm: () => {
                        console.log("Archive");
                      },
                    })
                  }
                >
                  Archive
                </Button>
                <Button onClick={() => setOpenActions(false)} color="third">
                  Cancel
                </Button>
              </Stack>
            </Drawer>

            <TableView
              pagination={{
                page: 0,
                perPage: 10,
                totalPages: 1,
                total: 8,
              }}
              expandable={{
                rowExpandable: () => true,
                expandedRowRender: (record) => (
                  <>
                    <LabeledRecord label="Id:" value={record.id} />
                    <LabeledRecord
                      label="Type:"
                      value={<Badge label={record.type} variant={"primary"} />}
                    />
                    <LabeledRecord label="Time:" value={record.time} />
                    <LabeledRecord
                      label="Description:"
                      value={record.description}
                    />
                  </>
                ),
              }}
              rowSelection={{
                selectedRowKeys: [],
                onChange: (ids) => setSelectedIds(ids),
              }}
              onAnyCellClick={(record) =>
                navigate(
                  url(ROUTE_SPENDS_EDIT, {
                    type,
                    id: record.id,
                  })
                )
              }
              dataSource={receiptsList}
              columns={spendsApprovedMobileColumns}
              handleSort={(sortableField) => setSortingState(sortableField)}
            />
          </>
        )}
      </Box>
    </>
  );
};
