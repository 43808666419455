import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AUTH_TOKEN } from "../../constants/app.constants";

import { ROUTE_SIGN_IN } from "../../constants/route.constants";
import { url } from "../../core/utils/route.utils";

export const ProtectedRoute = () => {
  const auth = !!localStorage.getItem(AUTH_TOKEN);
  const cachedType = localStorage.getItem("type");

  return auth ? (
    <Outlet />
  ) : (
    <Navigate to={url(ROUTE_SIGN_IN, { type: cachedType || "employer" })} />
  );
};
