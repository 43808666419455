import { ComponentType, ReactNode } from "react";
import { IconLoaderBlack } from "../icons";
import { Box, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import { IconArrow } from "../icons";
import useStyles from "./styles";

interface Props {
  amount: number;
  title: string;
  percent?: number;
  percentVariant?: "up" | "down";
  icon: ReactNode;
  color?: "success" | "info" | "error" | "light";
  isLoading?: boolean;
}

export const Record: ComponentType<Props> = ({
  amount,
  icon,
  color = "light",
  title,
  percent,
  percentVariant = "up",
  isLoading,
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const dollars = Math.trunc(amount);
  const cents = Math.round(amount % 100) || "00";
  const fill = percentVariant === "up" ? "#27AE60" : "#FE8E8E";

  return (
    <Box
      className={classes.root}
      sx={{
        backgroundColor: `background.${color}`,
        display: "flex",
        justifyContent: isLoading && "center",
        alignItems: "center",
      }}
    >
      {!isLoading ? (
        <Stack direction="row" alignItems="center">
          {!isMobile && (
            <Box
              className={classes.icon}
              sx={{
                color: `common.${color}`,
              }}
            >
              {icon}
            </Box>
          )}

          <Box>
            <Typography
              variant="h1"
              component="div"
              className={classes.dollars}
            >
              ${dollars}
              <Typography
                variant="h4"
                component="span"
                className={classes.cents}
              >
                .{cents}
              </Typography>
            </Typography>
            {percent ? (
              <Typography
                variant="body2"
                component="div"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                  fontWeight: 500,
                  color: fill,
                }}
              >
                <IconArrow
                  className={
                    percentVariant === "up"
                      ? classes.caretUp
                      : classes.caretDown
                  }
                />
                {percent}%
              </Typography>
            ) : (
              <div>-</div>
            )}

            <Typography variant="body2" component="div">
              {title}
            </Typography>
          </Box>
        </Stack>
      ) : (
        <Box className={classes.spinnerContainer}>
          <IconLoaderBlack />
        </Box>
      )}
    </Box>
  );
};
