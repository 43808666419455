import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  avatarWrapper: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    fontSize: "25px",
    height: "88px",
    width: "88px",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "50%",
  },
  [theme.breakpoints.down("md")]: {},
  fileLabel: {
    textDecoration: "underline",
    color: "#12C8C5",
    cursor: "pointer",
  },
}));

export default useStyles;
