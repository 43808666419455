import React, { ComponentType } from "react";
import { useLocation, matchPath } from "react-router-dom";
import { Typography, Avatar, MenuItem as Item } from "@mui/material";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { Badge } from "../../badge";
import { url } from "../../../core/utils/route.utils";
import useStyles from "./styles";

interface Props {
  item: Record<string, any>;
}

export const MenuItem: ComponentType<Props> = ({ item }) => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <Item key={item.id} sx={{ marginBottom: 3 }}>
      {item.avatar && (
        <Avatar
          alt="Remy Sharp"
          sx={{
            width: 24,
            height: 24,
            marginRight: "12px",
          }}
          src={item.avatar?.link}
        />
      )}
      <Typography
        component={Link}
        to={url(item.route)}
        className={clsx(classes.navLink, {
          [classes.navLinkActive]: matchPath(location.pathname, item.route),
        })}
      >
        {item.title}
      </Typography>
      {item.count && (
        <Badge
          sx={{ marginLeft: "8px" }}
          circle
          label={item.count > 9 ? "9+" : item.count.toString()}
        />
      )}
    </Item>
  );
};
