import { ComponentType } from "react";
import { Button, ButtonProps, Stack } from "@mui/material";
import clsx from "clsx";

import useStyles from "./styles";

interface Props {
  items: {
    label: string;
    value: string;
  }[];
  bgColor?: string;
  onChange?: (value: string) => void;
  defaultValue?: string;
  responsive?: boolean;
  value?: string;
}

const BUTTON_STYLE_PROPS = {
  active: {
    color: "third",
    variant: "contained",
    sx: {
      margin: "4px !important",
      padding: "6px 12px !important",
      borderRadius: 2,
      boxShadow: "0px 1px 3px rgba(30, 42, 75, 0.08)",
    },
  },
  default: {
    variant: "text",
    sx: {
      margin: "4px !important",
      padding: "6px 12px !important",
      color: "#6C7294 !important",
      borderRadius: 2,
    },
  },
};

export const RadioGroup: ComponentType<Props> = ({
  items,
  bgColor = "background.light",
  onChange,
  responsive = false,
  value = "",
}) => {
  const classes = useStyles();

  const handleClick = (value: string) => () => {
    onChange && onChange(value);
  };

  return (
    <Stack
      className={clsx(classes.root, responsive && classes.rootResponsive)}
      sx={{ backgroundColor: bgColor }}
      direction="row"
      display="inline-flex"
      spacing={0.25}
      p={0.25}
      borderRadius={3}
    >
      {items.map((item) => {
        const type = item.value === value ? "active" : "default";
        return (
          <Button
            key={item.value}
            size="small"
            className={clsx(
              classes.button,
              responsive && classes.buttonResponsive
            )}
            {...(BUTTON_STYLE_PROPS[type] as ButtonProps)}
            onClick={handleClick(item.value)}
          >
            {item.label}
          </Button>
        );
      })}
    </Stack>
  );
};
