import { ComponentType } from "react";
import useStyles from "./styles";
import clsx from "clsx";

interface Props {
  files?: Array<File>;
}

interface File {
  name: string;
  link: string;
  id: number;
  guid: string;
}

export const FileContainer: ComponentType<Props> = ({ files }) => {
  const classes = useStyles();
  return (
    <>
      {!!files?.length &&
        files.map((file: File) => (
          <a href={file.link} className={clsx(classes.link)} key={file.id}>
            {file.name}
          </a>
        ))}
    </>
  );
};
