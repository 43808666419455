import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import {
  Container,
  Grid,
  Box,
  TextField,
  Paper,
  Theme,
  useMediaQuery,
  Stack,
  Typography,
} from "@mui/material";
import {
  ROUTE_SPENDS_COMMENTS,
  ROUTE_SPENDS_INDEX,
  ROUTE_SPENDS_TEMPLATES_INDEX,
} from "../../constants/route.constants";
import { url } from "../../core/utils/route.utils";
import { AdaptiveButton } from "../../components/adaptive-button";
import {
  IconFolder,
  IconSave,
  IconTrash,
  IconComment,
} from "../../components/icons";
import { PageHeader } from "../../components/page-header";
import { LabeledRecord } from "../../components/labeled-record";
import { InputFile } from "../../components/fields";
import { FilePreview } from "../../components/file-preview";
import { Comments } from "../../components/comments";
import { useDialog } from "../../core/hooks/useDialog";
import { SaveActionDialog } from "./SaveActionDialog";
import { ConfirmDialog } from "../../components/dialogs/ConfirmDialog";
import { useType } from "../../core/hooks/useType";
import {
  useArchiveReceiptMutation,
  useCreateTemplateMutation,
  useDeleteReceiptMutation,
  useGetMeQuery,
  useGetTypesOfActivityQuery,
} from "../../http/api";
import { useEditReceiptForm } from "../../core/hooks/useEditReceiptForm";
import { useSnackbar } from "../../core/hooks/useSnackbar";
import {
  spendsStatusBadge,
  spendsStatusBadgeLabel,
} from "../../constants/statuses.constants";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
} from "../../constants/date.constants";
import { get, isEmpty, map } from "lodash";
import { Badge } from "../../components/badge";
import { FileContainer } from "../../components/files-container";
import { useUploadFileMutation } from "../../http/api/file.api";

interface IFile {
  name: string;
  link: string;
  id: number;
  guid: string;
  size?: number;
}

export const SpendsEdit = () => {
  const { data: userData } = useGetMeQuery({});
  const { id } = useParams();
  const [openSaveActionDialog, popSaveActionDialog] =
    useDialog(SaveActionDialog);
  const [openConfirmDialog, popConfirmDialog] = useDialog(ConfirmDialog);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const [file, setFile] = useState<File | IFile | undefined>(undefined);
  const navigate = useNavigate();
  const { type } = useType();
  const { showMessage } = useSnackbar();
  const { data: typesOfActivityData } = useGetTypesOfActivityQuery({});
  const [archiveReceipt] = useArchiveReceiptMutation({});
  const [deleteReceipt] = useDeleteReceiptMutation({});
  const [uploadFile] = useUploadFileMutation({});
  const [createTemplate] = useCreateTemplateMutation({});

  const typeOfActivityList = map(
    get(typesOfActivityData, "items", []),
    (item) => ({
      value: item.id,
      label: item.name,
    })
  );
  const {
    handleSubmit,
    getFieldProps,
    data,
    refetch,
    setFieldValue,
    validateForm,
    setTouched,
    setErrors,
    values,
  } = useEditReceiptForm({
    guid: undefined,
    id,
    userId: userData?.id,
    file: file instanceof File ? file : undefined,
    onSuccess: () => {
      showMessage("Reciept was updated successfully", "success");
      navigate(url(ROUTE_SPENDS_INDEX, { type }));
    },
    onError: (message) => {
      showMessage(message, "error");
    },
  });

  useEffect(() => {
    if (data?.files?.length) {
      setFile(data?.files[0]);
    }
  }, [data]);

  const handleSaveTemplate = async (name) => {
    const errors = await validateForm();
    if (!isEmpty(errors)) {
      const touched = Object.keys(errors).reduce((acc, item) => {
        acc[item] = true;
        return acc;
      }, {});
      setTouched(touched);
      setErrors(errors);
      popSaveActionDialog();
      return;
    }

    const formattedValues = {
      ...values,
      employeeId: values.userId,
      amount: Number(values.amount),
      type: Number(values.type),
      spentAt: format(new Date(values.spentAt), "yyyy-MM-dd HH:mm:ss"),
      name,
      files: [],
    };

    if (file && file instanceof File) {
      const guid = uuidv4();
      const formData = new FormData();
      formData.append("documentFile", file);
      formData.append("guid", guid);
      await uploadFile(formData);
      formattedValues.files = [guid];
    } else {
      formattedValues.files = data.files;
    }

    const res = await createTemplate(formattedValues);
    const error = get(res, "error", null);
    if (error) {
      const message = get(error, "data.title", "Something went wrong.");
      showMessage(message, "error");
    } else {
      showMessage("Template was created successfully", "success");
      popSaveActionDialog();
      navigate(url(ROUTE_SPENDS_TEMPLATES_INDEX, { type }));
    }
  };

  return (
    <>
      <PageHeader
        title="Receipt information"
        backTitle="New receipts"
        buttonsOrder={isMobile ? "column" : "row"}
        meta={
          <Badge
            label={spendsStatusBadgeLabel(data?.status)}
            variant={spendsStatusBadge[data?.status]}
          />
        }
      >
        {!isMobile && (
          <Stack direction="row" spacing={1}>
            {data?.status === "new" && (
              <>
                {type === "employee" && (
                  <AdaptiveButton
                    startIcon={<IconSave />}
                    onClick={() =>
                      openSaveActionDialog({
                        onSave: handleSubmit,
                        onSaveAndApprove: () => {
                          setFieldValue("onApproval", true);
                          handleSubmit();
                        },
                        onSaveTemplate: handleSaveTemplate,
                      })
                    }
                  >
                    Save
                  </AdaptiveButton>
                )}
                <AdaptiveButton
                  color="error"
                  startIcon={<IconTrash />}
                  onClick={() => {
                    openConfirmDialog({
                      message:
                        "Are you sure you want to remove selected receipts?",
                      onConfirm: async () => {
                        const res = await deleteReceipt({
                          ids: [id],
                        });
                        const error = get(res, "error", null);
                        if (error) {
                          const message = get(
                            error,
                            "data.title",
                            "Something went wrong."
                          );
                          showMessage(message, "error");
                        } else {
                          showMessage(
                            "Receipts was removed successfully",
                            "success"
                          );
                          popConfirmDialog();
                          navigate(url(ROUTE_SPENDS_INDEX, { type }));
                        }
                      },
                    });
                  }}
                >
                  Remove
                </AdaptiveButton>
                <AdaptiveButton
                  color="third"
                  startIcon={<IconFolder />}
                  onClick={() => {
                    openConfirmDialog({
                      message:
                        "Are you sure you want to archive selected receipts?",
                      onConfirm: async () => {
                        const res = await archiveReceipt({
                          ids: [id],
                        });
                        const error = get(res, "error", null);
                        if (error) {
                          const message = get(
                            error,
                            "data.title",
                            "Something went wrong."
                          );
                          showMessage(message, "error");
                        } else {
                          showMessage(
                            "Receipts was archived successfully",
                            "success"
                          );
                          popConfirmDialog();
                          refetch();
                        }
                      },
                    });
                  }}
                >
                  Archive
                </AdaptiveButton>
              </>
            )}
          </Stack>
        )}
      </PageHeader>

      <Container>
        <Box py={3}>
          <Grid container spacing={1.25}>
            <Grid item xs={12} md={12} lg={6}>
              <Box component={Paper} p={isMobile ? 2 : 4} pb={1}>
                <Grid container>
                  <Grid item md={4} xs={12}>
                    <LabeledRecord label="ID:" {...getFieldProps("id")} />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <LabeledRecord
                      label="Organization:"
                      value={data?.vendor_name}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item md={12} xs={12}>
                    {type === "employee" && (
                      <LabeledRecord
                        label="Employee:"
                        value={data?.employeeName}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item md={12} xs={12}>
                    {type === "employee" ? (
                      <TextField
                        label="Description"
                        multiline
                        rows={isMobile ? 3 : 6}
                        {...getFieldProps("description")}
                      />
                    ) : (
                      <LabeledRecord
                        label="Description"
                        value={data?.description}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid container>
                  {type === "employer" ? (
                    <Grid item md={12} xs={12} sx={{ marginBottom: "24px" }}>
                      <Typography
                        variant="body2"
                        color="common.grey"
                        component="div"
                      >
                        Type:
                      </Typography>
                      <Badge label={data?.type} />
                    </Grid>
                  ) : (
                    <Grid
                      item
                      md={6}
                      xs={12}
                      sx={{ marginTop: isMobile ? 2 : 3 }}
                    >
                      <TextField
                        select
                        label="Type"
                        placeholder="Select"
                        {...getFieldProps("type")}
                      >
                        <option key="empty" value="empty">
                          Select
                        </option>
                        {typeOfActivityList.map((item) => (
                          <option
                            value={item.value}
                            key={`type-of-activity-${item.value}`}
                          >
                            {item.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  )}
                </Grid>
                <Grid container>
                  <Grid item md={4} xs={12}>
                    <LabeledRecord
                      label="Date:"
                      value={
                        data?.spentAt
                          ? format(new Date(data.spentAt), DEFAULT_DATE_FORMAT)
                          : "-"
                      }
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <LabeledRecord
                      label="Time:"
                      value={
                        data?.spentAt
                          ? format(new Date(data.spentAt), DEFAULT_TIME_FORMAT)
                          : "-"
                      }
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <LabeledRecord
                      label="Amount:"
                      {...getFieldProps("amount")}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  {type === "employee" ? (
                    <Grid item md={12} xs={12}>
                      {file ? (
                        <FilePreview
                          fileName={file?.name}
                          fileSize={file?.size}
                          onRemove={() => setFile(null)}
                        />
                      ) : (
                        <InputFile
                          label="Files:"
                          setFile={(file) => setFile(file)}
                        />
                      )}
                    </Grid>
                  ) : (
                    file && (
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="body2"
                          color="common.grey"
                          component="div"
                        >
                          File:
                        </Typography>
                        <FileContainer files={data?.files} />
                      </Grid>
                    )
                  )}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              {isMobile ? (
                <AdaptiveButton
                  fullWidth
                  startIcon={<IconComment />}
                  onClick={() => {
                    navigate(url(ROUTE_SPENDS_COMMENTS, { type }));
                  }}
                >
                  Add comment
                </AdaptiveButton>
              ) : (
                <>
                  {data?.guid && (
                    <Comments
                      receiptGuid={data.guid}
                      status={data.status}
                      amount={data.amount}
                      approvedAmount={data.approvedAmount}
                    />
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Box>
        {isMobile && (
          <Stack direction="row" spacing={1}>
            {data?.status === "new" && (
              <>
                {type === "employee" && (
                  <AdaptiveButton
                    startIcon={<IconSave />}
                    onClick={() => openSaveActionDialog({ handleSubmit })}
                  >
                    Save
                  </AdaptiveButton>
                )}
                <AdaptiveButton
                  color="error"
                  startIcon={<IconTrash />}
                  onClick={() => {
                    openConfirmDialog({
                      message:
                        "Are you sure you want to remove selected receipts?",
                      onConfirm: async () => {
                        const res = await deleteReceipt({
                          ids: [id],
                        });
                        const error = get(res, "error", null);
                        if (error) {
                          const message = get(
                            error,
                            "data.title",
                            "Something went wrong."
                          );
                          showMessage(message, "error");
                        } else {
                          showMessage(
                            "Receipts was removed successfully",
                            "success"
                          );
                          popConfirmDialog();
                          navigate(url(ROUTE_SPENDS_INDEX, { type }));
                        }
                      },
                    });
                  }}
                >
                  Remove
                </AdaptiveButton>
              </>
            )}
            <AdaptiveButton
              color="third"
              startIcon={<IconFolder />}
              onClick={() => {
                openConfirmDialog({
                  message:
                    "Are you sure you want to archive selected receipts?",
                  onConfirm: async () => {
                    const res = await archiveReceipt({
                      ids: [id],
                    });
                    const error = get(res, "error", null);
                    if (error) {
                      const message = get(
                        error,
                        "data.title",
                        "Something went wrong."
                      );
                      showMessage(message, "error");
                    } else {
                      showMessage(
                        "Receipts was archived successfully",
                        "success"
                      );
                      popConfirmDialog();
                      refetch();
                    }
                  },
                });
              }}
            >
              Archive
            </AdaptiveButton>
          </Stack>
        )}
      </Container>
    </>
  );
};
