import { ComponentType } from "react";
import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { IconSearch } from "../../icons";
import useStyles from "./styles";

export const SearchInput: ComponentType<TextFieldProps> = (props) => {
  const classes = useStyles();
  return (
    <TextField
      className={classes.root}
      {...props}
      placeholder="Search"
      helperText=""
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconSearch fontSize="small" />
          </InputAdornment>
        ),
      }}
    />
  );
};
