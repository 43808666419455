import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "radio-group",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      "& .MuiButton-root": {
        color: theme.palette.common.dark,
      },
    },
    rootResponsive: {
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    button: {
      "&:hover": {
        backgroundColor: "#fff",
        boxShadow: "0px 1px 3px rgba(30, 42, 75, 0.08)",
      },
    },
    buttonResponsive: {
      [theme.breakpoints.down("md")]: {
        width: "50%",
      },
    },
  }),
  options
);

export default useStyles;
