import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "record",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      borderRadius: 16,
      minHeight: 130,
    },
    [theme.breakpoints.up("xs")]: {
      root: {
        padding: theme.spacing(2, 3),
      },
      dollars: {
        fontSize: "24px",
      },
      cents: {
        fontSize: "18px",
      },
    },
    [theme.breakpoints.up("md")]: {
      root: {
        padding: theme.spacing(3.75, 3),
      },
      dollars: {
        fontSize: "32px",
      },
      cents: {
        fontSize: "18px",
      },
    },
    [theme.breakpoints.up("lg")]: {
      root: {
        padding: theme.spacing(3.75, 3),
      },
    },

    icon: {
      marginRight: theme.spacing(3.125),
      "& svg": {
        fontSize: 40,
      },
    },
    caretDown: {
      width: "10px",
      marginRight: "7px",
      "& path": {
        fill: "#FE8E8E",
      },
    },
    caretUp: {
      "& path": {
        fill: "#27AE60",
      },
      width: "10px",
      marginRight: "7px",
      transform: "rotate(180deg)",
    },
    spinnerContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      ackgroundColor: "red",
      animation: `$spinner 3000ms infinite`,
    },

    "@keyframes spinner": {
      "0%": { transform: "rotate(0)" },
      "100%": { transform: "rotate(360deg)" },
    },
  }),
  options
);

export default useStyles;
