import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../core/store/rtk.utils";
import { GET_VENDORS } from "./api.routes";
import { IGetListResponse } from "../../types";

interface IVendor {
  amount: number;
  countComments: number;
  description: string;
  guid: string;
  id: number;
  spentAt: string;
  type: string | null;
  vendor_name: string;
}

interface IVendors extends IGetListResponse {
  items: Array<IVendor>;
}

export const vendorApi = createApi({
  reducerPath: "vendorApi",
  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getVendors: builder.query<IVendors, any>({
      query: (params) => ({
        url: GET_VENDORS,
        method: "GET",
        params,
      }),
    }),
  }),
});

export const { useGetVendorsQuery } = vendorApi;
