import { ComponentType, ReactNode } from "react";
import clsx from "clsx";
import { Box, Typography, Stack, Paper } from "@mui/material";
import useStyles from "./styles";
import { IconLoaderBlack } from "../icons";

interface Props {
  icon: ReactNode;
  description: string;
  action?: ReactNode;
  loading?: boolean;
}

export const EmptyState: ComponentType<Props> = ({
  icon,
  action,
  description,
  loading = false,
}) => {
  const classes = useStyles();
  return (
    <Box
      component={Paper}
      className={clsx(classes.root, {
        [classes.loading]: loading,
      })}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        height="100%"
        width="100%"
      >
        {loading ? (
          <IconLoaderBlack />
        ) : (
          <>
            <div className={classes.icon}>{icon}</div>
            <Typography variant="body2">{description}</Typography>
            <div className={classes.action}>{action}</div>
          </>
        )}
      </Stack>
    </Box>
  );
};
