import { useEffect } from "react";
import { AUTH_TOKEN } from "../../constants/app.constants";
import { useLazyGetMeQuery } from "../../http/api";

export const useType = () => {
  const [trigger, result] = useLazyGetMeQuery({});
  const cachedType = localStorage.getItem("type");
  useEffect(() => {
    if (localStorage.getItem(AUTH_TOKEN)) {
      trigger({});
    }
  }, [trigger]);

  useEffect(() => {
    if (result?.data?.roles) {
      localStorage.setItem(
        "type",
        result?.data.roles.includes("ROLE_EMPLOYER") ? "employer" : "employee"
      );
    }
  }, [result]);

  return {
    type: result?.data?.roles
      ? result?.data.roles.includes("ROLE_EMPLOYER")
        ? "employer"
        : "employee"
      : cachedType || "employer",
  };
};
