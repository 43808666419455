import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    position: "initial",
    "& .MuiPaper-root": {
      padding: theme.spacing(3),
      background: theme.palette.common.white,
      boxShadow: "0px -8px 16px rgba(24, 61, 98, 0.04)",
      border: "none",
      borderRadius: 0,
    },
  },
}));

export default useStyles;
