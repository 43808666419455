import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& table thead tr > th:last-child > div": {
      justifyContent: "flex-end",
    },
  },
}));

export default useStyles;
