import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "message",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(1.5, 0),
      borderBottom: "1px dashed #E7EAF9",
      display: "flex",
      flexDirection: "row",
      width: "100%",

      "&:first-child": {
        paddingTop: theme.spacing(0.5),
      },
      "&:last-child": {
        borderBottom: "none",
      },
    },
    author: {
      fontSize: 14,
      fontWeight: 500,
      color: theme.palette.common.info,
    },
    time: {
      fontSize: 12,
      color: theme.palette.common.grey,
    },
    filePreview: {
      textDecoration: "none",
      color: "black",
      "&:hover": {
        textDecoration: "none",
        color: "black",
      },
      "&:visited": {
        textDecoration: "none",
        color: "black",
      },
      "&:active": {
        textDecoration: "none",
        color: "black",
      },
      "&:focus": {
        textDecoration: "none",
        color: "black",
      },
    },
  }),
  options
);

export default useStyles;
