import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import { get } from "lodash";
import { AmountView } from "../../components/amount-view";
import { Badge } from "../../components/badge";
import { LinkButton } from "../../components/buttons/LinkButton";
import { DataTableColumn } from "../../components/table-view/TableView";
import { DEFAULT_DATE_FORMAT } from "../../constants/date.constants";
import { ROUTE_SPENDS_TEMPLATE_EDIT } from "../../constants/route.constants";
import { url } from "../utils/route.utils";

const templatesTypeBadge = {
  Food: "warning",
  "Dinner with client": "success",
};

export const templatesColumn = (type: string): DataTableColumn[] => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text) => (
      <Typography variant={"body2"} sx={{ fontWeight: 500 }}>
        {text}
      </Typography>
    ),
  },
  {
    title: "Organization",
    dataIndex: "vendor_name",
    key: "vendor_name",
    cellProps: {
      sx: {
        whiteSpace: "nowrap",
      },
    },
    render: (text) => (
      <Typography variant={"body2"} sx={{ fontWeight: 500 }}>
        {text}
      </Typography>
    ),
  },
  // {
  //   title: "Employee",
  //   dataIndex: "employee",
  //   key: "employee",
  //   cellProps: {
  //     sx: {
  //       whiteSpace: "nowrap",
  //     },
  //   },
  //   render: (text, { id }) => (
  //     <Typography variant={"body2"} sx={{ fontWeight: 500 }}>
  //       {text}
  //     </Typography>
  //   ),
  // },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    render: (text, { id }) => {
      if (text) {
        return <Typography variant={"body2"}>{text}</Typography>;
      }

      return (
        <LinkButton
          color={"common.info"}
          underline={"always"}
          to={url(ROUTE_SPENDS_TEMPLATE_EDIT, { type, id })}
        >
          Add description
        </LinkButton>
      );
    },
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    sortable: true,
    cellProps: {
      sx: {
        whiteSpace: "nowrap",
      },
    },
    render: (text) => {
      if (text) {
        return (
          <Badge
            label={text}
            variant={get(templatesTypeBadge, text, "primary")}
          />
        );
      }
      return (
        <LinkButton
          color="common.info"
          underline="always"
          to={url(ROUTE_SPENDS_TEMPLATE_EDIT, { type, id: "new" })}
        >
          Add type
        </LinkButton>
      );
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    sortable: true,
    cellProps: {
      align: "right",
    },
    render: (amount) => <AmountView amount={amount} />,
  },
];

export const spendsColumnMobile: DataTableColumn[] = [
  {
    title: "Select ALL",
    dataIndex: "item",
    key: "item",
    render: (text, { vendorName, createdAt, amount }) => {
      if (text === "SELECT ALL") {
        return null;
      }
      return (
        <Box>
          <Box>
            <Typography
              mr={1}
              component={"span"}
              mb={3}
              variant="body2"
              color="common.grey"
            >
              Organization:
            </Typography>
            {vendorName}
          </Box>
          <Box>
            <Typography
              mr={1}
              component={"span"}
              mb={3}
              variant="body2"
              color="common.grey"
            >
              Date:
            </Typography>
            {createdAt ? format(new Date(createdAt), DEFAULT_DATE_FORMAT) : "-"}
          </Box>
          <Box>
            <Typography
              mr={1}
              component={"span"}
              mb={3}
              variant="body2"
              color="common.grey"
            >
              Amount:
            </Typography>
            {amount}
          </Box>
        </Box>
      );
    },
  },
];

export const templatesColumnMobile: DataTableColumn[] = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Organization",
    dataIndex: "organization",
    key: "organization",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (text) => (
      <Badge label={text} variant={get(templatesTypeBadge, text, "primary")} />
    ),
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    cellProps: {
      align: "right",
    },
    render: (amount) => <AmountView amount={amount} />,
  },
];
