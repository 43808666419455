import { useCallback, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Avatar,
  Button,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { AuthLogo } from "../../../components/logos";
import { GoogleButton } from "../../../components/buttons";
import { Space } from "../../../components/space";
import { url } from "../../../core/utils/route.utils";
import {
  ROUTE_SIGN_IN_RESULT,
  ROUTE_SIGN_UP,
} from "../../../constants/route.constants";

import useStyles from "./styles";
import { AUTH_TOKEN, ENDPOINT_ROOT } from "../../../constants/app.constants";
import { useLazyGetMeQuery } from "../../../http/api";
import { generateInitials } from "../../../core/utils/generateInitials";

export const SignIn = () => {
  const { type } = useParams();
  const [trigger, result] = useLazyGetMeQuery({});

  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  useEffect(() => {
    if (localStorage.getItem(AUTH_TOKEN)) {
      trigger({});
    }
  }, [trigger]);

  const handleAuth = useCallback(() => {
    window.location.href = `${ENDPOINT_ROOT}/${type}/google/connect`;
  }, [type]);

  const initials = result?.data
    ? generateInitials(result?.data.firstName, result?.data.lastName)
    : "";

  return (
    <Box className={classes.root}>
      <Box mb={5}>
        <AuthLogo />
      </Box>
      <Box className={classes.block}>
        <Typography variant={isMobile ? "h3" : "h1"} mb={3}>
          Sign in
        </Typography>
        {result?.data ? (
          <>
            <Typography variant="body2" component={Box} mb={3}>
              You signed in yet with
            </Typography>
            <Box className={classes.signedUser} mb={3}>
              {result?.data?.avatar ? (
                <Avatar
                  className={classes.avatar}
                  src={result?.data?.avatar?.link}
                />
              ) : (
                <div className={classes.avatarWrapper}>{initials}</div>
              )}
              <Typography variant="body1">
                {result?.data?.firstName} {result?.data?.lastName}
              </Typography>
            </Box>
            <Space direction="column" spacing={1} width="100%">
              <Button fullWidth component={Link} to={ROUTE_SIGN_IN_RESULT}>
                Continue
              </Button>
              <Button fullWidth color="secondary" onClick={() => handleAuth()}>
                Change account
              </Button>
            </Space>
          </>
        ) : (
          <GoogleButton onClick={() => handleAuth()}>
            Sign In with Google
          </GoogleButton>
        )}
      </Box>

      <Typography variant="body1" component="span">
        I haven’t an account.{" "}
        <Link to={url(ROUTE_SIGN_UP, { type })} className={classes.link}>
          Sign up
        </Link>
      </Typography>
    </Box>
  );
};
