import React from "react";
import { Button, ButtonProps } from "@mui/material";
import useStyles from "./styles";

export const AdaptiveButton = <C extends React.ElementType>({
  startIcon,
  fullWidth,
  ...props
}: ButtonProps<C, { component?: C }>) => {
  const classes = useStyles();
  return (
    <Button
      startIcon={startIcon}
      {...props}
      fullWidth={fullWidth}
      className={classes.root}
    />
  );
};
