import React, { ComponentType, ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";
import MuiTabs from "@mui/material/Tabs";
import MuiTab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { TabPanel } from "./TabPanel";

interface Props {
  items: {
    key: string;
    label: string;
    content: ReactNode;
  }[];
  onChange?: (key: string) => void;
  defaultValue?: number;
}

export const Tabs: ComponentType<Props> = ({
  items,
  onChange,
  defaultValue = 0,
}) => {
  const [value, setValue] = React.useState<number>(defaultValue);
  const { hash } = useLocation();

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
    onChange && onChange(items[newValue].key);
  };

  useEffect(() => {
    if (hash) {
      const index = items.findIndex((item) => item.key === hash.slice(1));
      if (index !== -1) {
        handleChange(null, index);
      }
    }
  }, [hash]);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: 4 }}>
        <MuiTabs value={value} onChange={handleChange}>
          {items.map((item, index) => (
            <MuiTab key={index} label={item.label} />
          ))}
        </MuiTabs>
      </Box>
      {items.map((item, index) => (
        <TabPanel key={index} value={value} index={index}>
          {item.content}
        </TabPanel>
      ))}
    </>
  );
};
