import useStyles from "./styles";
import { ComponentType } from "react";
import { IconArrowLeft } from "../../icons";
import { Button } from "@mui/material";
interface Props {
  title: string;
}

export const BackNavigationButton: ComponentType<Props> = ({ title }) => {
  const classes = useStyles();

  return (
    <Button
      variant="text"
      className={classes.backButton}
      startIcon={<IconArrowLeft width={12} sx={{ ml: "-3px" }} />}
      onClick={() => {
        window.history.back();
      }}
    >
      {title}
    </Button>
  );
};
