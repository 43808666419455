import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "back-navigation-button",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    backButton: {
      color: theme.palette.common.grey,
      fontSize: 14,
      fontWeight: 500,
      marginLeft: theme.spacing(-0.5),
      marginBottom: theme.spacing(1),

      "& svg": {
        fontSize: "12px !important",
      },
    },
  }),
  options
);

export default useStyles;
