import React, { ComponentType } from "react";
import { Container, Box, Typography, Grid } from "@mui/material";
import { useStyles } from "./styles";
import { IconNotFound, Icon404 } from "../../components/icons";

export const NotFound: ComponentType = () => {
  const classes = useStyles();
  return (
    <Container>
      <Grid container direction="column" className={classes.root}>
        <Grid item>
          <Box className={classes.iconNotFoundWrapper}>
            <IconNotFound />
          </Box>
        </Grid>
        <Grid item>
          <Box className={classes.icon404Wrapper}>
            <Icon404 />
          </Box>
        </Grid>
        <Grid item>
          <Typography color="common.grey" variant="h1">
            Page not found
          </Typography>
        </Grid>
        <Grid item>
          <Typography color="common.grey" variant="body1">
            The page are you looking for not available.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};
