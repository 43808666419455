import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { isRejectedWithValue, Middleware } from "@reduxjs/toolkit";
import { forEach, get, isNull } from "lodash";
import { ENDPOINT_ROOT, AUTH_TOKEN } from "../../constants/app.constants";
import { ROUTE_SIGN_IN } from "../../constants/route.constants";
import { url } from "../utils/route.utils";

export const rtkBaseHeaders = (headers: Headers) => {
  headers.set("Content-Type", "application/json");
  headers.set("Accept", "application/json");

  const token = localStorage.getItem(AUTH_TOKEN);
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }

  return headers;
};

export const rtkMultipartHeaders = (headers: Headers) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }

  return headers;
};

export const baseQuery = fetchBaseQuery({
  baseUrl: ENDPOINT_ROOT,
  prepareHeaders: rtkBaseHeaders,
  paramsSerializer: (params) => {
    const paramsSerialize = new URLSearchParams();
    forEach(params, (value: string, key: string) => {
      if (Array.isArray(value)) {
        value.forEach((v) => paramsSerialize.append(`${key}[]`, v));
      } else if (isNull(value)) {
        console.log(111);
      } else {
        paramsSerialize.append(key, value);
      }
    });
    return paramsSerialize.toString();
  },
});

export const baseMultipartQuery = fetchBaseQuery({
  baseUrl: ENDPOINT_ROOT,
  prepareHeaders: rtkMultipartHeaders,
  paramsSerializer: (params) => {
    const paramsSerialize = new URLSearchParams();
    forEach(params, (value: string, key: string) => {
      if (Array.isArray(value)) {
        value.forEach((v) => paramsSerialize.append(`${key}[]`, v));
      } else if (isNull(value)) {
        console.log(111);
      } else {
        paramsSerialize.append(key, value);
      }
    });
    return paramsSerialize.toString();
  },
});

export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const status = get(action, "payload.status", null);
    if (status === 401) {
      localStorage.removeItem(AUTH_TOKEN);
      const cachedType = localStorage.getItem("type");
      window.location.href = url(ROUTE_SIGN_IN, {
        type: cachedType || "employer",
      });
    } else if (status > 300 && status < 600) {
      console.warn("Unexpected status", action);
    }
  }

  return next(action);
};
