import * as yup from "yup";
import { useFormik } from "formik";
import get from "lodash/get";
import { v4 as uuidv4 } from "uuid";

import { useSendCommentMutation } from "../../http/api";
import { useUploadFileMutation } from "../../http/api/file.api";

interface UseProps {
  guid: string;
  onSuccess: () => void;
  onError: (message: string) => void;
  file?: File;
}

export const useSendCommentForm = ({
  guid,
  onSuccess,
  onError,
  file,
}: UseProps) => {
  const [sendComment] = useSendCommentMutation({});
  const [uploadFile] = useUploadFileMutation({});
  const {
    handleSubmit,
    getFieldProps: formikGetFieldProps,
    setFieldValue,
    errors,
    values,
    touched,
    resetForm,
  } = useFormik({
    initialValues: {
      guid: guid ? guid : uuidv4(),
      text: "",
      files: [],
    },
    validationSchema: yup.object().shape({
      guid: yup.string(),
      files: yup.array(),
      text: yup.string().required("Comment text is required"),
    }),
    onSubmit: async (values) => {
      const formattedValues = { ...values };

      if (file) {
        const guid = uuidv4();
        const formData = new FormData();
        formData.append("documentFile", file);
        formData.append("guid", guid);
        await uploadFile(formData);
        formattedValues.files = [guid];
      }
      const res = await sendComment(formattedValues);
      const error = get(res, "error", null);
      if (error) {
        const message = get(error, "data.title", "Something went wrong.");
        onError(message);
      } else {
        resetForm();
        onSuccess();
      }
    },
  });

  const getFieldProps = (fieldName) => {
    const error = errors[fieldName] && touched[fieldName];
    return {
      error,
      helperText: error ? errors[fieldName] : "",
      ...formikGetFieldProps(fieldName),
      onChange: (e) => {
        const targetValue = get(e, "target.value");
        if (targetValue || targetValue === "") {
          setFieldValue(fieldName, targetValue);
        } else {
          setFieldValue(fieldName, e);
        }
      },
    };
  };

  return {
    handleSubmit,
    errors,
    values,
    getFieldProps,
    setFieldValue,
  };
};
