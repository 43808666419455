import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import useStyles from "./styles";
import { TextField } from "@mui/material";
import { useRef } from "react";

export const MultiSelect = (props) => {
  const classes = useStyles();
  const textFieldRef = useRef(null);

  const filter = createFilterOptions();

  return (
    <Autocomplete
      className={classes.root}
      fullWidth
      multiple
      disabled={false}
      value={props.value}
      onChange={(event, newValue) => {
        props.onChange(newValue);
      }}
      onBlur={(event) => {
        if (props.onBlur) {
          props.onBlur(event);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return filtered;
      }}
      options={props.options.filter(
        (item) => !props.value.find((value) => value.value === item.value)
      )}
      selectOnFocus
      clearOnBlur={!props.saveOnBlur}
      handleHomeEndKeys
      inputValue={props.text}
      freeSolo={props.freeSolo}
      renderOption={(props, option) => <li {...props}>{option.label}</li>}
      renderInput={(params) => {
        return (
          <>
            <TextField
              ref={textFieldRef}
              className={classes.formControl}
              placeholder={!props.value.length ? props.placeholder : ""}
              required={props.required}
              sx={{
                "& .MuiAutocomplete-endAdornment": {
                  top: "unset",
                },
              }}
              helperText={!!props.errors && "Field is required."}
              onChange={(e) => props.setText(e.target.value)}
              {...params}
            />
          </>
        );
      }}
    />
  );
};
