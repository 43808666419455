import useStyles from "./styles";
import { ComponentType } from "react";
import { IconGoogleLogoMin } from "../../icons";

interface Props {
  onClick: () => void;
}

export const GoogleButton: ComponentType<Props> = ({ onClick, children }) => {
  const classes = useStyles();

  return (
    <button className={classes.root} onClick={onClick}>
      <IconGoogleLogoMin className={classes.icon} />
      <span className={classes.text}>{children}</span>
    </button>
  );
};
