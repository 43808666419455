import { combineReducers } from "redux";
import {
  userApi,
  receiptApi,
  vendorApi,
  typeOfActivityApi,
  typeOfBusinessApi,
  organizationApi,
  invitationApi,
  employeeApi,
  notificationsApi,
  templateApi,
  commentApi,
} from "../../http/api";

export const rootReducer = combineReducers({
  [userApi.reducerPath]: userApi.reducer,
  [receiptApi.reducerPath]: receiptApi.reducer,
  [vendorApi.reducerPath]: vendorApi.reducer,
  [typeOfActivityApi.reducerPath]: typeOfActivityApi.reducer,
  [typeOfBusinessApi.reducerPath]: typeOfBusinessApi.reducer,
  [organizationApi.reducerPath]: organizationApi.reducer,
  [invitationApi.reducerPath]: invitationApi.reducer,
  [employeeApi.reducerPath]: employeeApi.reducer,
  [notificationsApi.reducerPath]: notificationsApi.reducer,
  [templateApi.reducerPath]: templateApi.reducer,
  [commentApi.reducerPath]: commentApi.reducer,
});
