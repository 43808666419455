import * as yup from "yup";
import { useFormik } from "formik";
import get from "lodash/get";

import { useLazyGetStatisticsQuery } from "../../http/api";
import { endOfDay, format, startOfDay, sub } from "date-fns";
import { useEffect } from "react";

interface UseProps {
  onSuccess?: () => void;
  onError: (message: string) => void;
}

export const useStatisticsForm = ({ onSuccess, onError }: UseProps) => {
  const [trigger, result] = useLazyGetStatisticsQuery({});

  const {
    handleSubmit,
    getFieldProps: formikGetFieldProps,
    setFieldValue,
    errors,
    values,
    touched,
  } = useFormik({
    initialValues: {
      dateFrom: sub(startOfDay(new Date()), { months: 1 }),
      dateTo: endOfDay(new Date()),
    },
    validationSchema: yup.object().shape({
      dateFrom: yup.string().required("Required field"),
      dateTo: yup.string().required("Required field"),
    }),
    onSubmit: async (values) => {
      const formattedValues = {
        ...values,
        dateFrom: format(new Date(values.dateFrom), "yyyy-MM-dd HH:mm:ss"),
        dateTo: format(new Date(values.dateTo), "yyyy-MM-dd HH:mm:ss"),
      };
      const res = await trigger(formattedValues);
      const error = get(res, "error", null);
      if (error) {
        const message = get(error, "data.title", "Something went wrong.");
        onError(message);
      } else {
        if (onSuccess) {
          onSuccess();
        }
      }
    },
  });

  const getFieldProps = (fieldName) => {
    const error = errors[fieldName] && touched[fieldName];
    return {
      error,
      helperText: error ? errors[fieldName] : "",
      ...formikGetFieldProps(fieldName),
      onChange: (e) => {
        const targetValue = get(e, "target.value");
        if (targetValue || targetValue === "") {
          setFieldValue(fieldName, targetValue);
        } else {
          setFieldValue(fieldName, e);
        }
      },
    };
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  return {
    handleSubmit,
    errors,
    values,
    getFieldProps,
    setFieldValue,
    result,
  };
};
