import { makeStyles } from "@mui/styles";

const options = {
  name: "table-view",
};

const useStyles = makeStyles(
  () => ({
    root: {
      "& .MuiCheckbox-root .MuiSvgIcon-root": {
        color: "#B8BDD9",
      },
      "& .MuiCheckbox-root.Mui-checked .MuiSvgIcon-root, & .MuiCheckbox-indeterminate .MuiSvgIcon-root":
        {
          color: "#131730",
        },
    },
    spinnerContainer: {
      height: "200px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      ackgroundColor: "red",
      animation: `$spinner 3000ms infinite`,
    },
    "@keyframes spinner": {
      "0%": { transform: "rotate(0)" },
      "100%": { transform: "rotate(360deg)" },
    },
  }),
  options
);

export default useStyles;
