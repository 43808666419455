import { ComponentType } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import useStyles from "./styles";
import { Space } from "../../space";

import { spendsCommentsStatusBadgeLabel } from "../../../constants/statuses.constants";
import { CommentsBadge } from "../../comments-badge";
import { FilePreview } from "../../file-preview";
import { COMMENTS_DATE_FORMAT } from "../../../constants/date.constants";
import { format } from "date-fns";

interface IFile {
  name: string;
  link: string;
  id: number;
  guid: string;
  size?: number;
}

interface Item {
  text: string;
  createdAt: string;
  owner: IOwner;
  amount?: number;
  approvedAmount?: number;
  files?: IFile[];
}
interface IOwner {
  id: number;
  firstName: string;
  lastName: string;
  userName: string;
  avatar?: {
    guid: string;
    id: number;
    link: string;
    name: string;
    size: number;
  };
  createdAt: string;
  lastLogin: string;
}

interface Props {
  item: Item;
  statuses?: string[];
  statusView?: boolean;
}

export const Message: ComponentType<Props> = ({
  item,
  statuses,
  statusView,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Avatar src={item.owner.avatar?.link} />
      <Box ml={1} sx={{ width: "100%" }}>
        <Space spacing={1} alignItems="center">
          <Typography className={classes.author} component="div">
            {item.owner.firstName} {item.owner.lastName}
          </Typography>
          {!statusView && (
            <Typography className={classes.time} component="div">
              {format(new Date(item.createdAt), COMMENTS_DATE_FORMAT)}
            </Typography>
          )}
        </Space>
        {statusView ? (
          <Typography variant="body2">{item.createdAt}</Typography>
        ) : (
          <>
            <Typography variant="body2">{item.text}</Typography>
            {!!item?.files.length && (
              <a
                href={item?.files[0].link}
                target="_blank"
                className={classes.filePreview}
              >
                <FilePreview
                  fileName={item?.files[0].name}
                  fileSize={item?.files[0].size}
                />
              </a>
            )}
          </>
        )}
        {statuses?.includes(item.text) && (
          <CommentsBadge
            label={spendsCommentsStatusBadgeLabel(
              item.text,
              item.amount,
              item.approvedAmount
            )}
            status={item.text}
          />
        )}
      </Box>
    </Box>
  );
};
