import { ComponentType } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { DialogProps } from "../../core/providers/dialog.provider";

interface Props extends DialogProps {
  title?: string;
  message?: string;
  onConfirm: () => void;
  okText?: string;
  cancelText?: string;
}

export const ConfirmDialog: ComponentType<Props> = ({
  popDialog,
  title = "Confirm your action",
  message,
  onConfirm,
  okText = "Yes, I do",
  cancelText = "Cancel",
}) => {
  return (
    <Dialog open onClose={popDialog}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button onClick={onConfirm}>{okText}</Button>
        <Button color="secondary" onClick={popDialog}>
          {cancelText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
