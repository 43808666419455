import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "search-input",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      "& .MuiOutlinedInput-input": {
        backgroundColor: "#fff",
      },
    },
  }),
  options
);

export default useStyles;
