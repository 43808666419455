import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { rootReducer } from "./reducers";
import { rtkQueryErrorLogger } from "./rtk.utils";
import {
  userApi,
  receiptApi,
  vendorApi,
  typeOfActivityApi,
  typeOfBusinessApi,
  organizationApi,
  invitationApi,
  employeeApi,
  notificationsApi,
  templateApi,
  commentApi,
} from "../../http/api";

export const makeStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat([
        rtkQueryErrorLogger,
        userApi.middleware,
        receiptApi.middleware,
        vendorApi.middleware,
        typeOfActivityApi.middleware,
        typeOfBusinessApi.middleware,
        organizationApi.middleware,
        invitationApi.middleware,
        employeeApi.middleware,
        notificationsApi.middleware,
        templateApi.middleware,
        commentApi.middleware,
      ]),
  });
};

export const store = makeStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore["getState"]>;
// export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action
>;
