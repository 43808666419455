import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "input",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(1.5, 3),
      boxShadow: "0px -4px 4px rgba(231, 234, 249, 0.36)",
    },
    icons: {
      color: "#131730",
      "& svg": {
        fontSize: 19,
      },
    },
    sendButton: {
      backgroundColor: "#F5F5FC",
      paddingLeft: theme.spacing(1.8),
      paddingRight: theme.spacing(1.8),
    },
  }),
  options
);

export default useStyles;
