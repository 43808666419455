import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: "auto",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  [theme.breakpoints.up("md")]: {},
  [theme.breakpoints.down("md")]: {
    block: {
      maxWidth: "unset !important",
      width: "100%",
    },
  },
  block: {
    width: 480,
    background: theme.palette.common.white,
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.06)",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
    marginBottom: theme.spacing(5),
    borderRadius: "8px",
  },
  inputContainer: {
    width: "100%",
    textAlign: "center",
  },
  link: {
    color: theme.palette.common.info,
  },
}));
