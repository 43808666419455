import * as yup from "yup";
import { useFormik } from "formik";
import get from "lodash/get";
import { v4 as uuidv4 } from "uuid";

import { useCreateOrganizationMutation } from "../../http/api";

interface UseProps {
  id: string;
  onSuccess: () => void;
  onError: (message: string) => void;
}

export const useOrganizationForm = ({ id, onSuccess, onError }: UseProps) => {
  const [createOrganization] = useCreateOrganizationMutation({});
  const {
    handleSubmit,
    getFieldProps: formikGetFieldProps,
    setFieldValue,
    errors,
    values,
    touched,
  } = useFormik({
    initialValues: {
      guid: id ? id : uuidv4(),
      name: "",
      typeOfBusinessGuid: "",
    },
    validationSchema: yup.object().shape({
      guid: yup.string().required("Required field"),
      name: yup.string().required("Required field"),
      typeOfBusinessGuid: yup.string().required("Required field"),
    }),
    onSubmit: async (values) => {
      const res = await createOrganization(values);
      const error = get(res, "error", null);
      if (error) {
        const message = get(error, "data.title", "Something went wrong.");
        onError(message);
      } else {
        onSuccess();
      }
    },
  });

  const getFieldProps = (fieldName) => {
    const error = errors[fieldName] && touched[fieldName];
    return {
      error,
      helperText: error ? errors[fieldName] : "",
      ...formikGetFieldProps(fieldName),
      onChange: (e) => {
        const targetValue = get(e, "target.value");
        if (targetValue || targetValue === "") {
          setFieldValue(fieldName, targetValue);
        } else {
          setFieldValue(fieldName, e);
        }
      },
    };
  };

  return {
    handleSubmit,
    errors,
    values,
    getFieldProps,
    setFieldValue,
  };
};
