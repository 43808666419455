import { Box } from "@mui/system";
import { ComponentType } from "react";

import {
  IconCommentsApprovedReceipts,
  IconCommentsDeclinedReceipts,
} from "../icons";
import useStyles from "./styles";
import clsx from "clsx";

interface Props {
  label: string;
  status?: string;
  sx?: any;
}

export const CommentsBadge: ComponentType<Props> = ({ label, status, sx }) => {
  const classes = useStyles();
  return (
    <Box
      sx={sx}
      className={clsx(classes.root, {
        [classes.primary]: ["approved", "approved_partially"].includes(status),
        [classes.error]: status === "declined",
      })}
      component="span"
    >
      {status === "declined" ? (
        <IconCommentsDeclinedReceipts />
      ) : (
        <IconCommentsApprovedReceipts />
      )}
      Receipt {label}
    </Box>
  );
};
