import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "adaptive-button",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      maxHeight: "40px",
      [theme.breakpoints.down("md")]: {
        "& .MuiButton-startIcon": {
          marginRight: 0,
          marginLeft: 0,
        },
        fontSize: "14px",
      },
      [theme.breakpoints.up("md")]: {
        "& .MuiButton-startIcon": {
          marginRight: theme.spacing(1),
          marginLeft: 0,
        },
        fontSize: "14px",
      },
    },
  }),
  options
);

export default useStyles;
