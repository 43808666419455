import { ComponentType } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { DialogProps } from "../../core/providers/dialog.provider";
import { useChangeEmailForm } from "../../core/hooks/useChangeEmailForm";
import { useChangeEmployerEmailForm } from "../../core/hooks/useChangeEmployerEmailForm";
import { useType } from "../../core/hooks/useType";
import { useGetMeQuery } from "../../http/api";

interface ChangeEmailDialogProps extends DialogProps {
  onSuccess: () => void;
  onError: (message: string) => void;
}

export const ChangeEmailDialog: ComponentType<ChangeEmailDialogProps> = ({
  popDialog,
  onSuccess,
  onError,
}) => {
  const { type } = useType();
  const { data } = useGetMeQuery({});
  const {
    getFieldProps: getEmployeeFieldProps,
    handleSubmit: handleEmployeeSubmit,
  } = useChangeEmailForm({
    onSuccess: () => {
      onSuccess();
      popDialog();
    },
    onError: (message) => {
      onError(message);
    },
  });

  const {
    getFieldProps: getEmployerFieldProps,
    handleSubmit: handleEmployerSubmit,
  } = useChangeEmployerEmailForm({
    id: data.id,
    onSuccess: () => {
      onSuccess();
      popDialog();
    },
    onError: (message) => {
      onError(message);
    },
  });

  return (
    <Dialog open onClose={popDialog} fullWidth>
      <DialogTitle>Change email address</DialogTitle>
      <DialogContent>
        <Typography variant="body2" sx={{ mb: 1.5 }}>
          Enter the email address below and we will return to you soon.
        </Typography>
        {type === "employee" ? (
          <TextField
            label="Email address"
            {...getEmployeeFieldProps("email")}
          />
        ) : (
          <TextField
            label="Email address"
            {...getEmployerFieldProps("email")}
          />
        )}
      </DialogContent>
      <DialogActions>
        {type === "employee" ? (
          <Button onClick={() => handleEmployeeSubmit()}>Send</Button>
        ) : (
          <Button onClick={() => handleEmployerSubmit()}>Send</Button>
        )}
        <Button color="secondary" onClick={popDialog}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
