import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";
import { useReceiptForm } from "../../core/hooks/useReceiptForm";
import {
  Container,
  Box,
  Paper,
  Grid,
  TextField,
  Divider,
  useMediaQuery,
  Theme,
  Autocomplete,
} from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";
import { AdaptiveButton } from "../../components/adaptive-button";
import { TimePicker, InputFile } from "../../components/fields";
import { FilePreview } from "../../components/file-preview";
import { IconSave, IconDate } from "../../components/icons";
import { PageHeader } from "../../components/page-header";
import { Space } from "../../components/space";
import { useSnackbar } from "../../core/hooks/useSnackbar";
import { useDialog } from "../../core/hooks/useDialog";
import { ConfirmDialog } from "../../components/dialogs/ConfirmDialog";
import { SaveActionDialog } from "./SaveActionDialog";
import useStyles from "./styles";
import {
  useGetTypesOfActivityQuery,
  useGetVendorsQuery,
  useGetEmployeesQuery,
  useCreateTemplateMutation,
  useGetMeQuery,
} from "../../http/api";
import { get, isEmpty, map } from "lodash";
import { useType } from "../../core/hooks/useType";
import {
  ROUTE_SPENDS_INDEX,
  ROUTE_SPENDS_TEMPLATES_INDEX,
} from "../../constants/route.constants";
import { url } from "../../core/utils/route.utils";
import { format } from "date-fns";
import { useUploadFileMutation } from "../../http/api/file.api";

export const SpendsCreate = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [createTemplate] = useCreateTemplateMutation({});
  const { data: vendorsData } = useGetVendorsQuery({});
  const { data: typesOfActivityData } = useGetTypesOfActivityQuery({});
  const { data: employeeData } = useGetEmployeesQuery({});
  const { type } = useType();
  const { data: userData } = useGetMeQuery({});
  const [uploadFile] = useUploadFileMutation({});
  const {
    getFieldProps,
    setFieldValue,
    values,
    handleSubmit,
    validateForm,
    errors,
    touched,
    setErrors,
    setTouched,
  } = useReceiptForm({
    id: undefined,
    file: file || undefined,
    onSuccess: () => {
      showMessage("Reciept was sent to approval successfully", "success");
      navigate(url(ROUTE_SPENDS_INDEX, { type }));
    },
    onError: (message) => {
      showMessage(message, "error");
    },
  });

  useEffect(() => {
    setTimeout(() => {
      if (type === "employee") {
        setFieldValue("ownerId", userData?.id);
      }
    }, 100);
  }, [type, userData]);

  const { showMessage } = useSnackbar();
  const [openSaveActionDialog, popSaveActionDialog] =
    useDialog(SaveActionDialog);
  const [openCancelConfirm, popCancelConfirm] = useDialog(ConfirmDialog);
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const vendorsList = map(get(vendorsData, "items", []), (item) => ({
    value: item.guid,
    label: item.name,
  }));

  const employeesList = map(get(employeeData, "items", []), (item) => ({
    value: item.id,
    label: `${item.firstName} ${item.lastName}`,
  }));

  const typeOfActivityList = map(
    get(typesOfActivityData, "items", []),
    (item) => ({
      value: item.id,
      label: item.name,
    })
  );

  const handleSaveTemplate = async (name) => {
    const errors = await validateForm();
    if (!isEmpty(errors)) {
      const touched = Object.keys(errors).reduce((acc, item) => {
        acc[item] = true;
        return acc;
      }, {});
      setTouched(touched);
      setErrors(errors);
      popSaveActionDialog();
      return;
    }

    const formattedValues = {
      ...values,
      employeeId: values.ownerId,
      amount: Number(values.amount),
      type: Number(values.type),
      spentAt: format(new Date(values.spentAt), "yyyy-MM-dd HH:mm:ss"),
      name,
      files: [],
    };

    if (file) {
      const guid = uuidv4();
      const formData = new FormData();
      formData.append("documentFile", file);
      formData.append("guid", guid);
      await uploadFile(formData);
      formattedValues.files = [guid];
    }

    const res = await createTemplate(formattedValues);
    const error = get(res, "error", null);
    if (error) {
      const message = get(error, "data.title", "Something went wrong.");
      showMessage(message, "error");
    } else {
      showMessage("Template was created successfully", "success");
      popSaveActionDialog();
      navigate(url(ROUTE_SPENDS_TEMPLATES_INDEX, { type }));
    }
  };

  return (
    <>
      <PageHeader title="Add new receipt" backTitle="New receipts">
        <AdaptiveButton
          color="primary"
          startIcon={<IconSave />}
          onClick={() =>
            openSaveActionDialog({
              onSave: handleSubmit,
              onSaveAndApprove: () => {
                setFieldValue("onApproval", true);
                handleSubmit();
              },
              onSaveTemplate: handleSaveTemplate,
            })
          }
        >
          Save
        </AdaptiveButton>
        <AdaptiveButton
          color="third"
          onClick={() => {
            openCancelConfirm({
              message:
                "Do you really want to cancel the creation of the receipt? All changes will not saved.",
              onConfirm: () => {
                navigate(url(ROUTE_SPENDS_INDEX, { type }));
                popCancelConfirm();
              },
            });
          }}
        >
          Cancel
        </AdaptiveButton>
      </PageHeader>
      <Container>
        <Box component={Paper} className={classes.paper} mt={3} mb={5}>
          <Grid container spacing={isMobile ? 0 : 3}>
            <Grid item xs={12} md={3} lg={2}>
              <DatePicker
                components={{
                  OpenPickerIcon: IconDate,
                }}
                label="Date"
                {...getFieldProps("spentAt")}
                renderInput={(params) => {
                  params.inputProps.placeholder = "MMDDYY";
                  return <TextField {...params} />;
                }}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <TimePicker
                value={values.spentAt}
                label="Time"
                onChange={(newDate) => setFieldValue("spentAt", newDate)}
              />
            </Grid>
            {type === "employer" && (
              <Grid item xs={12} md={6} lg={4}>
                <Autocomplete
                  placeholder="Select"
                  disablePortal
                  id="combo-box-demo"
                  options={employeesList}
                  onChange={(_, value: any) => {
                    setFieldValue("ownerId", value.value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select"
                      label="Employees"
                      error={!!errors["ownerId"] && !!touched["ownerId"]}
                      helperText={
                        errors ? touched["ownerId"] && errors["ownerId"] : ""
                      }
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={isMobile ? 0 : 3}>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                select
                label="Type"
                {...getFieldProps("type")}
                placeholder="Select"
              >
                <option value="">Select</option>
                {typeOfActivityList.map((item) => (
                  <option
                    value={item.value}
                    key={`type-of-activity-${item.value}`}
                  >
                    {item.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6} lg={4} sx={isMobile && { marginTop: 2 }}>
              <Autocomplete
                placeholder="Select"
                disablePortal
                id="combo-box-demo"
                options={vendorsList}
                onChange={(_, value: any) => {
                  setFieldValue("vendorGuid", value.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select"
                    label="Organization"
                    error={errors["vendorGuid"] && touched["vendorGuid"]}
                    helperText={
                      errors
                        ? touched["vendorGuid"] && errors["vendorGuid"]
                        : ""
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={isMobile ? 0 : 3}>
            <Grid item xs={12} md={12} lg={4}>
              <TextField
                className={classes.numericInput}
                label="Amount"
                {...getFieldProps("amount")}
                type="number"
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{ marginTop: isMobile ? 2 : 3 }}
            >
              {file ? (
                <FilePreview
                  fileName={file.name}
                  fileSize={file.size}
                  onRemove={() => setFile(null)}
                />
              ) : (
                <InputFile
                  label="Files:"
                  {...getFieldProps("files")}
                  setFile={setFile}
                />
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                label="Description"
                {...getFieldProps("description")}
                multiline
                rows={5}
              />
            </Grid>
          </Grid>
          <Divider />
          <Space spacing={1} pt={3}>
            <AdaptiveButton
              color="primary"
              startIcon={<IconSave />}
              onClick={() =>
                openSaveActionDialog({
                  onSave: handleSubmit,
                  onSaveAndApprove: () => {
                    setFieldValue("onApproval", true);
                    handleSubmit();
                  },
                  onSaveTemplate: handleSaveTemplate,
                })
              }
            >
              Save
            </AdaptiveButton>
            <AdaptiveButton
              color="third"
              onClick={() => {
                openCancelConfirm({
                  message:
                    "Do you really want to cancel the creation of the receipt? All changes will not saved.",
                  onConfirm: () => {
                    navigate(url(ROUTE_SPENDS_INDEX, { type }));
                    popCancelConfirm();
                  },
                });
              }}
            >
              Cancel
            </AdaptiveButton>
          </Space>
        </Box>
      </Container>
    </>
  );
};
