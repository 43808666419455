import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "time-picker",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    timeSuffix: {
      paddingRight: "10px",
    },
    timePickerContainer: {
      position: "relative",
    },
    downUp: {
      position: "absolute",
      right: 0,
      top: "32px",
      cursor: "pointer",
    },
  }),
  options
);

export default useStyles;
