import React, { ComponentType } from "react";
import { AuthLayoutWithBackground } from "./auth-layout";

export const withAuthLayoutWithBackground =
  (ComposedComponent: ComponentType) => (props: any) => {
    return (
      <AuthLayoutWithBackground>
        <ComposedComponent {...props} />
      </AuthLayoutWithBackground>
    );
  };
