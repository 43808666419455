import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../core/store/rtk.utils";
import {
  GET_ME,
  CHANGE_EMAIL,
  CHANGE_EMPLOYER_EMAIL,
  BLOCK_USER,
  EDIT_USER,
} from "./api.routes";

interface IGetMeResponse {
  id: number;
  firstName: string;
  lastName: string;
  avatar?: {
    guid: string;
    id: number;
    link: string;
    name: string;
    size: number;
  } | null;
  createdAt: string;
  lastLogin: string;
  organizationGuid: number | null;
  roles: Array<string>;
  userName: string;
  status?: string;
}

interface IChangeEmailRequest {
  email: string;
}
interface IBlockUserRequest {
  id: string;
}

interface IEditUserRequest {
  id: string;
  avatarGuid: string;
}

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getMe: builder.query<IGetMeResponse, any>({
      query: (params) => ({
        url: GET_ME,
        method: "GET",
        params,
      }),
    }),
    changeEmail: builder.mutation<IChangeEmailRequest, any>({
      query: (params) => ({
        url: CHANGE_EMAIL,
        method: "POST",
        body: params,
      }),
    }),
    changeEmployerEmail: builder.mutation<IChangeEmailRequest, any>({
      query: (params) => ({
        url: CHANGE_EMPLOYER_EMAIL,
        method: "POST",
        body: params,
      }),
    }),
    blockUser: builder.mutation<IBlockUserRequest, any>({
      query: (params) => ({
        url: BLOCK_USER,
        method: "POST",
        body: { id: params.id },
      }),
    }),
    editUser: builder.mutation<IEditUserRequest, any>({
      query: (params) => ({
        url: EDIT_USER,
        method: "PUT",
        body: params,
      }),
    }),
  }),
});

export const {
  useLazyGetMeQuery,
  useGetMeQuery,
  useChangeEmailMutation,
  useChangeEmployerEmailMutation,
  useBlockUserMutation,
  useEditUserMutation,
} = userApi;
