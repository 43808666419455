import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../core/store/rtk.utils";
import { INVITE_USERS } from "./api.routes";

export const invitationApi = createApi({
  reducerPath: "invitationApi",
  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    inviteUsers: builder.mutation<any, any>({
      query: (params) => ({
        url: INVITE_USERS,
        method: "POST",
        body: params,
      }),
    }),
  }),
});

export const { useInviteUsersMutation } = invitationApi;
