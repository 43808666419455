import { ComponentType } from "react";
import Typography from "@mui/material/Typography";

interface Props {
  amount: number;
}

export const AmountView: ComponentType<Props> = ({ amount }) => {
  const dollars = Math.trunc(amount);
  const cents = Math.round((amount * 100) % 100) || "00";

  return (
    <Typography variant="body3" component="span">
      <b>${dollars}</b>.{cents}
    </Typography>
  );
};
