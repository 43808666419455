import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "empty-state",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      width: "100%",
      padding: theme.spacing(5),
      marginTop: theme.spacing(3),
    },
    loading: {
      backgroundColor: theme.palette.background.light,
      borderColor: theme.palette.background.light,
      "&>div": {
        minHeight: 257,
      },
    },
    icon: {
      marginBlock: theme.spacing(2.5),
      "& svg": {
        fontSize: 38,
        width: 56,
        height: 56,
        color: "#BEC1D8",
      },
    },
    action: {
      marginTop: theme.spacing(1.5),
    },
  }),
  options
);

export default useStyles;
