import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../core/store/rtk.utils";
import { GET_TYPES_OF_BUSINESS } from "./api.routes";
import { IGetListResponse } from "../../types";

interface ITypeOfBusiness {
  amount: number;
  countComments: number;
  description: string;
  guid: string;
  id: number;
  spentAt: string;
  type: string | null;
  vendor_name: string;
}

interface ITypesOfBusiness extends IGetListResponse {
  items: Array<ITypeOfBusiness>;
}

export const typeOfBusinessApi = createApi({
  reducerPath: "typeOfBusinessApi",
  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getTypesOfBusiness: builder.query<ITypesOfBusiness, any>({
      query: (params) => ({
        url: GET_TYPES_OF_BUSINESS,
        method: "GET",
        params,
      }),
    }),
  }),
});

export const { useGetTypesOfBusinessQuery } = typeOfBusinessApi;
