import React, { useState } from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  Popover,
  useMediaQuery,
  Theme,
} from "@mui/material";
import StaticDateRangePicker from "@mui/lab/StaticDateRangePicker";
import CalendarPicker from "@mui/lab/CalendarPicker";
import { RadioGroup } from "../radio-group";
import { IconCalendar } from "../../icons";
import useStyles from "./styles";

export const radioItems = [
  {
    label: "Date",
    value: "date",
  },
  {
    label: "Period",
    value: "period",
  },
];

export const DatePicker = ({
  date,
  setDate,
  dualMode = false,
  defaultPeriod = "period",
}) => {
  const [type, setType] = useState(defaultPeriod);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSingleDate = (date) => {
    setDate([date, null]);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const classes = useStyles();

  return (
    <>
      <Button
        className={classes.root}
        fullWidth={isMobile}
        size="small"
        color="secondary"
        endIcon={<IconCalendar />}
        onClick={handleClick}
      >
        Select period
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={10}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box pt={3} px={3.75}>
          {dualMode && (
            <Stack alignItems="center" mb={1}>
              <RadioGroup
                items={radioItems}
                defaultValue={type}
                onChange={setType}
              />
            </Stack>
          )}
          <Box className={classes.calendar}>
            {type === "period" ? (
              <StaticDateRangePicker
                inputFormat="MM-DD-YY"
                displayStaticWrapperAs="desktop"
                value={date}
                onChange={setDate}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <TextField {...startProps} />
                    <Box sx={{ mx: 2 }}> to </Box>
                    <TextField {...endProps} />
                  </React.Fragment>
                )}
              />
            ) : (
              <CalendarPicker date={date[0]} onChange={handleSingleDate} />
            )}
          </Box>
        </Box>
      </Popover>
    </>
  );
};
