import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "footer",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      backgroundColor: theme.palette.common.white,
      borderTop: `1px solid ${theme.palette.secondary.main}`,
    },
    developedBy: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  }),
  options
);

export default useStyles;
