import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../core/store/rtk.utils";
import {
  CREATE_RECEIPT,
  GET_RECEIPTS,
  GET_STATISTICS,
  GET_RECEIPT,
  UPDATE_RECEIPT,
  APPROVE_RECEIPT,
  DECLINE_RECEIPT,
  SEND_RECEIPTS_ON_APPROVAL,
  MARK_RECEIPT_AS_PERSONAL,
  DELETE_RECEIPT,
  ARCHIVE_RECEIPT,
  EXPORT_RECEIPTS,
} from "./api.routes";
import { IGetListResponse } from "../../types";
import { url } from "../../core/utils/route.utils";

interface IReceipt {
  amount: number;
  countComments: number;
  description: string;
  guid: string;
  id: number;
  spentAt: string;
  type: string | null;
  vendor_name: string;
}

interface IReceipts extends IGetListResponse {
  items: Array<IReceipt>;
}

interface ICreateReceiptRequest {
  guid: string;
  type: string;
  description: string;
  spentAt: string;
  amount: number;
  vendorGuid: string;
  files: Array<any>;
}

interface IGetStatisticsResponse {
  newAmount: number;
  approvedAmount: number;
  declinedAmount: number;
  totalAmount: number;
  newPercent: number;
  approvedPercent: number;
  declinedPercent: number;
  totalPercent: number;
}
interface IApproveReceiptRequest {
  guid: string;
  amount: number;
}

interface IUpdateReceiptRequest {
  userId: number;
  guid: string;
  description: string;
  vendorGuid: string;
  type: string;
  status: string;
  spentAt: string;
  amount: number;
  files: Array<string>;
}

interface IDeclineReceiptRequest {
  guid: string;
}

interface IReceiptChangeStatusRequest {
  ids: Array<number>;
}

interface IReceiptExportRequest {
  dateFrom: string;
  dateTo: string;
  memberIds: Array<number>;
  exportAllMembers: boolean;
  typeIds: Array<number>;
  exportAllTypes: boolean;
}

export const receiptApi = createApi({
  reducerPath: "receiptApi",
  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getReceipts: builder.query<IReceipts, any>({
      query: (params) => ({
        url: url(GET_RECEIPTS, { userId: params.userId }),
        method: "GET",
        params: params.status
          ? {
              "filter[r.status][in]": params.status,
              search: params.search,
              page: params.page,
              ...params.formattedSortingState,
            }
          : { page: params.page, ...params.formattedSortingState },
      }),
    }),
    createReceipt: builder.mutation<ICreateReceiptRequest, any>({
      query: (params) => ({
        url: CREATE_RECEIPT,
        method: "POST",
        body: params,
      }),
    }),
    getStatistics: builder.query<IGetStatisticsResponse, any>({
      query: (params) => ({
        url: url(GET_STATISTICS, {
          dateFrom: params.dateFrom,
          dateTo: params.dateTo,
        }),
        method: "GET",
      }),
    }),
    getReceipt: builder.query<any, any>({
      query: (params) => ({
        url: url(GET_RECEIPT, { userId: params.userId, id: params.id }),
        method: "GET",
      }),
    }),
    updateReceipt: builder.mutation<IUpdateReceiptRequest, any>({
      query: (params) => ({
        url: url(UPDATE_RECEIPT, { id: params.guid }),
        method: "PUT",
        body: params,
      }),
    }),
    approveReceipt: builder.mutation<IApproveReceiptRequest, any>({
      query: (params) => ({
        url: APPROVE_RECEIPT,
        method: "POST",
        body: params,
      }),
    }),
    declineReceipt: builder.mutation<IDeclineReceiptRequest, any>({
      query: (params) => ({
        url: DECLINE_RECEIPT,
        method: "POST",
        body: params,
      }),
    }),
    sendReceiptsOnApproval: builder.mutation<IReceiptChangeStatusRequest, any>({
      query: (params) => ({
        url: SEND_RECEIPTS_ON_APPROVAL,
        method: "POST",
        body: params,
      }),
    }),
    markReceiptAsPersonal: builder.mutation<IReceiptChangeStatusRequest, any>({
      query: (params) => ({
        url: MARK_RECEIPT_AS_PERSONAL,
        method: "POST",
        body: params,
      }),
    }),
    deleteReceipt: builder.mutation<IDeclineReceiptRequest, any>({
      query: (params) => ({
        url: DELETE_RECEIPT,
        method: "POST",
        body: params,
      }),
    }),
    archiveReceipt: builder.mutation<IReceiptChangeStatusRequest, any>({
      query: (params) => ({
        url: ARCHIVE_RECEIPT,
        method: "POST",
        body: params,
      }),
    }),
    exportReceipt: builder.mutation<IReceiptExportRequest, any>({
      query: (params) => ({
        url: EXPORT_RECEIPTS,
        method: "POST",
        body: params,
      }),
    }),
  }),
});

export const {
  useGetReceiptsQuery,
  useCreateReceiptMutation,
  useLazyGetStatisticsQuery,
  useGetReceiptQuery,
  useUpdateReceiptMutation,
  useApproveReceiptMutation,
  useDeclineReceiptMutation,
  useSendReceiptsOnApprovalMutation,
  useMarkReceiptAsPersonalMutation,
  useDeleteReceiptMutation,
  useArchiveReceiptMutation,
  useExportReceiptMutation,
} = receiptApi;
