import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";

import {
  Container,
  Grid,
  Box,
  TextField,
  Paper,
  Theme,
  useMediaQuery,
  Stack,
  Typography,
} from "@mui/material";
import { ROUTE_SPENDS_TEMPLATES_INDEX } from "../../constants/route.constants";
import { url } from "../../core/utils/route.utils";
import { AdaptiveButton } from "../../components/adaptive-button";
import { IconFolder, IconSave, IconTrash } from "../../components/icons";
import { PageHeader } from "../../components/page-header";
import { LabeledRecord } from "../../components/labeled-record";
import { InputFile } from "../../components/fields";
import { FilePreview } from "../../components/file-preview";
import { useDialog } from "../../core/hooks/useDialog";
import { ConfirmDialog } from "../../components/dialogs/ConfirmDialog";
import { useType } from "../../core/hooks/useType";
import {
  useDeleteTemplateMutation,
  useGetMeQuery,
  useGetTemplatesQuery,
  useGetTypesOfActivityQuery,
} from "../../http/api";
import { useEditTemplateForm } from "../../core/hooks/useEditTemplateForm";
import { useSnackbar } from "../../core/hooks/useSnackbar";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
} from "../../constants/date.constants";
import { get, map } from "lodash";
import { Badge } from "../../components/badge";
import { FileContainer } from "../../components/files-container";

export const SpendsTemplatesEdit = () => {
  const { data: userData } = useGetMeQuery({});
  const { id } = useParams();
  const [openConfirmDialog, popConfirmDialog] = useDialog(ConfirmDialog);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const [deleteTemplate] = useDeleteTemplateMutation({});
  const { refetch } = useGetTemplatesQuery({
    userId: userData?.id || 0,
  });

  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const { type } = useType();
  const { showMessage } = useSnackbar();
  const { data: typesOfActivityData } = useGetTypesOfActivityQuery({});
  const typeOfActivityList = map(
    get(typesOfActivityData, "items", []),
    (item) => ({
      value: item.id,
      label: item.name,
    })
  );
  const { handleSubmit, getFieldProps, data } = useEditTemplateForm({
    id,
    guid: undefined,
    userId: userData?.id,
    onSuccess: () => {
      showMessage("Reciept was updated successfully", "success");
      refetch();
      navigate(url(ROUTE_SPENDS_TEMPLATES_INDEX, { type }));
    },
    onError: (message) => {
      showMessage(message, "error");
    },
  });

  useEffect(() => {
    if (data?.files?.length) {
      setFile(data?.files[0]);
    }
  }, [data]);

  return (
    <>
      <PageHeader
        title="Template information"
        backTitle="Templates"
        buttonsOrder={isMobile ? "column" : "row"}
      >
        {!isMobile && (
          <Stack direction="row" spacing={1}>
            {type === "employer" && (
              <AdaptiveButton
                startIcon={<IconSave />}
                onClick={() => handleSubmit()}
              >
                Save
              </AdaptiveButton>
            )}
            <AdaptiveButton
              color="error"
              startIcon={<IconTrash />}
              onClick={() => {
                openConfirmDialog({
                  message: "Do you really want to delete this template?",
                  onConfirm: async () => {
                    const res = await deleteTemplate({
                      guid: data.guid,
                    });
                    const error = get(res, "error", null);
                    if (error) {
                      const message = get(
                        error,
                        "data.title",
                        "Something went wrong."
                      );
                      showMessage(message, "error");
                    } else {
                      showMessage(
                        "Receipts was sent on approval successfully",
                        "success"
                      );
                      navigate(url(ROUTE_SPENDS_TEMPLATES_INDEX, { type }));
                      popConfirmDialog();
                    }
                  },
                });
              }}
            >
              Remove
            </AdaptiveButton>
          </Stack>
        )}
      </PageHeader>

      <Container>
        <Box py={3}>
          <Grid container spacing={1.25}>
            <Grid item xs={12} md={12} lg={6}>
              <Box component={Paper} p={isMobile ? 2 : 4} pb={1}>
                <Grid container>
                  <Grid item md={4} xs={12}>
                    <LabeledRecord label="ID:" {...getFieldProps("id")} />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <LabeledRecord
                      label="Organization:"
                      value={data?.vendor_name}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item md={12} xs={12}>
                    {type === "employer" && (
                      <LabeledRecord
                        label="Employee:"
                        value={data?.employeeName}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item md={12} xs={12}>
                    {type === "employer" ? (
                      <TextField
                        label="Description"
                        multiline
                        rows={isMobile ? 3 : 6}
                        {...getFieldProps("description")}
                      />
                    ) : (
                      <LabeledRecord
                        label="Description"
                        value={data?.description}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid container mt={2}>
                  {type === "employee" ? (
                    <Grid item md={12} xs={12} mb={3}>
                      <Typography
                        variant="body2"
                        color="common.grey"
                        component="div"
                      >
                        Type:
                      </Typography>
                      <Badge label={data?.type} />
                    </Grid>
                  ) : (
                    <Grid item md={6} xs={12}>
                      <TextField
                        select
                        label="Type"
                        placeholder="Select"
                        {...getFieldProps("type")}
                      >
                        <option key="empty" value="empty">
                          Select
                        </option>
                        {typeOfActivityList.map((item) => (
                          <option
                            value={item.value}
                            key={`type-of-activity-${item.value}`}
                            selected={item.value === data?.typeId}
                          >
                            {item.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  )}
                </Grid>
                <Grid container mt={2}>
                  <Grid item md={4} xs={12}>
                    <LabeledRecord
                      label="Date:"
                      value={
                        data?.spentAt
                          ? format(new Date(data.spentAt), DEFAULT_DATE_FORMAT)
                          : "-"
                      }
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <LabeledRecord
                      label="Time:"
                      value={
                        data?.spentAt
                          ? format(new Date(data.spentAt), DEFAULT_TIME_FORMAT)
                          : "-"
                      }
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <LabeledRecord
                      label="Amount:"
                      {...getFieldProps("amount")}
                    />
                  </Grid>
                </Grid>

                <Grid container>
                  {type === "employee" ? (
                    <Grid item md={12} xs={12}>
                      {file ? (
                        <FilePreview
                          fileName={file.name}
                          fileSize={file?.size}
                          onRemove={() => setFile(null)}
                        />
                      ) : (
                        <InputFile
                          label="Files:"
                          setFile={(file) => setFile(file)}
                        />
                      )}
                    </Grid>
                  ) : (
                    file && (
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="body2"
                          color="common.grey"
                          component="div"
                        >
                          File:
                        </Typography>
                        <FileContainer files={data?.files} />
                      </Grid>
                    )
                  )}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {isMobile && (
          <Stack direction="row" spacing={1}>
            {type === "employer" && (
              <AdaptiveButton
                startIcon={<IconSave />}
                onClick={() => handleSubmit()}
              >
                Save
              </AdaptiveButton>
            )}
            <AdaptiveButton
              color="error"
              startIcon={<IconTrash />}
              onClick={() => {
                openConfirmDialog({
                  message: "Do you really want to delete this template?",
                  onConfirm: async () => {
                    const res = await deleteTemplate({
                      guid: data.guid,
                    });
                    const error = get(res, "error", null);
                    if (error) {
                      const message = get(
                        error,
                        "data.title",
                        "Something went wrong."
                      );
                      showMessage(message, "error");
                    } else {
                      showMessage(
                        "Receipts was sent on approval successfully",
                        "success"
                      );
                      navigate(url(ROUTE_SPENDS_TEMPLATES_INDEX, { type }));
                      popConfirmDialog();
                    }
                  },
                });
              }}
            >
              Remove
            </AdaptiveButton>
          </Stack>
        )}
      </Container>
    </>
  );
};
