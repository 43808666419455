import React, { useEffect } from "react";
import { Navigate, Route, Routes as RouterRoutes } from "react-router-dom";

import { useLazyGetMeQuery } from "./http/api";
import {
  ROUTE_HOME,
  ROUTE_SPENDS_CREATE,
  ROUTE_SPENDS_TEMPLATE_CREATE,
  ROUTE_SPENDS_EDIT,
  ROUTE_SPENDS_INDEX,
  ROUTE_SPENDS_TEMPLATE_EDIT,
  ROUTE_SPENDS_TEMPLATES_INDEX,
  ROUTE_PROFILE,
  ROUTE_NOTIFICATIONS,
  ROUTE_SIGN_IN,
  ROUTE_SIGN_UP,
  ROUTE_AUTH_REDIRECT,
  ROUTE_SIGN_IN_RESULT,
  ROUTE_MAIL_ACCESS,
  ROUTE_FILL_COMPANY_INFO,
  ROUTE_INVITE_EMPLOYEES,
  ROUTE_SUCCESS_INVITES,
  ROUTE_EMPLOYEES_LIST,
  ROUTE_EMPLOYEE_DETAILS,
  ROUTE_EMPLOYEE_DETAILS_TAB,
  ROUTE_EMPLOYEE_CREATE,
  ROUTE_SPENDS_COMMENTS,
} from "./constants/route.constants";
import {
  SpendsPage,
  SpendsEditPage,
  SpendsCreatePage,
  SpendsCommentsPage,
  SpendsCreateTemplatePage,
  SpendsTemplateEditPage,
  SpendsTemplatesPage,
  ProfilePage,
  NotificationsPage,
  SignInPage,
  SignUpPage,
  AuthRedirectPage,
  SignInResultPage,
  MailAccessPage,
  FillCompanyInfoPage,
  InviteEmployeesPage,
  SuccessInvitesPage,
  EmployeesListPage,
  EmployeeDetailsPage,
  EmployeeCreatePage,
  NotFoundPage,
} from "./pages";
import { AUTH_TOKEN } from "./constants/app.constants";
import { url } from "./core/utils/route.utils";
import { ProtectedRoute } from "./components/protected-route";

const Routes = () => {
  const cachedType = localStorage.getItem("type");
  return (
    <RouterRoutes>
      <Route
        path={ROUTE_HOME}
        element={
          <Navigate
            to={url(ROUTE_SIGN_IN, { type: cachedType || "employer" })}
            replace
          />
        }
      />
      <Route path={ROUTE_SIGN_IN} element={<SignInPage />} />
      <Route path={ROUTE_AUTH_REDIRECT} element={<AuthRedirectPage />} />
      <Route path={ROUTE_SIGN_IN_RESULT} element={<SignInResultPage />} />
      <Route path={ROUTE_SIGN_UP} element={<SignUpPage />} />
      <Route path="/" element={<ProtectedRoute />}>
        <Route path={ROUTE_MAIL_ACCESS} element={<MailAccessPage />} />
        <Route
          path={ROUTE_FILL_COMPANY_INFO}
          element={<FillCompanyInfoPage />}
        />
        <Route
          path={ROUTE_INVITE_EMPLOYEES}
          element={<InviteEmployeesPage />}
        />
        <Route path={ROUTE_SUCCESS_INVITES} element={<SuccessInvitesPage />} />
        <Route path={ROUTE_PROFILE} element={<ProfilePage />} />
        <Route path={ROUTE_NOTIFICATIONS} element={<NotificationsPage />} />
        <Route path={ROUTE_SPENDS_INDEX} element={<SpendsPage />} />
        <Route path={ROUTE_SPENDS_EDIT} element={<SpendsEditPage />} />
        <Route path={ROUTE_SPENDS_CREATE} element={<SpendsCreatePage />} />
        <Route path={ROUTE_SPENDS_COMMENTS} element={<SpendsCommentsPage />} />
        <Route path={ROUTE_EMPLOYEES_LIST} element={<EmployeesListPage />} />
        <Route path={ROUTE_EMPLOYEE_CREATE} element={<EmployeeCreatePage />} />
        <Route
          path={ROUTE_EMPLOYEE_DETAILS}
          element={<EmployeeDetailsPage />}
        />
        <Route
          path={ROUTE_EMPLOYEE_DETAILS_TAB}
          element={<EmployeeDetailsPage />}
        />
        <Route
          path={ROUTE_SPENDS_TEMPLATE_CREATE}
          element={<SpendsCreateTemplatePage />}
        />
        <Route
          path={ROUTE_SPENDS_TEMPLATE_EDIT}
          element={<SpendsTemplateEditPage />}
        />
        <Route
          path={ROUTE_SPENDS_TEMPLATES_INDEX}
          element={<SpendsTemplatesPage />}
        />
        <Route
          path={ROUTE_SPENDS_TEMPLATES_INDEX}
          element={<SpendsTemplatesPage />}
        />
      </Route>

      <Route path="*" element={<NotFoundPage />} />
    </RouterRoutes>
  );
};

function App() {
  const [trigger] = useLazyGetMeQuery({});
  useEffect(() => {
    if (localStorage.getItem(AUTH_TOKEN)) {
      trigger({});
    }
  }, [trigger]);

  return <Routes />;
}

export default App;
