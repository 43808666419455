import { DataTableColumn } from "../../components/table-view/TableView";
import { Badge } from "../../components/badge";
import { Typography, Box } from "@mui/material";
import { AmountView } from "../../components/amount-view";
import { LinkButton } from "../../components/buttons/LinkButton";
import { url } from "../../core/utils/route.utils";
import { ROUTE_SPENDS_EDIT } from "../../constants/route.constants";
import get from "lodash/get";
import { spendsStatusBadgeLabel } from "../../constants/statuses.constants";

const receiptsTypeBadge = {
  Food: "warning",
  Dinner: "success",
};

const receiptsStatusBadge = {
  Approved: "success",
  Declined: "error",
};

export const receiptsColumn: DataTableColumn[] = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    sortable: true,
  },
  {
    title: "STATUS",
    dataIndex: "status",
    key: "status",
    sortable: true,
    cellProps: {
      sx: {
        whiteSpace: "nowrap",
      },
    },
    render: (text) => {
      if (text) {
        return (
          <Badge
            label={spendsStatusBadgeLabel(text)}
            variant={get(receiptsStatusBadge, text, "primary")}
          />
        );
      }
    },
  },
  {
    title: "Organization",
    dataIndex: "vendorName",
    key: "vendorName",
    cellProps: {
      sx: {
        whiteSpace: "nowrap",
      },
    },
  },
  {
    title: "EMPLOYEE",
    dataIndex: "employee",
    key: "employee",
    sortable: true,
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Comments",
    dataIndex: "comments",
    key: "comments",
    sortable: true,
    render: (text) => {
      if (text) {
        return text;
      }
      return "-";
    },
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    sortable: true,
    cellProps: {
      sx: {
        whiteSpace: "nowrap",
      },
    },
    render: (text) => {
      if (text) {
        return (
          <Badge
            label={text}
            variant={get(receiptsTypeBadge, text, "primary")}
          />
        );
      }
      return (
        <LinkButton
          color="common.info"
          underline="always"
          to={url(ROUTE_SPENDS_EDIT, { id: "new" })}
        >
          Add type
        </LinkButton>
      );
    },
  },
  {
    title: "Date",
    dataIndex: "createdAt",
    key: "createdAt",
    sortable: true,
    cellProps: {
      sx: {
        whiteSpace: "nowrap",
      },
    },
  },
  {
    title: "Time",
    dataIndex: "time",
    key: "time",
    sortable: true,
    cellProps: {
      sx: {
        whiteSpace: "nowrap",
      },
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    sortable: true,
    cellProps: {
      align: "right",
    },
    render: (amount) => <AmountView amount={amount} />,
  },
];

export const employeesColumn: DataTableColumn[] = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text, { firstName, lastName }) => (
      <Typography variant={"body2"} sx={{ fontWeight: 500 }}>
        {`${firstName} ${lastName}`}
      </Typography>
    ),
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    render: (text) => (
      <Typography variant={"body2"} sx={{ fontWeight: 500 }}>
        {text}
      </Typography>
    ),
  },
  {
    title: "All Receipts",
    dataIndex: "allReceipts",
    key: "allReceipts",
    sortable: true,
    render: (text, { receiptsCount }) => (
      <Typography variant={"body2"} sx={{ fontWeight: 500 }}>
        {receiptsCount}
      </Typography>
    ),
  },

  {
    title: "Approved",
    dataIndex: "approved",
    key: "approved",
    sortable: true,
    render: (text, { receiptsApprovedCount }) => (
      <Typography variant={"body2"} sx={{ fontWeight: 500 }}>
        {receiptsApprovedCount}
      </Typography>
    ),
  },
];

export const employeesData = [
  {
    id: 1,
    name: "Jenny Wilson",
    email: "Big Kahuna Burger Ltd.",
    allReceipts: "23 / $328.85",
    approved: "23 / $328.85",
  },
  {
    id: 2,
    name: "Jenny Wilson",
    email: "Big Kahuna Burger Ltd.",
    allReceipts: "23 / $328.85",
    approved: "23 / $328.85",
  },
  {
    id: 3,
    name: "Jenny Wilson",
    email: "Big Kahuna Burger Ltd.",
    allReceipts: "23 / $328.85",
    approved: "23 / $328.85",
  },
  {
    id: 4,
    name: "Jenny Wilson",
    email: "Big Kahuna Burger Ltd.",
    allReceipts: "23 / $328.85",
    approved: "23 / $328.85",
  },
  {
    id: 5,
    name: "Jenny Wilson",
    email: "Big Kahuna Burger Ltd.",
    allReceipts: "23 / $328.85",
    approved: "23 / $328.85",
  },
  {
    id: 6,
    name: "Jenny Wilson",
    email: "Big Kahuna Burger Ltd.",
    allReceipts: "23 / $328.85",
    approved: "23 / $328.85",
  },
  {
    id: 7,
    name: "Jenny Wilson",
    email: "Big Kahuna Burger Ltd.",
    allReceipts: "23 / $328.85",
    approved: "23 / $328.85",
  },
  {
    id: 8,
    name: "Jenny Wilson",
    email: "Big Kahuna Burger Ltd.",
    allReceipts: "23 / $328.85",
    approved: "23 / $328.85",
  },
  {
    id: 9,
    name: "Jenny Wilson",
    email: "Big Kahuna Burger Ltd.",
    allReceipts: "23 / $328.85",
    approved: "23 / $328.85",
  },
  {
    id: 10,
    name: "Jenny Wilson",
    email: "Big Kahuna Burger Ltd.",
    allReceipts: "23 / $328.85",
    approved: "23 / $328.85",
  },
  {
    id: 11,
    name: "Jenny Wilson",
    email: "Big Kahuna Burger Ltd.",
    allReceipts: "23 / $328.85",
    approved: "23 / $328.85",
  },
  {
    id: 12,
    name: "Jenny Wilson",
    email: "Big Kahuna Burger Ltd.",
    allReceipts: "23 / $328.85",
    approved: "23 / $328.85",
  },
  {
    id: 13,
    name: "Jenny Wilson",
    email: "Big Kahuna Burger Ltd.",
    allReceipts: "23 / $328.85",
    approved: "23 / $328.85",
  },
  {
    id: 14,
    name: "Jenny Wilson",
    email: "Big Kahuna Burger Ltd.",
    allReceipts: "23 / $328.85",
    approved: "23 / $328.85",
  },
  {
    id: 15,
    name: "Jenny Wilson",
    email: "Big Kahuna Burger Ltd.",
    allReceipts: "23 / $328.85",
    approved: "23 / $328.85",
  },
];

export const receiptsData = [
  {
    id: 6215954,
    status: "Approved",
    organization: "Big Kahuna Burger Ltd.",
    employee: "Esther Howard",
    description:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint... ",
    comments: 1,
    type: "Food",
    date: "1/15/21",
    time: "04:15 am",
    amount: "32885",
  },
  {
    id: 6215944,
    status: "Approved",
    organization: "Big Kahuna Burger Ltd.",
    employee: "Esther Howard",
    description:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint... ",
    comments: 3,
    type: "Food",
    date: "1/15/21",
    time: "04:15 am",
    amount: "32885",
  },
  {
    id: 6211954,
    status: "Approved",
    organization: "Big Kahuna Burger Ltd.",
    employee: "Esther Howard",
    description: "",
    comments: 6,
    type: "Dinner",
    date: "1/15/21",
    time: "04:15 am",
    amount: "32885",
  },
  {
    id: 6315954,
    status: "Declined",
    organization: "Big Kahuna Burger Ltd.",
    employee: "Esther Howard",
    description:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint... ",
    comments: null,
    type: "Dinner",
    date: "1/15/21",
    time: "04:15 am",
    amount: "32885",
  },
  {
    id: 62159654,
    status: "Approved",
    organization: "Big Kahuna Burger Ltd.",
    employee: "Esther Howard",
    description:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint... ",
    comments: 4,
    type: "Food",
    date: "1/15/21",
    time: "04:15 am",
    amount: "32885",
  },
  {
    id: 62154,
    status: "Declined",
    organization: "Big Kahuna Burger Ltd.",
    employee: "Esther Howard",
    description: "",
    comments: 2,
    type: "Food",
    date: "1/15/21",
    time: "04:15 am",
    amount: "32885",
  },
  {
    id: 6215754,
    status: "Approved",
    organization: "Big Kahuna Burger Ltd.",
    employee: "Esther Howard",
    description:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint... ",
    comments: 12,
    type: "Food",
    date: "1/15/21",
    time: "04:15 am",
    amount: "32885",
  },
  {
    id: 1215954,
    status: "Declined",
    organization: "Big Kahuna Burger Ltd.",
    employee: "Esther Howard",
    description:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint... ",
    comments: 1,
    type: "Office goods",
    date: "1/15/21",
    time: "04:15 am",
    amount: "32885",
  },
  {
    id: 6715954,
    status: "Approved",
    organization: "Big Kahuna Burger Ltd.",
    employee: "Esther Howard",
    description: "",
    comments: 1,
    type: "Dinner",
    date: "1/15/21",
    time: "04:15 am",
    amount: "32885",
  },
  {
    id: 6295954,
    status: "Declined",
    organization: "Big Kahuna Burger Ltd.",
    employee: "Esther Howard",
    description:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint... ",
    comments: 1,
    type: "Dinner with client",
    date: "1/15/21",
    time: "04:15 am",
    amount: "32885",
  },
  {
    id: 62100954,
    status: "Declined",
    organization: "Big Kahuna Burger Ltd.",
    employee: "Esther Howard",
    description:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint... ",
    comments: 1,
    type: "Food",
    date: "1/15/21",
    time: "04:15 am",
    amount: "32885",
  },
  {
    id: 62152954,
    status: "Declined",
    organization: "Big Kahuna Burger Ltd.",
    employee: "Esther Howard",
    description:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint... ",
    comments: 1,
    type: "Food",
    date: "1/15/21",
    time: "04:15 am",
    amount: "32885",
  },
  {
    id: 62215954,
    status: "Approved",
    organization: "Big Kahuna Burger Ltd.",
    description: "",
    comments: 1,
    type: "Dinner with client",
    date: "1/15/21",
    time: "04:15 am",
    amount: "32885",
  },
  {
    id: 26215954,
    status: "Declined",
    organization: "Big Kahuna Burger Ltd.",
    description:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint... ",
    comments: 1,
    type: "Food",
    date: "1/15/21",
    time: "04:15 am",
    amount: "32885",
  },
  {
    id: 62159542,
    status: "Approved",
    organization: "Big Kahuna Burger Ltd.",
    description:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint... ",
    comments: 1,
    type: "Food",
    date: "1/15/21",
    time: "04:15 am",
    amount: "32885",
  },
];

export const receiptsColumnMobile: DataTableColumn[] = [
  {
    title: "Select ALL",
    dataIndex: "item",
    key: "item",
    render: (text, { organization, employee, date, amount }) => {
      if (text === "SELECT ALL") {
        return null;
      }
      return (
        <Box>
          <Box>
            <Typography
              mr={1}
              component={"span"}
              mb={3}
              variant="body2"
              color="common.grey"
            >
              Organization:
            </Typography>
            {organization}
          </Box>
          <Box>
            <Typography
              mr={1}
              component={"span"}
              mb={3}
              variant="body2"
              color="common.grey"
            >
              Employee:
            </Typography>
            {employee}
          </Box>
          <Box>
            <Typography
              mr={1}
              component={"span"}
              mb={3}
              variant="body2"
              color="common.grey"
            >
              Date:
            </Typography>
            {date}
          </Box>
          <Box>
            <Typography
              mr={1}
              component={"span"}
              mb={3}
              variant="body2"
              color="common.grey"
            >
              Amount:
            </Typography>
            {amount}
          </Box>
        </Box>
      );
    },
  },
];
