import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "snackbar",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: "fixed",
      top: 24,
      left: "50%",
      transform: "translateX(-50%)",
      zIndex: 9999,

      "& .MuiPaper-root": {
        borderRadius: 36,
        boxShadow: "0px 4px 16px rgba(8, 77, 75, 0.12)",
        padding: theme.spacing(0, 2),
      },

      "& .MuiAlert-message": {
        fontSize: 14,
        color: "#183D62",
      },

      "& .MuiAlert-standardInfo": {
        backgroundColor: "#D9FFFE",

        "& .MuiSvgIcon-root": {
          color: "#12C8C5",
        },
      },

      "& .MuiAlert-standardError": {
        backgroundColor: "#FFEAEA",

        "& .MuiSvgIcon-root": {
          color: "#FE8E8E",
        },
      },

      "& .MuiAlert-standardSuccess": {
        backgroundColor: "#EAFFF5",

        "& .MuiSvgIcon-root": {
          color: "#6AE3A9",
        },
      },

      "& .MuiAlert-standardWarning": {
        backgroundColor: "#fceed0",

        "& .MuiSvgIcon-root": {
          color: "#fdd77b",
        },
      },
    },
  }),
  options
);

export default useStyles;
