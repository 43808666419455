import React from "react";
import { Link } from "react-router-dom";
import { Grid, Typography, Button, Box, Container } from "@mui/material";
import { MainLogo } from "../../../components/logos";
import { ROUTE_HOME } from "../../../constants/route.constants";
import { useStyles } from "./styles";

export const SuccessInvites = () => {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Box sx={{ textAlign: "center" }}>
        <Link to="/">
          <MainLogo />
        </Link>
        <Box mt={5}>
          <Typography variant="h1">Welcome to Rimshare</Typography>
        </Box>
        <Typography variant="body2">
          Your account was created successfully.
        </Typography>
      </Box>
      <Box className={classes.block} mt={5}>
        <Grid
          container
          xs={12}
          direction="column"
          sx={{ textAlign: "center" }}
          p={4}
        >
          <Grid item xs={12} mb={1}>
            <Typography variant="h2">
              All invitations were sent successfully
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              Members will received invitations on emails shortly.
            </Typography>
          </Grid>
          <Grid item xs={12} mt={3}>
            <Button component={Link} to={ROUTE_HOME}>
              Let’s start
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
