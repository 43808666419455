export const GET_ME = "/api/v1/user/me";

export const GET_EMPLOYEES = "/api/v1/employee/";
export const GET_USER = "/api/v1/user/:id";
export const BLOCK_USER = "/api/v1/user/block/";
export const CHANGE_EMAIL_APPROVE = "/api/v1/employee/:id/change-email/accept";
export const CHANGE_EMAIL_DECLINE = "/api/v1/employee/:id/change-email/decline";
export const EDIT_USER = "/api/v1/user/";

export const CHANGE_EMAIL = "/api/v1/employee/change-email";
export const CHANGE_EMPLOYER_EMAIL = "/api/v1/user/email";

export const GET_RECEIPTS = "/api/v1/employee/:userId/receipts";
export const CREATE_RECEIPT = "/api/v1/receipt/";
export const GET_RECEIPT = "/api/v1/employee/:userId/receipts/:id";
export const UPDATE_RECEIPT = "/api/v1/receipt/:id";
export const APPROVE_RECEIPT = "/api/v1/receipt/approve";
export const DECLINE_RECEIPT = "/api/v1/receipt/decline";
export const SEND_RECEIPTS_ON_APPROVAL = "/api/v1/receipt/on-approval";
export const MARK_RECEIPT_AS_PERSONAL = "/api/v1/receipt/mark-as-personal";
export const DELETE_RECEIPT = "/api/v1/receipt/delete";
export const ARCHIVE_RECEIPT = "/api/v1/receipt/archived";

export const GET_TEMPLATES = "/api/v1/template/";
export const GET_TEMPLATE = "/api/v1/template/:id";
export const CREATE_TEMPLATE = "/api/v1/template/";
export const UPDATE_TEMPLATE = "/api/v1/template/:id";
export const DELETE_TEMPLATE = "/api/v1/template/:id";

export const GET_COMMENTS = "/api/v1/receipt/:receiptGuid/comment/";
export const SEND_COMMENT = "/api/v1/receipt/:receiptGuid/comment/";

export const GET_STATISTICS = "/api/v1/statistics/:dateFrom/:dateTo/";

export const GET_VENDORS = "/api/v1/vendor/";

export const GET_TYPES_OF_ACTIVITY = "/api/v1/type-of-activity/";

export const GET_TYPES_OF_BUSINESS = "/api/v1/type_of_business/";

export const CREATE_ORGANIZATION = "/api/v1/organization/";

export const INVITE_USERS = "api/v1/invitation/";

export const GET_NOTIFICATIONS = "/api/v1/notification/";
export const GET_NOTIFICATIONS_COUNT = "/api/v1/notification/count";
export const MARK_NOTIFICATION_AS_READ = "/api/v1/notification/mark-as-read";

export const UPLOAD_FILE = "/api/v1/files/uploadFile";

export const EXPORT_RECEIPTS = "/api/v1/export-to-csv";
