import { useState, useCallback } from "react";
import get from "lodash/get";

export type SortingDirection = "asc" | "desc" | null;

export type SortingState = Record<string, SortingDirection>;

export const useTableSorting = () => {
  const [sortingState, setSortingState] = useState<SortingState>({});

  const toggleDirection = (direction?: SortingDirection) => {
    if (direction === "asc") {
      return "desc";
    } else if (direction === "desc") {
      return null;
    } else {
      return "asc";
    }
  };

  const sort = useCallback((column: string) => {
    setSortingState((prevState) => {
      const currentColumnDirection = get(prevState, column);

      return {
        ...prevState,
        [column]: toggleDirection(currentColumnDirection),
      };
    });
  }, []);

  return {
    sortingState,
    sort,
  };
};
