import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "badge",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      fontSize: 12,
      textTransform: "uppercase",
      padding: theme.spacing(0.55, 1.1),
      backgroundColor: "#CEF9FF",
      borderRadius: 8,
    },
    link: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "24px",
      display: "flex",
      alignItems: "center",
      textDecoration: "underline",
      color: "#12C8C5",
    },
  }),
  options
);

export default useStyles;
