import * as yup from "yup";
import { useNavigate, Link } from "react-router-dom";
import { Typography, Button, Box, Container } from "@mui/material";

import { useInvitationForm } from "../../../core/hooks/useInvitationForm";
import { MainLogo } from "../../../components/logos";
import { useStyles } from "./styles";
import { url } from "../../../core/utils/route.utils";
import {
  ROUTE_SUCCESS_INVITES,
  ROUTE_HOME,
} from "../../../constants/route.constants";
import { useSnackbar } from "../../../core/hooks/useSnackbar";
import { useType } from "../../../core/hooks/useType";
import { MultiSelect } from "../../../components/fields/multi-select";
import { useState } from "react";

const schema = yup.object({
  email: yup.string().email().required(),
});

export const InviteEmployees = () => {
  const [text, setText] = useState("");
  const classes = useStyles();
  const navigate = useNavigate();
  const { type } = useType();
  const { showMessage } = useSnackbar();
  const { setFieldValue, values, handleSubmit } = useInvitationForm({
    onSuccess: () => {
      showMessage("Invitation was sent successfully", "success");
      navigate(url(ROUTE_SUCCESS_INVITES, { type }));
    },
    onError: (message) => {
      showMessage(message, "error");
    },
  });

  return (
    <Container className={classes.root}>
      <Box sx={{ textAlign: "center" }}>
        <Link to="/">
          <MainLogo />
        </Link>
        <Box mt={5}>
          <Typography variant="h1">Welcome to Rimshare!</Typography>
        </Box>
        <Box mt={1}>
          <Typography variant="body2">
            Your account was created successfully.
          </Typography>
        </Box>
      </Box>
      <Box className={classes.block} mt={5}>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h2">Invite your employees</Typography>
          <Box mt={1}>
            <Typography variant="body2">Enter member’s email bellow</Typography>
          </Box>
        </Box>
        <Box className={classes.inputContainer} mt={3}>
          <MultiSelect
            options={[]}
            onChange={async (arr) => {
              setText("");
              try {
                for (const email of arr) {
                  await schema.validate({ email: email });
                }
                setFieldValue("emails", arr);
              } catch (e) {
                showMessage("Not valid email", "error");
              }
            }}
            onBlur={async (event) => {
              if (event.target.value) {
                try {
                  await schema.validate({ email: event.target.value });
                  setFieldValue("emails", [
                    ...values.emails,
                    event.target.value,
                  ]);
                } catch (e) {
                  showMessage("Not valid email", "error");
                }
                setText("");
              }
            }}
            value={values.emails}
            text={text}
            setText={setText}
            freeSolo
            saveOnBlur
          />
        </Box>
        <Box mt={3} className={classes.inputContainer}>
          <Button
            fullWidth
            onClick={() => handleSubmit()}
            disabled={!values.emails.length}
          >
            Invite
          </Button>
        </Box>
      </Box>
      <Box>
        <Link to={url(ROUTE_HOME)} className={classes.link}>
          I’ll do it later
        </Link>
      </Box>
    </Container>
  );
};
