import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { endOfDay, startOfDay, sub } from "date-fns";
import {
  Box,
  Container,
  Grid,
  Theme,
  useMediaQuery,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import { PageHeader } from "../../components/page-header";
import { useStatisticsForm } from "../../core/hooks/useStatisticsForm";
import { useSnackbar } from "../../core/hooks/useSnackbar";
import {
  IconApprovedReceipts,
  IconDeclinedReceipts,
  IconFolder,
  IconNewReceipts,
  IconPlus,
  IconTotalReceipts,
} from "../../components/icons";
import { Record } from "../../components/record";
import { Tabs } from "../../components/tabs";
import { AdaptiveButton } from "../../components/adaptive-button";
import { DatePicker, RadioGroup } from "../../components/fields";
import { url } from "../../core/utils/route.utils";
import {
  ROUTE_SPENDS_CREATE,
  ROUTE_SPENDS_INDEX,
  ROUTE_SPENDS_TEMPLATES_INDEX,
} from "../../constants/route.constants";
import { SpendsApproved } from "./SpendsApproved";
import { SpendsNewReceipts } from "./SpendsNewReceipts";
import { SpendsOnApproval } from "./SpendsOnApproval";
import { SpendsHistory } from "./SpendsHistory";
import { EmptyState } from "../../components/empty-state";
import { filterItems } from "../../core/columns/receipts.columns";
import { useType } from "../../core/hooks/useType";
import useStyles from "./spendsStyles";

export const SpendsList = () => {
  const { hash } = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();
  const { type } = useType();
  const [periodDate, setPeriodDate] = React.useState<any>([null, null]);
  const [period, setPeriod] = React.useState<string>("month");
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { showMessage } = useSnackbar();

  const { setFieldValue, handleSubmit, result } = useStatisticsForm({
    onError: (message) => {
      showMessage(message, "error");
    },
  });
  const handleChangeDate = (value: string) => {
    setPeriod(value);
    let val = 1;
    switch (value) {
      case "month":
        val = 1;
        break;
      case "3_month":
        val = 3;
        break;
      case "6_month":
        val = 6;
        break;
      case "total":
        val = 99;
        break;
      default:
        val = 1;
        break;
    }
    setFieldValue("dateFrom", sub(startOfDay(new Date()), { months: val }));
    setFieldValue("dateTo", endOfDay(new Date()));
    handleSubmit();
  };

  useEffect(() => {
    if (periodDate[0] && periodDate[1]) {
      setPeriod("");
      setFieldValue("dateFrom", startOfDay(periodDate[0]));
      setFieldValue("dateTo", endOfDay(periodDate[1]));
      handleSubmit();
    }
  }, [periodDate]);

  return (
    <>
      <PageHeader title="Spends">
        <AdaptiveButton
          component={Link}
          to={url(ROUTE_SPENDS_TEMPLATES_INDEX, { type })}
          color="third"
          startIcon={<IconFolder />}
        >
          {!isMobile && "Receipt templates"}
        </AdaptiveButton>
        <AdaptiveButton
          component={Link}
          to={url(ROUTE_SPENDS_CREATE, { type })}
          startIcon={<IconPlus />}
        >
          {!isMobile && "Add new receipt"}
        </AdaptiveButton>
      </PageHeader>
      <Container className={classes.root}>
        <Box py={3}>
          <Grid
            container
            sx={{ justifyContent: "space-between" }}
            mb={3}
            spacing={2}
          >
            <Grid item xs={6}>
              {!isMobile ? (
                <RadioGroup
                  items={filterItems}
                  value={period}
                  onChange={(value) => {
                    handleChangeDate(value);
                  }}
                />
              ) : (
                <TextField
                  select
                  placeholder="Month"
                  helperText=""
                  onChange={(event) => handleChangeDate(event.target.value)}
                >
                  {filterItems.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </TextField>
              )}
            </Grid>
            <Grid item xs={6} md={6} justifyContent="flex-end" display="flex">
              <DatePicker date={periodDate} setDate={setPeriodDate} />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6} md={6} lg={3}>
              <Record
                percent={result?.data?.newPercent}
                percentVariant={"down"}
                amount={result?.data?.newAmount}
                title="New receipts"
                icon={<IconNewReceipts />}
                color="info"
                isLoading={result?.isLoading}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={3}>
              <Record
                percent={result?.data?.approvedPercent}
                amount={result?.data?.approvedAmount}
                title="Approved"
                icon={<IconApprovedReceipts />}
                color="success"
                isLoading={result?.isLoading}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={3}>
              <Record
                percent={result?.data?.declinedPercent}
                amount={result?.data?.declinedAmount}
                title="Declined"
                icon={<IconDeclinedReceipts />}
                color="error"
                isLoading={result?.isLoading}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={3}>
              <Record
                percent={result?.data?.totalPercent}
                percentVariant={"down"}
                amount={result?.data?.totalAmount}
                title="Total amount"
                icon={<IconTotalReceipts />}
                isLoading={result?.isLoading}
              />
            </Grid>
          </Grid>
          <Typography
            component={Box}
            mt={1.5}
            variant="body2"
            color="common.grey"
          >
            * % - Changes vs previous period
          </Typography>
          <Tabs
            onChange={(key) => {
              navigate(url(ROUTE_SPENDS_INDEX + `#${key}`, { type }));
            }}
            items={
              type === "employer"
                ? [
                    {
                      key: "new_receipts",
                      label: "New receipts",
                      content:
                        hash === "#loading" || hash === "#empty" ? (
                          <EmptyState
                            icon={<IconNewReceipts />}
                            description="You have no new receipts"
                            loading={hash === "#loading"}
                            action={
                              <Button startIcon={<IconPlus />}>
                                Add new receipt
                              </Button>
                            }
                          />
                        ) : (
                          <SpendsNewReceipts />
                        ),
                    },
                    {
                      key: "approved",
                      label: "Approved",
                      content: <SpendsApproved variant="approved" />,
                    },
                    {
                      key: "declined",
                      label: "Declined",
                      content: <SpendsApproved variant="declined" />,
                    },
                    {
                      key: "history",
                      label: "History",
                      content: <SpendsHistory />,
                    },
                  ]
                : [
                    {
                      key: "new_receipts",
                      label: "New receipts",
                      content:
                        hash === "#loading" || hash === "#empty" ? (
                          <EmptyState
                            icon={<IconNewReceipts />}
                            description="You have no new receipts"
                            loading={hash === "#loading"}
                            action={
                              <Button startIcon={<IconPlus />}>
                                Add new receipt
                              </Button>
                            }
                          />
                        ) : (
                          <SpendsNewReceipts />
                        ),
                    },
                    {
                      key: "on_approval",
                      label: "On Approval",
                      content: <SpendsOnApproval />,
                    },
                    {
                      key: "history",
                      label: "History",
                      content: <SpendsHistory />,
                    },
                  ]
            }
          />
        </Box>
      </Container>
    </>
  );
};
