import { ComponentType, ReactNode } from "react";
import { Drawer as MuiDrawer } from "@mui/material";
import useStyles from "./styles";

interface Props {
  children: ReactNode;
  isOpen: boolean;
}

export const Drawer: ComponentType<Props> = ({ children, isOpen = false }) => {
  const classes = useStyles();

  return (
    <MuiDrawer
      hideBackdrop
      ModalProps={{
        disableScrollLock: true,
        disableEscapeKeyDown: true,
      }}
      open={isOpen}
      anchor="bottom"
      className={classes.drawer}
    >
      {children}
    </MuiDrawer>
  );
};
