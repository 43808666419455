import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  commentsRoot: {
    position: "absolute",
    height: "100%",
  },
  exportActionDialog: {
    "& .MuiPaper-root": {
      maxWidth: "unset",
      width: "fit-content",
    },
  },

  [theme.breakpoints.down("lg")]: {
    exportActionDialog: {
      "& .MuiPaper-root": {
        maxWidth: "640px",
      },
    },
  },
  [theme.breakpoints.down("md")]: {
    exportActionDialog: {
      "& .MuiPaper-root": {
        margin: theme.spacing(1.8),
      },
    },
    saveActionDialog: {
      "& .MuiPaper-root": {
        maxWidth: "320px !important",
        minHeight: "316px !important",
      },
    },
    monthSelect: {
      "& select": {
        height: "36px !important",
        boxSizing: "border-box",
      },
    },
    paper: {
      padding: theme.spacing(1.8),
    },
  },
  [theme.breakpoints.up("md")]: {
    paper: {
      padding: theme.spacing(3),
    },
  },
  [theme.breakpoints.up("lg")]: {
    paper: {
      padding: theme.spacing(4.5),
    },
  },
  drawer: {
    padding: theme.spacing(3),
  },
  radioGroup: {
    paddingTop: "0 !important",
  },
  numericInput: {
    "& input": {
      margin: 0,
      "&::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        "-moz-appearance": "textfield",
      },
      "&::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        "-moz-appearance": "textfield",
      },
    },
  },
}));

export default useStyles;
