import { Link, useParams } from "react-router-dom";
import { Box, Typography, useMediaQuery, Theme, Grid } from "@mui/material";
import { AuthLogo } from "../../../components/logos";
import { GoogleButton } from "../../../components/buttons";
import { url } from "../../../core/utils/route.utils";
import { ROUTE_SIGN_IN } from "../../../constants/route.constants";
import useStyles from "./styles";
import { ENDPOINT_ROOT } from "../../../constants/app.constants";

export const SignUp = () => {
  const { type } = useParams();
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const handleAuth = () => {
    window.location.href = `${ENDPOINT_ROOT}/${type}/google/connect`;
  };
  return (
    <Box className={classes.root}>
      <Box mb={5}>
        <AuthLogo />
      </Box>
      <Box className={classes.block}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant={isMobile ? "h3" : "h1"} textAlign="center">
              Sign up
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" align="center">
              Sign up with a Google account and allow access to your email to
              recognize receipts.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <GoogleButton onClick={() => handleAuth()}>
              Sign Up with Google
            </GoogleButton>
          </Grid>
        </Grid>
      </Box>
      <Typography variant="body1" component="span">
        I have an account.{" "}
        <Link to={url(ROUTE_SIGN_IN, { type })} className={classes.link}>
          Sign in
        </Link>
      </Typography>
    </Box>
  );
};
