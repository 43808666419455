import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "mail-access",
};

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    wrapper: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      block: {
        width: "100%",
        maxWidth: "unset !important",
      },
    },
    [theme.breakpoints.up("md")]: {},
    block: {
      marginTop: theme.spacing(5),
      maxWidth: 352,
      background: theme.palette.common.white,
      boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.06)",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(4),
      marginBottom: theme.spacing(5),
      borderRadius: "8px",
    },
    description: {
      letterSpacing: 0,
    },
    navigationStack: {
      marginTop: 8,
      width: "100%",
    },
    link: {
      color: theme.palette.common.info,
      textDecoration: "none",
    },
  }),
  options
);
