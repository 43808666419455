import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "badge",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      width: "100%",
      display: "inline-flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      fontSize: 16,
      fontWeight: 500,
      padding: theme.spacing(2, 2.1),
      backgroundColor: "#CEF9FF",
      borderRadius: 6,
      marginTop: theme.spacing(1.5),
      "& svg": {
        marginRight: theme.spacing(1.25),
      },
    },
    primary: {
      backgroundColor: "#CEF9FF",
    },
    error: {
      backgroundColor: "#FEEFEF",
    },
  }),
  options
);

export default useStyles;
