import { ComponentType, createContext } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
import { breakpoints } from "./breakpoints";
import { themeComponents } from "./components";
import { lightPalette } from "./palettes";
import { shadows } from "./shadows";
import { shape } from "./shape";
import { typography } from "./typography";

const lightTheme = createTheme({
  palette: lightPalette,
  typography,
  shape,
  shadows,
  breakpoints,
  components: themeComponents(lightPalette),
});

export const ThemeContext = createContext({});

export const ThemeProvider: ComponentType = ({ children }) => {
  const contextValue = {};

  return (
    <ThemeContext.Provider value={contextValue}>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={lightTheme}>
          {children}
          <CssBaseline />
        </MuiThemeProvider>
      </StyledEngineProvider>
    </ThemeContext.Provider>
  );
};
