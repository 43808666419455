import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Stack,
  Theme,
  useMediaQuery,
  Paper,
  TextField,
  Divider,
} from "@mui/material";
import { PageHeader } from "../../components/page-header";
import { IconPlus } from "../../components/icons";
import { AdaptiveButton } from "../../components/adaptive-button";
import { ROUTE_EMPLOYEES_LIST } from "../../constants/route.constants";
import { url } from "../../core/utils/route.utils";
import { useType } from "../../core/hooks/useType";
import { useInvitationForm } from "../../core/hooks/useInvitationForm";
import { useSnackbar } from "../../core/hooks/useSnackbar";

export const EmployeeCreate = () => {
  const navigate = useNavigate();
  const { type } = useType();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { showMessage } = useSnackbar();

  const { getFieldProps, handleSubmit } = useInvitationForm({
    onSuccess: () => {
      showMessage("Invitation was sent successfully", "success");
      navigate(url(ROUTE_EMPLOYEES_LIST, { type }));
    },
    onError: (message) => {
      showMessage(message, "error");
    },
  });
  return (
    <>
      <PageHeader
        title="Add new Employee"
        backTitle="Employees"
        buttonsOrder={isMobile ? "column" : "row"}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{
            marginTop: { xs: 2, lg: 0, md: 0 },
          }}
        >
          <AdaptiveButton startIcon={<IconPlus />} onClick={handleSubmit}>
            Add & send invitation
          </AdaptiveButton>
          <AdaptiveButton
            component={Link}
            color="third"
            to={url(ROUTE_EMPLOYEES_LIST, { type })}
          >
            Cancel
          </AdaptiveButton>
        </Stack>
      </PageHeader>
      <Box py={3}>
        <Container>
          <Box component={Paper} p={isMobile ? 2 : 4} my={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={8}>
                <TextField select label="Role">
                  <option key="employee" value="Employee">
                    Employee
                  </option>
                </TextField>
              </Grid>
            </Grid>
            <Grid container spacing={isMobile ? 0 : 3}>
              <Grid item md={6} xs={12} lg={4}>
                <TextField label="Email" {...getFieldProps("emails")} />
              </Grid>
            </Grid>
            <Divider sx={{ mb: 3 }} />
            <Stack spacing={1} direction="row" alignItems="center">
              <AdaptiveButton startIcon={<IconPlus />} onClick={handleSubmit}>
                Add & send invitation
              </AdaptiveButton>
              <AdaptiveButton
                color="secondary"
                component={Link}
                to={url(ROUTE_EMPLOYEES_LIST, { type })}
              >
                Cancel
              </AdaptiveButton>
            </Stack>
          </Box>
        </Container>
      </Box>
    </>
  );
};
