import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "multi-select",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      "& .MuiOutlinedInput-root": {
        padding: "8px",
      },
    },
    formControl: {
      "& input": {
        fontSize: 12,
        padding: 0,
        "&::placeholder": {
          fontSize: 12,
        },
      },
      "& .MuiButtonBase-root": {
        margin: 1,
        height: "unset",
      },
      "& .MuiSvgIcon-root": {
        fontSize: 20,
      },
    },
  }),
  options
);

export default useStyles;
