import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "input-file",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: "flex",
      padding: theme.spacing(2),
      border: "1px dashed #E7EAF9",
      backgroundColor: "#FBFBFF",
      borderRadius: 8,
      justifyContent: "center",
    },
    label: {
      color: "#6C7294",
      marginBottom: theme.spacing(0.5),
    },
    container: {
      display: "flex",
      textAlign: "center",
      flexDirection: "column",
      alignItems: "center",
    },
    icon: {
      marginBottom: theme.spacing(1),
    },
    text: {
      fontSize: 14,
      color: "#131730",
      marginTop: theme.spacing(1),
    },
  }),
  options
);

export default useStyles;
