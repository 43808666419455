import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "auth-layout",
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    display: "flex",
    backgroundColor: theme.palette.background.auth,
    backgroundSize: "cover",
    width: "100%",
  },
  rootWithBackground: {
    flex: 1,
    display: "flex",
    background: "url(/assets/images/auth-bg.png) no-repeat 50% 50%",
    backgroundSize: "cover",
    width: "100%",
    padding: "11.5px",
  },
  options,
}));

export default useStyles;
