import React, { ComponentType } from "react";
import TableCell from "@mui/material/TableCell";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import clsx from "clsx";
import { DataTableColumn } from "../TableView";
import { IconSorting, IconFiltering } from "../../icons";
import { SortingDirection } from "../useTableSorting";
import useStyles from "./styles";

interface Props {
  column: DataTableColumn;
  sort: (column: string) => void;
  sortDirection: SortingDirection;

  filteredValue: any;
  filter: (column: string, value: string) => void;
  clearFilter: (column: string) => void;
  right?: boolean;
}

export const TableViewHeaderCell: ComponentType<Props> = ({
  column,
  sort,
  sortDirection,
  filteredValue,
  filter,
  clearFilter,
  right,
}) => {
  const { sortable, key: columnKey, filters } = column;
  const classes = useStyles();
  const filterable = filters !== undefined;
  const anchorElRef = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState(false);

  const handlePopover = (status: boolean) => () => {
    setOpen(status);
  };

  const handleFilter = (value: string) => () => {
    if (filterable) {
      filter(columnKey, value);
    }
  };

  const handleClearFilter = () => {
    if (filterable) {
      clearFilter(columnKey);
    }
  };

  return (
    <>
      <TableCell
        ref={anchorElRef}
        className={clsx(classes.cellRoot, {
          [classes.cellRootClickable]: filterable,
          [classes.cellRootSelected]:
            (sortable && sortDirection !== null) || filteredValue !== null,
          [classes.cellRootSortableAsc]: sortable && sortDirection === "asc",
          [classes.cellRootSortableDesc]: sortable && sortDirection === "desc",
          [classes.cellRootRightAlign]: right,
        })}
        {...column.cellProps}
      >
        <Box className={classes.cellContent}>
          <span>{column.title}</span>
          {sortable && (
            <IconButton
              className={classes.sortingIcon}
              size="small"
              onClick={() => {
                if (sortable) {
                  sort(column.key);
                }
              }}
            >
              <IconSorting />
            </IconButton>
          )}
          {filterable && (
            <IconButton
              className={classes.filteringIcon}
              size="small"
              onClick={handlePopover(true)}
            >
              <IconFiltering />
            </IconButton>
          )}
        </Box>
      </TableCell>
      {filterable && (
        <Popover
          className={classes.popover}
          id={columnKey}
          open={open}
          anchorEl={anchorElRef.current}
          onClose={handlePopover(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box py={0.5}>
            <Box
              className={clsx(classes.filterItem, classes.filterClearAll)}
              onClick={handleClearFilter}
            >
              <Typography variant="body2">Clear all</Typography>
            </Box>
            {filters?.map((filter, index) => (
              <Box
                key={columnKey + index}
                className={clsx(classes.filterItem, {
                  [classes.filterItemSelected]:
                    filteredValue !== undefined &&
                    filter.value === filteredValue,
                })}
                onClick={handleFilter(filter.value)}
              >
                <Typography variant="body2">{filter.label}</Typography>
              </Box>
            ))}
          </Box>
        </Popover>
      )}
    </>
  );
};
