import * as yup from "yup";
import { useFormik } from "formik";
import get from "lodash/get";
import { v4 as uuidv4 } from "uuid";

import { useGetReceiptQuery, useUpdateReceiptMutation } from "../../http/api";
import { format } from "date-fns";
import { useEffect } from "react";
import { useUploadFileMutation } from "../../http/api/file.api";

interface UseProps {
  guid: string;
  id: string;
  userId: number;
  file: File;
  onSuccess: () => void;
  onError: (message: string) => void;
}

export const useEditReceiptForm = ({
  guid,
  id,
  userId,
  file,
  onSuccess,
  onError,
}: UseProps) => {
  const { data, refetch } = useGetReceiptQuery({
    userId,
    id,
  });
  useEffect(() => {
    setValues({
      ...data,
      type: data?.typeId,
      userId: userId,
      amount: data?.amount,
    });
  }, [data]);

  const [updateReceipt] = useUpdateReceiptMutation({});
  const [uploadFile] = useUploadFileMutation({});

  const {
    setValues,
    handleSubmit,
    getFieldProps: formikGetFieldProps,
    setFieldValue,
    errors,
    values,
    touched,
    validateForm,
    setTouched,
    setErrors,
  } = useFormik({
    initialValues: {
      userId: userId,
      id: 0,
      guid: guid ? guid : uuidv4(),
      type: 0,
      description: "",
      spentAt: new Date(),
      amount: 0,
      vendorGuid: "",
      files: [],
    },
    validationSchema: yup.object().shape({
      userId: yup.number(),
      id: yup.string(),
      guid: yup.string(),
      type: yup.number().min(1),
      description: yup.string(),
      spentAt: yup.string(),
      amount: yup.number().min(1),
      vendorGuid: yup.string(),
    }),
    onSubmit: async (values) => {
      const formattedValues = {
        ...values,
        amount: Number(values.amount),
        type: Number(values.type),
        spentAt: format(new Date(values.spentAt), "yyyy-MM-dd HH:mm:ss"),
        files: values.files.map((file) => file.guid),
      };
      if (file) {
        const guid = uuidv4();
        const formData = new FormData();
        formData.append("documentFile", file);
        formData.append("guid", guid);
        await uploadFile(formData);
        formattedValues.files = [guid];
      }
      const res = await updateReceipt(formattedValues);
      const error = get(res, "error", null);
      if (error) {
        const message = get(error, "data.title", "Something went wrong.");
        onError(message);
      } else {
        onSuccess();
      }
    },
  });

  const getFieldProps = (fieldName) => {
    const error = errors[fieldName] && touched[fieldName];
    return {
      error,
      helperText: error ? errors[fieldName] : "",
      ...formikGetFieldProps(fieldName),
      onChange: (e) => {
        const targetValue = get(e, "target.value");
        if (targetValue || targetValue === "") {
          setFieldValue(fieldName, targetValue);
        } else {
          setFieldValue(fieldName, e);
        }
      },
    };
  };

  return {
    handleSubmit,
    errors,
    values,
    getFieldProps,
    setFieldValue,
    data,
    refetch,
    validateForm,
    setTouched,
    setErrors,
  };
};
