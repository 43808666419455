import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "file-preview",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: "flex",
      justifyContent: "space-between",
      borderRadius: 8,
      borderColor: "#E7EAF9",
      borderStyle: "dashed",
      flex: 1,
      alignItems: "center",
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    fileInfo: {
      flex: 1,
    },
    deleteBtn: {
      cursor: "pointer",
      "& svg": {},
    },
  }),
  options
);

export default useStyles;
