import clsx from "clsx";
import { Box, IconButton, Stack, TextField } from "@mui/material";
import { IconAttach, IconSend } from "../../icons";
import { FilePreview } from "../../file-preview";
import { ComponentType, useState } from "react";
import { useSendCommentForm } from "../../../core/hooks/useSendCommentForm";
import useStyles from "./styles";
import { useSnackbar } from "../../../core/hooks/useSnackbar";
import { useGetCommentsQuery } from "../../../http/api";

interface IProps {
  receiptGuid?: string;
}

export const Input: ComponentType<IProps> = ({ receiptGuid }) => {
  const classes = useStyles();
  const [file, setFile] = useState(null);

  const { refetch } = useGetCommentsQuery({ receiptGuid });

  const handleSelectFile = async (file) => {
    setFile(file);
  };
  const { showMessage } = useSnackbar();

  const { getFieldProps, handleSubmit } = useSendCommentForm({
    guid: receiptGuid,
    file: file || undefined,
    onSuccess: () => {
      setFile(null);
      refetch();
      showMessage("Your comment was sent successfully", "success");
    },
    onError: (message) => {
      showMessage(message, "error");
    },
  });

  return (
    <Box className={classes.root}>
      {file && (
        <FilePreview
          fileName={file.name}
          fileSize={file.size}
          onRemove={() => setFile(null)}
        />
      )}
      <Stack direction="row" spacing={1} mt={2}>
        <IconButton className={classes.icons} component="label">
          <IconAttach />
          <input
            type="file"
            hidden
            onChange={(event) =>
              event.target.files && handleSelectFile(event.target.files[0])
            }
          />
        </IconButton>
        <TextField
          placeholder="Message"
          helperText=""
          {...getFieldProps("text")}
        />
        <IconButton
          className={clsx(classes.icons, classes.sendButton)}
          color="primary"
          onClick={() => handleSubmit()}
        >
          <IconSend width={18} />
        </IconButton>
      </Stack>
    </Box>
  );
};
