import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  Paper,
  Grid,
  TextField,
  Divider,
  useMediaQuery,
  Theme,
  Autocomplete,
} from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";
import { AdaptiveButton } from "../../components/adaptive-button";
import { IconSave, IconDate } from "../../components/icons";
import { PageHeader } from "../../components/page-header";
import { InputFile, TimePicker } from "../../components/fields";
import { FilePreview } from "../../components/file-preview";
import { Space } from "../../components/space";
import { url } from "../../core/utils/route.utils";
import { ROUTE_SPENDS_TEMPLATES_INDEX } from "../../constants/route.constants";
import { useDialog } from "../../core/hooks/useDialog";
import { ConfirmDialog } from "../../components/dialogs/ConfirmDialog";
import { useType } from "../../core/hooks/useType";
import { useSnackbar } from "../../core/hooks/useSnackbar";
import { useTemplateForm } from "../../core/hooks/useTemplateForm";
import {
  useGetEmployeesQuery,
  useGetMeQuery,
  useGetTemplatesQuery,
  useGetTypesOfActivityQuery,
  useGetVendorsQuery,
} from "../../http/api";
import { get, map } from "lodash";
import useStyles from "./styles";

export const SpendsTemplateCreate = () => {
  const [openSaveConfirm, popSaveConfirm] = useDialog(ConfirmDialog);
  const { type } = useType();
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const classes = useStyles();
  const { showMessage } = useSnackbar();
  const { data: vendorsData } = useGetVendorsQuery({});
  const { data: typesOfActivityData } = useGetTypesOfActivityQuery({});
  const { data: employeeData } = useGetEmployeesQuery({});
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { data: userData } = useGetMeQuery({});

  const { refetch } = useGetTemplatesQuery({
    userId: userData?.id || 0,
  });

  const {
    getFieldProps,
    setFieldValue,
    values,
    handleSubmit,
    errors,
    touched,
  } = useTemplateForm({
    id: undefined,
    file: file || undefined,
    onSuccess: () => {
      showMessage("Template was created successfully", "success");
      navigate(
        url(`${ROUTE_SPENDS_TEMPLATES_INDEX}?refresh=${Math.random() * 100}`, {
          type,
        })
      );
      refetch();
    },
    onError: (message) => {
      showMessage(message, "error");
    },
  });

  console.log(errors);

  useEffect(() => {
    setTimeout(() => {
      if (type === "employee") {
        setFieldValue("ownerId", userData?.id);
      }
    }, 400);
  }, [type, userData]);

  const vendorsList = map(get(vendorsData, "items", []), (item) => ({
    value: item.guid,
    label: item.name,
  }));

  const employeesList = map(get(employeeData, "items", []), (item) => ({
    value: item.id,
    label: `${item.firstName} ${item.lastName}`,
  }));

  const typeOfActivityList = map(
    get(typesOfActivityData, "items", []),
    (item) => ({
      value: item.id,
      label: item.name,
    })
  );

  return (
    <>
      <PageHeader title="Add new template" backTitle="Templates">
        <AdaptiveButton
          color="primary"
          startIcon={<IconSave />}
          onClick={() => handleSubmit()}
        >
          Save
        </AdaptiveButton>
        <AdaptiveButton
          color="third"
          onClick={() => {
            openSaveConfirm({
              message:
                "Do you really want to cancel the creation of the template? All changes will not saved.",
              onConfirm: () => {
                navigate(url(ROUTE_SPENDS_TEMPLATES_INDEX, { type }));
                popSaveConfirm();
              },
            });
          }}
        >
          Cancel
        </AdaptiveButton>
      </PageHeader>
      <Container>
        <Box component={Paper} className={classes.paper} mt={3} mb={5}>
          <Grid container spacing={isMobile ? 0 : 3}>
            <Grid item xs={12} md={12} lg={4}>
              <TextField label="Name" {...getFieldProps("name")} type="text" />
            </Grid>
            <Grid item xs={12} md={3} lg={2} mt={isMobile ? 2 : 0}>
              <DatePicker
                components={{
                  OpenPickerIcon: IconDate,
                }}
                label="Date"
                {...getFieldProps("spentAt")}
                renderInput={(params) => {
                  params.inputProps.placeholder = "MMDDYY";
                  return <TextField {...params} />;
                }}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <TimePicker
                value={values.spentAt}
                label="Time"
                onChange={(newDate) => setFieldValue("spentAt", newDate)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={isMobile ? 0 : 3}>
            {type === "employer" && (
              <Grid item xs={12} md={6} lg={4}>
                <Autocomplete
                  placeholder="Select"
                  disablePortal
                  id="combo-box-demo"
                  options={employeesList}
                  onChange={(_, value: any) => {
                    setFieldValue("ownerId", value.value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select"
                      label="Employee"
                      error={!!errors["ownerId"] && !!touched["ownerId"]}
                      helperText={
                        errors ? touched["ownerId"] && errors["ownerId"] : ""
                      }
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={isMobile ? 0 : 3}>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                select
                label="Type"
                {...getFieldProps("type")}
                placeholder="Select"
              >
                <option value="">Select</option>
                {typeOfActivityList.map((item) => (
                  <option
                    value={item.value}
                    key={`type-of-activity-${item.value}`}
                  >
                    {item.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6} lg={4} mt={isMobile ? 2 : 0}>
              <Autocomplete
                placeholder="Select"
                disablePortal
                id="combo-box-demo"
                options={vendorsList}
                onChange={(_, value: any) => {
                  setFieldValue("vendorGuid", value.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select"
                    label="Organization"
                    error={errors["vendorGuid"] && touched["vendorGuid"]}
                    helperText={
                      errors
                        ? touched["vendorGuid"] && errors["vendorGuid"]
                        : ""
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={isMobile ? 0 : 3}>
            <Grid item xs={12} md={12} lg={4}>
              <TextField
                className={classes.numericInput}
                label="Amount"
                {...getFieldProps("amount")}
                type="number"
              />
            </Grid>
          </Grid>
          <Grid container mt={2}>
            <Grid item xs={12} md={12} lg={12}>
              {file ? (
                <FilePreview
                  fileName={file.name}
                  fileSize={file.size}
                  onRemove={() => setFile(null)}
                />
              ) : (
                <InputFile
                  label="Files:"
                  {...getFieldProps("files")}
                  setFile={setFile}
                />
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                label="Description"
                {...getFieldProps("description")}
                multiline
                rows={5}
              />
            </Grid>
          </Grid>
          <Divider />
          <Space spacing={1} pt={3}>
            <AdaptiveButton
              color="primary"
              startIcon={<IconSave />}
              onClick={() => handleSubmit()}
            >
              Save
            </AdaptiveButton>
            <AdaptiveButton
              color="third"
              onClick={() => {
                openSaveConfirm({
                  message:
                    "Do you really want to cancel the creation of the template? All changes will not saved.",
                  onConfirm: () => {
                    navigate(url(ROUTE_SPENDS_TEMPLATES_INDEX, { type }));
                    popSaveConfirm();
                  },
                });
              }}
            >
              Cancel
            </AdaptiveButton>
          </Space>
        </Box>
      </Container>
    </>
  );
};
