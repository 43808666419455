import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider as StoreProvider } from "react-redux";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "./core/theme/theme-provider";
import { DialogProvider } from "./core/providers/dialog.provider";
import { SnackbarProvider } from "./core/providers/snackbar";
import { store } from "./core/store";
import "reflect-metadata";
import { LocalizationProvider } from "@mui/lab";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <BrowserRouter>
            <DialogProvider>
              <SnackbarProvider>
                <App />
              </SnackbarProvider>
            </DialogProvider>
          </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
