import React, { ComponentType } from "react";
import { AuthLayoutWithFooter } from "./auth-layout";

export const withAuthLayoutWithFooter =
  (ComposedComponent: ComponentType) => (props: any) => {
    return (
      <AuthLayoutWithFooter>
        <ComposedComponent {...props} />
      </AuthLayoutWithFooter>
    );
  };
