import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetMeQuery } from "../../../http/api";
import {
  ROUTE_SPENDS_INDEX,
  ROUTE_FILL_COMPANY_INFO,
} from "../../../constants/route.constants";
import { useType } from "../../../core/hooks/useType";
import { url } from "../../../core/utils/route.utils";

export const SignInResult = () => {
  const { data } = useGetMeQuery({});
  const { type } = useType();
  const navigate = useNavigate();

  useEffect(() => {
    if (data && type) {
      if (data.roles.includes("ROLE_EMPLOYEE")) {
        navigate(url(ROUTE_SPENDS_INDEX, { type }));
      } else if (data.roles.includes("ROLE_EMPLOYER")) {
        if (!data.organizationGuid) {
          navigate(url(ROUTE_FILL_COMPANY_INFO, { type }));
        } else {
          navigate(url(ROUTE_SPENDS_INDEX, { type }));
        }
      }
    }
  }, [data, type]);

  return null;
};
