import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
  whiteHeader: {
    backgroundColor: "#fff",
    borderBottom: "1px solid #E7EAF9",
  },
  navLink: {
    fontSize: 14,
    fontWeight: 500,
    textDecoration: "none",
    color: theme.palette.common.dark,
    "& .MuiBox-root": {
      borderRadius: 40,
      marginLeft: 8,
    },
  },
  avatarWrapper: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    textAlign: "center",
    cursor: "pointer",
    width: "24px",
    height: "24px",
    fontSize: 12,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "50%",
  },
  iconWrapper: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    cursor: "pointer",
  },
  caretDown: {
    width: "10px",
  },
  burger: {
    width: "14px",
  },
  navLinkActive: {
    color: theme.palette.common.info,
    "& .MuiBox-root": {
      backgroundColor: "#B1FAFA",
      color: theme.palette.common.black,
    },
  },
  [theme.breakpoints.down("md")]: {},
}));

export default useStyles;
