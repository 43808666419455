import React, { ComponentType } from "react";
import { Box } from "@mui/material";
import { Header } from "../header";
import { Footer } from "../footer";

export const BaseLayout: ComponentType = ({ children }) => {
  return (
    <>
      <Header />
      <Box component="main" flex={2}>
        {children}
      </Box>
      <Footer />
    </>
  );
};
