import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../core/store/rtk.utils";
import { CREATE_ORGANIZATION } from "./api.routes";

interface ICreateOrganizationRequest {
  guid: string;
  typeOfBusinessGuid: string;
  name: string;
}

export const organizationApi = createApi({
  reducerPath: "organizationApi",
  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    createOrganization: builder.mutation<ICreateOrganizationRequest, any>({
      query: (params) => ({
        url: CREATE_ORGANIZATION,
        method: "POST",
        body: params,
      }),
    }),
  }),
});

export const { useCreateOrganizationMutation } = organizationApi;
