import React, { ComponentType } from "react";
import { useMediaQuery, Box, Theme, Stack } from "@mui/material";
import { AdaptiveButton } from "../adaptive-button";
import { IconWarning } from "../icons";
import {
  useChangeEmailApproveMutation,
  useChangeEmailDeclineMutation,
  useGetUserQuery,
} from "../../http/api";
import { useSnackbar } from "../../core/hooks/useSnackbar";

interface Props {
  id: string;
}

export const AlertMessage: ComponentType<Props> = ({ id }) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { data, refetch } = useGetUserQuery({ id });
  const [changeEmailApprove] = useChangeEmailApproveMutation({});
  const [changeEmailDecline] = useChangeEmailDeclineMutation({});
  const { showMessage } = useSnackbar();
  const handleChangeEmailApprove = async () => {
    await changeEmailApprove({ id });
    showMessage("Email was approved successfully", "success");
    refetch();
  };

  const handleChangeEmailDecline = async () => {
    await changeEmailDecline({ id });
    showMessage("Email was declined successfully", "success");
    refetch();
  };

  return (
    <Box
      sx={{
        border: "1px solid #F2C94C",
        background: "#fff",
        borderRadius: "8px",
        py: "32px",
        px: "26px",
        mb: 3,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <IconWarning />
        <span
          style={{
            marginLeft: "18px",
            marginBottom: isMobile ? "18px" : 0,
            fontSize: isMobile ? "12px" : "16px",
          }}
        >
          This user was requested changing email address to {data.newEmail}
        </span>
      </Box>
      <Stack spacing={1.2} direction="row" alignItems="center">
        <AdaptiveButton
          color="success"
          onClick={() => handleChangeEmailApprove()}
        >
          Accept
        </AdaptiveButton>
        <AdaptiveButton
          color="error"
          onClick={() => handleChangeEmailDecline()}
        >
          Decline
        </AdaptiveButton>
      </Stack>
    </Box>
  );
};
