import * as yup from "yup";
import { useFormik } from "formik";
import get from "lodash/get";

import { useApproveReceiptMutation } from "../../http/api/receipt.api";

interface UseProps {
  id: number;
  amount: number;
  onSuccess: () => void;
  onError: (message: string) => void;
}

export const useApproveSingleForm = ({
  id,
  amount,
  onSuccess,
  onError,
}: UseProps) => {
  const [approveReceipt] = useApproveReceiptMutation({});
  const {
    handleSubmit,
    getFieldProps: formikGetFieldProps,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      id,
      amount: amount,
    },
    validationSchema: yup.object().shape({
      id: yup.number().required("Required field"),
      amount: yup.number().required("Required field"),
    }),
    onSubmit: async (values) => {
      const formattedValues = {
        ...values,
        amount: Number(values.amount),
        ids: [values.id],
      };
      const res = await approveReceipt(formattedValues);
      const error = get(res, "error", null);
      if (error) {
        const message = get(
          error,
          "data.title",
          error.data.errorMessage || "Something went wrong."
        );
        onError(message);
      } else {
        onSuccess();
      }
    },
  });

  const getFieldProps = (fieldName) => {
    const error = errors[fieldName] && touched[fieldName];
    return {
      error,
      helperText: error ? errors[fieldName] : "",
      ...formikGetFieldProps(fieldName),
      onChange: (e) => {
        const targetValue = get(e, "target.value");
        if (targetValue || targetValue === "") {
          setFieldValue(fieldName, targetValue);
        } else {
          setFieldValue(fieldName, e);
        }
      },
    };
  };

  return {
    handleSubmit,
    getFieldProps,
  };
};
