import { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { AUTH_TOKEN } from "../../../constants/app.constants";
import { ROUTE_SIGN_IN_RESULT } from "../../../constants/route.constants";

export const AuthRedirect = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem(AUTH_TOKEN, searchParams.get("token") || "");
    navigate(ROUTE_SIGN_IN_RESULT);
  }, []);

  return null;
};
