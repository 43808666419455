import { ComponentType } from "react";
import { Box, Stack, Typography } from "@mui/material";
import useStyles from "./styles";
import { IconDelete, IconDocument } from "../icons";
import { bytesToSize } from "../../core/utils/bytesToSize";

interface Props {
  fileName: string;
  fileSize: number;
  onRemove?: () => void;
}

export const FilePreview: ComponentType<Props> = ({
  fileName,
  fileSize,
  onRemove,
}) => {
  const classes = useStyles();

  return (
    <Stack className={classes.root} px={2} py={1.5} direction="row" spacing={1}>
      <IconDocument />
      <Box className={classes.fileInfo} mx={1}>
        <Typography variant="body2" component="div">
          {fileName}
        </Typography>
        <Typography variant="body2" color="common.grey" component="div">
          {bytesToSize(fileSize)}
        </Typography>
      </Box>
      {onRemove && (
        <Box className={classes.deleteBtn} onClick={onRemove}>
          <IconDelete />
        </Box>
      )}
    </Stack>
  );
};
