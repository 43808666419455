import { ComponentType, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { Space } from "../../components/space";
import useStyles from "./styles";

export interface SaveDialogProps {
  popDialog: () => void;
  onSave: () => void;
  onSaveAndApprove: () => void;
  onSaveTemplate: (name: string) => void;
}

export const SaveActionDialog: ComponentType<SaveDialogProps> = ({
  popDialog,
  onSave,
  onSaveAndApprove,
  onSaveTemplate,
}) => {
  const [showTemplateForm, setShowTemplateForm] = useState(false);
  const handleSaveAsTemplate = (state: boolean) => {
    setShowTemplateForm(state);
  };

  const classes = useStyles();

  return (
    <Dialog open onClose={popDialog} className={classes.saveActionDialog}>
      <DialogTitle sx={{ textAlign: "center" }}>
        {showTemplateForm ? "Save receipt as template" : "Save receipt"}
      </DialogTitle>
      <DialogContent sx={{ pb: 4, minWidth: 256 }}>
        {showTemplateForm ? (
          <TemplateForm
            onSaveTemplate={onSaveTemplate}
            handleSaveAsTemplate={handleSaveAsTemplate}
          />
        ) : (
          <>
            <Space direction="column" spacing={1} mb={3}>
              <Button
                fullWidth
                onClick={() => {
                  onSave();
                  popDialog();
                }}
              >
                Save changes
              </Button>
              <Button
                color="success"
                fullWidth
                onClick={() => {
                  onSaveAndApprove();
                  popDialog();
                }}
              >
                Save and send on approval
              </Button>
              <Button
                color="secondary"
                fullWidth
                onClick={() => {
                  handleSaveAsTemplate(true);
                }}
              >
                Save as template
              </Button>
            </Space>
            <Button fullWidth variant="text" onClick={popDialog}>
              Cancel
            </Button>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

const TemplateForm = ({ onSaveTemplate, handleSaveAsTemplate }) => {
  const [name, setName] = useState("");

  return (
    <>
      <Stack spacing={1}>
        <TextField
          label="Template name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Button
          color="success"
          fullWidth
          onClick={() => {
            onSaveTemplate(name);
          }}
        >
          Save
        </Button>
        <Button
          color="secondary"
          fullWidth
          onClick={() => {
            handleSaveAsTemplate(false);
          }}
        >
          Cancel
        </Button>
      </Stack>
    </>
  );
};
