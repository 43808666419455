import { SvgIcon, SvgIconProps } from "@mui/material";
import { ReactComponent as SvgPlus } from "./assets/plus.svg";
import { ReactComponent as SvgCalendar } from "./assets/calendar.svg";
import { ReactComponent as SvgNewReceipts } from "./assets/new-receipts.svg";
import { ReactComponent as SvgApprovedReceipts } from "./assets/approved-receipts.svg";
import { ReactComponent as SvgCommentsApprovedReceipts } from "./assets/comments-approved-receipts.svg";
import { ReactComponent as SvgCommentsDeclinedReceipts } from "./assets/comments-declined-receipts.svg";
import { ReactComponent as SvgDeclinedReceipts } from "./assets/declined-receipts.svg";
import { ReactComponent as SvgDTotalReceipts } from "./assets/total-receipts.svg";
import { ReactComponent as SvgPerson } from "./assets/person.svg";
import { ReactComponent as SvgSend } from "./assets/send.svg";
import { ReactComponent as SvgTrash } from "./assets/trash.svg";
import { ReactComponent as SvgFolder } from "./assets/folder.svg";
import { ReactComponent as SvgOpen } from "./assets/open.svg";
import { ReactComponent as SvgClose } from "./assets/close.svg";
import { ReactComponent as SvgCheckbox } from "./assets/checkbox.svg";
import { ReactComponent as SvgLoading } from "./assets/loading.svg";
import { ReactComponent as SvgArrowLeft } from "./assets/arrow-left.svg";
import { ReactComponent as SvgSave } from "./assets/save.svg";
import { ReactComponent as SvgDownload } from "./assets/download.svg";
import { ReactComponent as SvgAttach } from "./assets/attach.svg";
import { ReactComponent as SvgMessages } from "./assets/messages.svg";
import { ReactComponent as SvgSearch } from "./assets/search.svg";
import { ReactComponent as SvgGoogleLogoMin } from "./assets/google-logo-min.svg";
import { ReactComponent as SvgSorting } from "./assets/sorting.svg";
import { ReactComponent as SvgFiltering } from "./assets/filtering.svg";
import { ReactComponent as SvgExport } from "./assets/export.svg";
import { ReactComponent as SvgWarning } from "./assets/warning.svg";
import { ReactComponent as SvgArrow } from "./assets/arrow.svg";
import { ReactComponent as SvgBurger } from "./assets/burger.svg";
import { ReactComponent as SvgDate } from "./assets/date.svg";
import { ReactComponent as SvgComment } from "./assets/comment.svg";
import { ReactComponent as SvgDownUp } from "./assets/down-up.svg";
import { ReactComponent as SvgExportLoading } from "./assets/export-loading.svg";
import { ReactComponent as SvgMinus } from "./assets/minus.svg";
import { ReactComponent as SvgLoaderBlack } from "./assets/loader-black.svg";
import { ReactComponent as SvgNotFound } from "./assets/not-found.svg";
import { ReactComponent as Svg404 } from "./assets/404.svg";
import { ReactComponent as SvgDocument } from "./assets/document.svg";
import { ReactComponent as SvgDelete } from "./assets/delete.svg";
import { ReactComponent as SvgEmptyData } from "./assets/empty-data.svg";

export const IconPlus = (props: SvgIconProps) => (
  <SvgIcon component={SvgPlus} viewBox="0 0 20 20" {...props} />
);

export const IconCalendar = (props: SvgIconProps) => (
  <SvgIcon component={SvgCalendar} viewBox="0 0 16 18" {...props} />
);

export const IconNewReceipts = (props: SvgIconProps) => (
  <SvgIcon component={SvgNewReceipts} viewBox="0 0 30 40" {...props} />
);

export const IconApprovedReceipts = (props: SvgIconProps) => (
  <SvgIcon component={SvgApprovedReceipts} viewBox="0 0 36 45" {...props} />
);

export const IconCommentsApprovedReceipts = (props: SvgIconProps) => (
  <SvgIcon
    component={SvgCommentsApprovedReceipts}
    viewBox="0 0 21 21"
    {...props}
  />
);

export const IconCommentsDeclinedReceipts = (props: SvgIconProps) => (
  <SvgIcon
    component={SvgCommentsDeclinedReceipts}
    viewBox="0 0 21 21"
    {...props}
  />
);

export const IconDeclinedReceipts = (props: SvgIconProps) => (
  <SvgIcon component={SvgDeclinedReceipts} viewBox="0 0 36 45" {...props} />
);

export const IconTotalReceipts = (props: SvgIconProps) => (
  <SvgIcon component={SvgDTotalReceipts} viewBox="0 0 36 45" {...props} />
);

export const IconPerson = (props: SvgIconProps) => (
  <SvgIcon component={SvgPerson} viewBox="0 0 14 15" {...props} />
);

export const IconSend = (props: SvgIconProps) => (
  <SvgIcon component={SvgSend} viewBox="0 0 16 17" {...props} />
);

export const IconTrash = (props: SvgIconProps) => (
  <SvgIcon component={SvgTrash} viewBox="0 0 13 17" {...props} />
);

export const IconFolder = (props: SvgIconProps) => (
  <SvgIcon component={SvgFolder} viewBox="0 0 16 17" {...props} />
);

export const IconOpen = (props: SvgIconProps) => (
  <SvgIcon component={SvgOpen} viewBox="0 0 12 12" {...props} />
);

export const IconClose = (props: SvgIconProps) => (
  <SvgIcon component={SvgClose} viewBox="0 0 12 12" {...props} />
);

export const IconCheckbox = (props: SvgIconProps) => (
  <SvgIcon component={SvgCheckbox} viewBox="0 0 20 20" {...props} />
);

export const IconLoading = (props: SvgIconProps) => (
  <SvgIcon component={SvgLoading} viewBox="0 0 32 33" {...props} />
);

export const IconArrowLeft = (props: SvgIconProps) => (
  <SvgIcon component={SvgArrowLeft} viewBox="0 0 8 14" {...props} />
);

export const IconSave = (props: SvgIconProps) => (
  <SvgIcon component={SvgSave} viewBox="0 0 20 20" {...props} />
);

export const IconDownload = (props: SvgIconProps) => (
  <SvgIcon component={SvgDownload} viewBox="0 0 24 24" {...props} />
);

export const IconAttach = (props: SvgIconProps) => (
  <SvgIcon component={SvgAttach} viewBox="0 0 24 24" {...props} />
);

export const IconMessages = (props: SvgIconProps) => (
  <SvgIcon component={SvgMessages} viewBox="0 0 20 20" {...props} />
);

export const IconSearch = (props: SvgIconProps) => (
  <SvgIcon component={SvgSearch} viewBox="0 0 18 18" {...props} />
);

export const IconGoogleLogoMin = (props: SvgIconProps) => (
  <SvgIcon component={SvgGoogleLogoMin} viewBox="0 0 23 23" {...props} />
);

export const IconSorting = (props: SvgIconProps) => (
  <SvgIcon component={SvgSorting} viewBox="0 0 20 20" {...props} />
);

export const IconFiltering = (props: SvgIconProps) => (
  <SvgIcon component={SvgFiltering} viewBox="0 0 18 16" {...props} />
);

export const IconExport = (props: SvgIconProps) => (
  <SvgIcon component={SvgExport} viewBox="0 0 20 20" {...props} />
);
export const IconWarning = (props: SvgIconProps) => (
  <SvgIcon component={SvgWarning} viewBox="0 0 28 24" {...props} />
);
export const IconArrow = (props: SvgIconProps) => (
  <SvgIcon component={SvgArrow} viewBox="0 0 10 5" {...props} />
);
export const IconBurger = (props: SvgIconProps) => (
  <SvgIcon component={SvgBurger} viewBox="0 0 14 10" {...props} />
);
export const IconDate = (props: SvgIconProps) => (
  <SvgIcon component={SvgDate} viewBox="0 0 16 18" {...props} />
);
export const IconComment = (props: SvgIconProps) => (
  <SvgIcon component={SvgComment} viewBox="0 0 17 18" {...props} />
);
export const IconDownUp = (props: SvgIconProps) => (
  <SvgIcon component={SvgDownUp} viewBox="0 0 20 21" {...props} />
);
export const IconExportLoading = (props: SvgIconProps) => (
  <SvgIcon component={SvgExportLoading} viewBox="0 0 33 32" {...props} />
);
export const IconMinus = (props: SvgIconProps) => (
  <SvgIcon component={SvgMinus} viewBox="0 0 20 20" {...props} />
);
export const IconLoaderBlack = (props: SvgIconProps) => (
  <SvgIcon component={SvgLoaderBlack} viewBox="0 0 32 32" {...props} />
);
export const IconNotFound = (props: SvgIconProps) => (
  <SvgIcon component={SvgNotFound} viewBox="0 0 80 81" {...props} />
);
export const Icon404 = (props: SvgIconProps) => (
  <SvgIcon component={Svg404} viewBox="0 0 138 70" {...props} />
);
export const IconDocument = (props: SvgIconProps) => (
  <SvgIcon component={SvgDocument} viewBox="0 0 16 16" {...props} />
);
export const IconDelete = (props: SvgIconProps) => (
  <SvgIcon component={SvgDelete} viewBox="0 0 24 24" {...props} />
);
export const IconEmptyData = (props: SvgIconProps) => (
  <SvgIcon component={SvgEmptyData} viewBox="0 0 56 56" {...props} />
);
