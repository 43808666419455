import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../core/store/rtk.utils";
import { url } from "../../core/utils/route.utils";
import {
  GET_EMPLOYEES,
  GET_USER,
  CHANGE_EMAIL_DECLINE,
  CHANGE_EMAIL_APPROVE,
} from "./api.routes";

interface IGetEmployeeResponse {
  items: [
    {
      id: number;
      firstName: string;
      lastName: string;
      email: string;
      receiptsCount: number;
      receiptsAmount: number;
      receiptsApprovedCount: number;
      receiptsApprovedAmount: number;
    }
  ];
  totalCount: number;
  itemsPerPage: number;
  currentPage: number;
  pagesCount: number;
  totalPages: number;
  recordsPerPage: number;
}

interface IGetUserResponse {
  id: number;
  userName: string;
  firstName: string;
  lastName: string;
  createdAt: string;
  lastLogin: string;
  newEmail?: string;
  status?: string;
  avatar: {
    id: number;
    guid: string;
    link: string;
  };
}

interface IChangeEmailRequest {
  id: number;
}

export const employeeApi = createApi({
  reducerPath: "employeeApi",
  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getEmployees: builder.query<IGetEmployeeResponse, any>({
      query: (params) => ({
        url: GET_EMPLOYEES,
        method: "GET",
        params,
      }),
    }),
    getUser: builder.query<IGetUserResponse, any>({
      query: (params) => ({
        url: url(GET_USER, { id: params.id }),
        method: "GET",
        params,
      }),
    }),
    changeEmailApprove: builder.mutation<IChangeEmailRequest, any>({
      query: (params) => ({
        url: url(CHANGE_EMAIL_APPROVE, { id: params.id }),
        method: "GET",
        params,
      }),
    }),
    changeEmailDecline: builder.mutation<IChangeEmailRequest, any>({
      query: (params) => ({
        url: url(CHANGE_EMAIL_DECLINE, { id: params.id }),
        method: "GET",
        params,
      }),
    }),
  }),
});

export const {
  useGetEmployeesQuery,
  useGetUserQuery,
  useChangeEmailDeclineMutation,
  useChangeEmailApproveMutation,
} = employeeApi;
