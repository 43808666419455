import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../core/store/rtk.utils";
import { url } from "../../core/utils/route.utils";
import { GET_COMMENTS, SEND_COMMENT } from "./api.routes";

interface IGetCommentsResponse {
  items: IComment[];
}

interface IComment {
  id: number;
  text: string;
  guid: string;
  createdAt: string;
  owner: {
    id: number;
    userName: string;
    firstName: string;
    lastName: string;
    createdAt: string;
    lastLogin: string;
    avatar: {
      id: number;
      guid: string;
      link: string;
    };
  };
  files: {
    id: number;

    guid: string;
    link: string;
  }[];
}

interface ICommentRequest {
  text: string;
  guid: string;
  files: string[];
}

export const commentApi = createApi({
  reducerPath: "commentApi",
  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getComments: builder.query<IGetCommentsResponse, any>({
      query: (params) => ({
        url: url(GET_COMMENTS, { receiptGuid: params.receiptGuid }),
        method: "GET",
      }),
    }),
    sendComment: builder.mutation<ICommentRequest, any>({
      query: (params) => ({
        url: url(SEND_COMMENT, { receiptGuid: params.guid }),
        method: "POST",
        body: params,
      }),
    }),
  }),
});

export const { useGetCommentsQuery, useSendCommentMutation } = commentApi;
