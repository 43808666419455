import React from "react";
import { Container } from "@mui/material";
import { Comments } from "../../components/comments";
import { BackNavigationButton } from "../../components/buttons";
import useStyles from "./styles";

export const SpendsComments = () => {
  const classes = useStyles();
  return (
    <Container className={classes.commentsRoot}>
      <BackNavigationButton title="Receipt information" />
      <Comments />
    </Container>
  );
};
